import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import idx from 'idx'
import Fade from 'react-reveal/Fade'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../utils/GatsbyLink'
import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import { trackEvent } from '../../utils/segmentTrack'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import meshGradient from '../../../static/gradients/mesh-gradient-large.png'
import testingVariant from '../../../config/testingVariant'

import Text from '../common/Text'
import Space from '../common/Space'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  width: 100vw;
  height: 80%;
  background-size: 100vw;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url(${meshGradient});
  top: 0;
  z-index: -1;
  max-height: 100%;

  @media (max-width: 1600px) {
    background-size: 110vw;
    height: 80%;
  }

  @media (max-width: 1500px) {
    background-size: 120vw;
    background-size: cover;
  }

  @media (max-width: 1050px) {
    height: 75vh;
  }

  @media (max-width: 800px) {
    height: 95vh;
  }
`

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 80px;
  grid-column-gap: 80px;
  width: calc(100% + 300px);
  margin-left: -150px;
  height: ${p => (p.formLoaded ? 'auto' : '80vh')};

  ::after {
    content: '';
    height: 100%;
    position: absolute;
    width: 100vw;
    z-index: -999;
    background-color: ${p => (p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white)};
  }

  .react-reveal {
    width: 100%;
  }

  @media (max-width: 1300px) {
    grid-column-gap: 56px;
  }

  @media (max-width: 1050px) {
    grid-column-gap: 40px;
    width: 100%;
    margin-left: 0px;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }

  @media (max-width: 800px) {
    padding: 48px 0px;
  }
`

const LeftWrapper = styled.div`
  grid-column: 1 /2;
  grid-row: 1/ 2;

  @media (max-width: 1050px) {
    grid-row: auto;
    align-items: center;
    * {
      text-align: center !important;
    }
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  background-color: ${theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  min-width: 500px;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1050px) {
    max-width: 500px;
    min-width: unset;
    display: block;
    margin: 32px auto 0px auto;
    padding: 32px;
    width: 100%;
    grid-row: auto;
    grid-column: 1 /2;
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const BannerForm = ({ primary }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const [success, setSuccess] = useState(false)
  const text = idx(primary, _ => _.text.richText[0].text) && (
    <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  )
  const heading = idx(primary, _ => _.heading.richText[0].text) && (
    <RichText render={primary.heading.richText} serializeHyperlink={GatsbyLink} />
  )
  const smallCapsText = idx(primary, _ => _.small_caps_text.richText[0].text) && (
    <RichText render={primary.small_caps_text.richText} serializeHyperlink={GatsbyLink} />
  )
  const formCardHeading = idx(primary, _ => _.form_card_heading.richText[0].text) && (
    <RichText render={primary.form_card_heading.richText} serializeHyperlink={GatsbyLink} />
  )
  const stagingHubSpotFormId = idx(primary, _ => _.staging_hubspot_form_id)
  const productionHubSpotFormId = idx(primary, _ => _.production_hubspot_form_id)
  const calendlyFollowUpPage = idx(primary, _ => _.calendly_follow_up_page)
  const backgroundColor = idx(primary, _ => _.background_color)

  function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors = e.data.data.filter(error => {
        if (error.errorTypes.length) {
          return error
        }
        return null
      })
      const firstErr = errors[0].name
      if (firstErr) {
        const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
        if (toFocus) toFocus.focus()
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (e.data.id === stagingHubSpotFormId || e.data.id === productionHubSpotFormId) {
        trackForm(e.data.data, stagingHubSpotFormId, productionHubSpotFormId)
        setSuccess(true)
        if (calendlyFollowUpPage) {
          navigate(calendlyFollowUpPage)
          window.sessionStorage.setItem('hs-form-data', JSON.stringify(e.data.data))
        }
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(sessionStorage.getItem('utm_params'))
      Object.entries(persistedUtmParams).forEach(param => {
        const hiddenInput = document.querySelector(`input[name="${param[0]}"]`)
        if (hiddenInput) hiddenInput.value = param[1] || ''
      })
    }
  }, [formLoaded])

  useEffect(() => {
    if (formLoaded) {
      const formNameInput = document.querySelector('input[name="form_name"]')
      let formName
      if (formNameInput) {
        formName = formNameInput.value
      }

      const agencyInput = document.querySelector('input[name="agency_or_freelancer"]')
      if (agencyInput) {
        agencyInput.addEventListener('change', e => {
          if (e.target.value === 'Yes') {
            trackEvent(`Agency Input Value Changed`, {
              value: true,
              form: formName,
              page_url: (window && window.location.pathname) || '',
            })
          }
        })
      }
      const testVariantInput = document.querySelector('input[name="variant"]')
      if (testVariantInput) {
        const testData = JSON.stringify(testingVariant())
        testVariantInput.value = testData
      }
    }

    if (window.location.pathname === '/partner-overview') {
      const howCanWeHelpInput = document.querySelector('form fieldset .hs-message.hs-fieldtype-textarea')
      if (howCanWeHelpInput) {
        howCanWeHelpInput.style.display = 'none'
      }
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded} backgroundColor={backgroundColor}>
      <LeftWrapper>
        {smallCapsText && (
          <Text font="smallCaps" richText color="green500">
            {smallCapsText}
          </Text>
        )}
        {heading && (
          <>
            <Text font="heading1" richText color="white">
              {heading}
            </Text>
            <Space height={24} tabletHeight={16} />
          </>
        )}
        {text && (
          <Text richText color="white">
            {text}
          </Text>
        )}
      </LeftWrapper>
      <Fade bottom when={formLoaded}>
        <FormWrapper>
          {formCardHeading && (
            <>
              <Text font="heading3" aria-level="2" richText>
                {formCardHeading}
              </Text>
              <Space height={24} />
            </>
          )}
          {(stagingHubSpotFormId || productionHubSpotFormId) && (
            <>
              {!success && (
                <RequiredWrapper>
                  <span>*&nbsp;</span>
                  <Text tinyBody color="gray700">
                    Required Field
                  </Text>
                </RequiredWrapper>
              )}
              <HubspotForm
                portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
                formId={process.env.GATSBY_ACTIVE_ENV === 'production' ? productionHubSpotFormId : stagingHubSpotFormId}
                loading={<div>Loading...</div>}
                onReady={() => setFormLoaded(true)}
                submitButtonClass="custom-hs-button"
                cssClass="custom-hs-css hs-form-lander"
              />
            </>
          )}
        </FormWrapper>
      </Fade>
      <BG className="bg" />
    </Wrapper>
  )
}

export default BannerForm

export const query = graphql`
  fragment BannerForm on PrismicPageDataBodyBannerForm {
    ...SliceBase
    primary {
      production_hubspot_form_id
      staging_hubspot_form_id
      calendly_follow_up_page
      background_color
      small_caps_text {
        ...TextContent
      }
      heading {
        ...TextContent
      }
      text {
        ...TextContent
      }
      form_card_heading {
        ...TextContent
      }
    }
  }
`
