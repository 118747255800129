import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../../styles'
import Text from './Text'
import tooltipIcon from '../../../static/icons/misc-icons/tooltip-icon.svg'

const TooltipWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 24px;
  padding: 4px;
  cursor: pointer;

  p {
    visibility: hidden;
    width: ${p => (p.width ? `${p.width}px` : '250px')};
    background-color: ${theme.color.offBlack};
    padding: 16px;
    border-radius: 8px;
    line-height: ${theme.fonts.lineHeight.smallBody};
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: ${p => (p.width ? `-${p.width / 2}px` : '-125px')};
    opacity: 0;
    transition: opacity 0.3s;

    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${theme.color.offBlack} transparent transparent transparent;
    }
  }

  :hover,
  :focus {
    p {
      visibility: visible;
      opacity: 1;
    }
  }

  :focus {
    outline: none;
    ::after {
      content: '';
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 50%;
      border: 3px solid ${theme.color.purple500};
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const TooltipIcon = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: top;
`

const Tooltip = ({ text, width, formVisible }) => (
  <TooltipWrapper width={width || 250} aria-labelledby="tooltip-text" tabIndex={formVisible ? '-1' : '0'}>
    <TooltipIcon src={tooltipIcon} alt="" />
    <Text tinyBody color="white" role="tooltip" id={`tooltip-text-${text.split(' ')[0]}`}>
      {text}
    </Text>
  </TooltipWrapper>
)

export default Tooltip
