/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import { theme } from '../../styles'
import Space from '../common/Space'
import Text from '../common/Text'
import TextLink from '../common/TextLink'
import SubMenu from './SubMenu'
import downChevronOffBlack from '../../../static/icons/arrow-icons/dropdown-chevron-icon.svg'
import downChevronGray300 from '../../../static/icons/arrow-icons/chevron-icon-gray300.svg'
import downChevronWhite from '../../../static/icons/arrow-icons/dropdown-chevron-icon-white.svg'

const MenuWrapper = styled.ul`
  display: grid;
  ${p =>
    p.mobile
      ? `
    grid-auto-flow: row;
    padding: 24px;
    padding-top: 0px;
    display: grid;

    > li {
      border-bottom: 1px solid ${theme.color.offWhite};
      padding: 16px 0px;

      &:last-of-type {
        border-bottom: none;
      }
    }
    `
      : `
    gap: 24px;
    grid-auto-flow: column;

  `}

  > li {
    list-style-type: none;
    margin-bottom: 0px;
    display: flex;
  }
`

const SubMenuContainer = styled.li`
  position: relative;
  perspective: 1000px;

  @media (max-width: 1200px) {
    position: unset;
    perspective: unset;
  }
`

const MenuButton = styled.button`
  background-color: transparent;
  text-decoration: none;
  padding: 0px 4px;
  margin: 0px;
  color: ${theme.color.offBlack};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  &::before {
    content: '';
    background-image: url(${downChevronOffBlack});
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    width: 12px;
    height: 12px;
    margin-left: 8px;
    margin-top: 2px;
  }

  :hover {
    p {
      color: ${theme.color.white};
    }

    &::before {
      background-image: url(${downChevronWhite});
    }

    @media (max-width: 800px) {
      p {
        color: ${theme.color.offBlack};
      }

      &::before {
        background-image: url(${downChevronOffBlack});
      }
    }
  }

  p {
    font-size: ${theme.fonts.size.smallBodyHeavy};
    font-family: ${theme.fonts.family.smallBodyHeavy};
    letter-spacing: ${theme.fonts.letterSpacing.smallBodyHeavy};
  }

  @media (max-width: 1200px) {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-between;
    padding: 0px;

    p {
      font-size: ${theme.fonts.size.bodyHeavy};
      font-family: ${theme.fonts.family.bodyHeavy};
      letter-spacing: ${theme.fonts.letterSpacing.bodyHeavy};
    }

    &::before {
      background-image: url(${downChevronGray300});
      background-size: 14px;
      grid-column: 2/ 3;
      width: 14px;
      height: 14px;
      margin-left: 8px;
      margin-top: 2px;
      transform: rotate(-90deg);
    }
  }
`

const HeaderTextLinkWrapper = styled.div`
  span {
    font-size: ${theme.fonts.size.smallBodyHeavy};
    font-family: ${theme.fonts.family.smallBodyHeavy};
    letter-spacing: ${theme.fonts.letterSpacing.smallBodyHeavy};
  }
  @media (max-width: 1200px) {
    span {
      font-size: ${theme.fonts.size.bodyHeavy};
      font-family: ${theme.fonts.family.bodyHeavy};
      letter-spacing: ${theme.fonts.letterSpacing.bodyHeavy};
    }
  }
`

export const Underline = styled.div`
  width: 100%;
  position: relative;
  top: 5px;
  left: 0px;
  height: 2px;
  margin-bottom: -2px;
  background: ${theme.color.purple500};
`

export const TopLevelItem = ({ headerItem }) => {
  const target = idx(headerItem, _ => _.url.url) || idx(headerItem, _ => _.fallback_url)
  const urlParts = target.split('/').filter(url => url.length > 0)
  const cleanPath = typeof window !== 'undefined' ? window.location.pathname.replace(/\//g, '') : ''
  const isActive = urlParts.includes(cleanPath)

  return (
    <React.Fragment key={`${headerItem.top_level_title}`}>
      <HeaderTextLinkWrapper className="header-text-link">
        <HeaderTextLink
          text={headerItem.top_level_title}
          data-cy={headerItem.top_level_title.replace(/ /g, '-').toLowerCase()}
          to={target}
          color={isActive ? 'purple500' : 'offBlack'}
        />
        {isActive && <Underline />}
      </HeaderTextLinkWrapper>
      <Space width={28} height={20} mobileWidth={0} mobileHeight={0} />
    </React.Fragment>
  )
}

export const HeaderTextLink = props => (
  <TextLink
    font="smallBodyHeavy"
    color="offBlack"
    style={{ textDecoration: 'none' }}
    outboundHeaderLink={idx(props.to, _ => _.includes('http'))}
    {...props}
  />
)

export const SubMenuGroup = ({
  topLevelLink,
  items,
  mobile,
  secondaryCTAText,
  secondaryCTALink,
  showCTA,
  setIsOpen,
  isAnyMenuOpen,
  setIsAnyMenuOpen,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const menuButtonRef = useRef(null)
  const firstListFirstItemRef = useRef(null)

  const toggleOpen = (forceClose, noFocus) => {
    setTimeout(() => {
      forceClose
        ? (setIsSubMenuOpen(false), setIsAnyMenuOpen(false))
        : (setIsSubMenuOpen(!isSubMenuOpen), setIsAnyMenuOpen(!isAnyMenuOpen))

      if (!noFocus) {
        menuButtonRef?.current.focus()
      }

      if (!isSubMenuOpen && !forceClose) {
        firstListFirstItemRef?.current.focus()
      }
    }, 0)
  }

  return (
    <SubMenuContainer>
      <MenuButton
        aria-haspopup="true"
        aria-expanded={isSubMenuOpen ? 'true' : 'false'}
        ref={menuButtonRef}
        onClick={() => toggleOpen()}
        data-cy={topLevelLink.replace(/ /g, '-').toLowerCase()}
        onKeyDown={e => {
          if (e.key === 'Escape' && isSubMenuOpen) toggleOpen()
        }}
        aria-controls={`${topLevelLink}-submenu`}
        key={topLevelLink}
        id={`${topLevelLink}-button`}
        className="menu-button header-text-link"
      >
        <Text>{topLevelLink.charAt(0).toUpperCase() + topLevelLink.slice(1)}</Text>
      </MenuButton>
      <SubMenu
        textLinks={items}
        isSubMenuOpen={isSubMenuOpen}
        setIsSubMenuOpen={setIsSubMenuOpen}
        toggleOpen={toggleOpen}
        menuButtonRef={menuButtonRef}
        firstListFirstItemRef={firstListFirstItemRef}
        key={`${items[0].submenu}-${topLevelLink}`}
        mobile={mobile}
        showCTA={showCTA}
        setIsOpen={setIsOpen}
        secondaryCTAText={secondaryCTAText}
        secondaryCTALink={secondaryCTALink}
      />
    </SubMenuContainer>
  )
}

export const Menu = ({
  headerLinks,
  mobile,
  secondaryCTAText,
  secondaryCTALink,
  showCTA,
  setIsOpen,
  isAnyMenuOpen,
  setIsAnyMenuOpen,
}) => {
  const mobileHeaderLinks = headerLinks.filter(link => link.show_on_mobile)
  const modifiedHeaderLinks = mobile ? mobileHeaderLinks : headerLinks

  const submenuData = mapValues(
    groupBy(
      modifiedHeaderLinks.filter(link => link.submenu),
      'submenu'
    )
  )

  const topLevelLinks = headerLinks.filter(headerLink => !headerLink.submenu)

  return (
    <MenuWrapper mobile={mobile}>
      {Object.keys(submenuData).map((submenu, index) => (
        <SubMenuGroup
          topLevelLink={submenu}
          items={submenuData[submenu]}
          key={submenuData[submenu]}
          index={index}
          mobile={mobile}
          showCTA={showCTA}
          setIsOpen={setIsOpen}
          isAnyMenuOpen={isAnyMenuOpen}
          setIsAnyMenuOpen={setIsAnyMenuOpen}
          secondaryCTAText={secondaryCTAText}
          secondaryCTALink={secondaryCTALink}
        />
      ))}
      {topLevelLinks.map(headerLink => (
        <li className="link-list-item" key={headerLink.top_level_title}>
          <TopLevelItem headerItem={headerLink} />
        </li>
      ))}
    </MenuWrapper>
  )
}
