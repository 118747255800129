/* eslint-disable react/no-array-index-key */
import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import companyLogo from '../../../static/logos/company-logo-violet.svg'
import companyGraphicLogo from '../../../static/logos/company-logo-graphic-only.svg'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import HamburgerIcon from '../../../static/icons/misc-icons/hamburger-menu-icon.svg'
import Button from '../common/Button'

import { Menu, HeaderTextLink } from './common'

const AudioEyeLogo = styled.img`
  width: 124px;
`

const AudioEyeGraphicLogo = styled.img`
  width: 24px;
`

const Responsive = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  .button {
    margin: unset;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const MobMenuButton = styled.button`
  background: transparent;
  cursor: pointer;
`

const MobContentWrapper = styled.div`
  position: absolute;
  transition-property: transform, opacity;
  transition: 200ms;
  transform-origin: 100% 0;
  width: calc(100vw - var(--scrollbar-width));
  top: 0;
  left: 0;
  background-color: transparent;

  ${p =>
    p.isOpen
      ? `
      z-index: 9999;
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
  `
      : `
      z-index: -9999;
      pointer-events: none;
      opacity: 0;
      transform: scale(0.90);
  `};
`

const MobContent = styled.div`
  margin: 16px;
  border-radius: 8px;
  background-color: ${theme.color.white};
  display: grid;
  box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
`

const MobHeader = styled.section`
  border-radius: 8px 8px 0px 0px;
  padding-top: 16px;
  padding: 24px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

const MobFooterWrapper = styled.section`
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
  padding-top: 16px;
  display: grid;
  gap: 16px;
  background-color: ${theme.color.offWhite};
`

const MobileNav = styled.nav`
  display: grid;
  @media (min-width: 1200px) {
    display: none;
  }
`

const CloseButton = styled.button`
  padding: 4px;
  background: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
`

const HamburgerButton = styled.img`
  width: 100%;
`

const MobileMenu = ({
  hideHeaderFooterNav,
  isOpen,
  setIsOpen,
  homePageLink,
  secondaryCTAText,
  secondaryCTALink,
  path,
  showCTA,
  headerLinks,
  setIsAnyMenuOpen,
}) => (
  <>
    {!hideHeaderFooterNav && (
      <Responsive>
        <Link to="/">
          <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
        </Link>
        <MobMenuButton
          onClick={() => setIsOpen(true)}
          aria-label="Open the menu"
          onKeyDown={e => {
            if (e.key === 'Enter') setIsOpen(true)
          }}
        >
          <HamburgerButton src={HamburgerIcon} alt="" />
        </MobMenuButton>
        <MobContentWrapper isOpen={isOpen}>
          <MobContent>
            <MobHeader>
              <Link to="/">
                <AudioEyeGraphicLogo alt="AudioEye logo homepage" src={companyGraphicLogo} />
              </Link>
              <CloseButton
                role="button"
                aria-label="Close menu"
                onClick={() => setIsOpen(false)}
                onKeyDown={e => {
                  if (e.key === 'Enter') setIsOpen(false)
                }}
              >
                <CloseIcon alt="" src={CrossIcon} />
              </CloseButton>
            </MobHeader>
            <MobileNav role="navigation">
              <Menu
                headerLinks={headerLinks}
                mobile
                showCTA={showCTA}
                setIsOpen={setIsOpen}
                secondaryCTAText={secondaryCTAText}
                secondaryCTALink={secondaryCTALink}
                setIsAnyMenuOpen={setIsAnyMenuOpen}
              />
            </MobileNav>
            <MobFooterWrapper>
              <HeaderTextLink text="Login" to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL} />
              {showCTA && (
                <Button
                  text="Start Free Trial"
                  aria-label="Start Free Trial"
                  size="small"
                  onClick={e => {
                    e.preventDefault()
                    setIsOpen(false)
                    navigate('#get-started-free')
                  }}
                />
              )}
              {secondaryCTAText && secondaryCTALink && (
                <Button text={secondaryCTAText} to={secondaryCTALink} size="small" buttonType="secondary" />
              )}
            </MobFooterWrapper>
          </MobContent>
        </MobContentWrapper>
      </Responsive>
    )}

    {hideHeaderFooterNav && (
      <Responsive style={{ padding: '14px 0' }}>
        <Link to={homePageLink ? '/' : path}>
          <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
        </Link>
        {secondaryCTAText && secondaryCTALink && <Button text={secondaryCTAText} to={secondaryCTALink} size="small" />}
        {showCTA && !secondaryCTAText && !secondaryCTALink && (
          <Button text="Start Free Trial" aria-label="Start Free Trial" to="#get-started-free" size="small" />
        )}
      </Responsive>
    )}
  </>
)

export default MobileMenu
