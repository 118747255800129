import React from 'react'
import styled from '@emotion/styled'

const StyledSpan = styled.span`
  position: absolute;
  left: -9000px;
  width: 0;
  height: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
  padding: 4px;
  border-radius: 3px;
  background-color: ${p => p.theme.color.white};
  white-space: normal;
`

export const OpenNewTabHiddenSpan = ({ text, fileType, isButton, overGraphic, shortOverGraphic }) => (
  <StyledSpan
    className={`external-link ${isButton ? 'external-button' : ''} ${overGraphic ? 'over-graphic' : ''} ${
      shortOverGraphic ? 'short-over-graphic' : ''
    }`}
  >
    {text || fileType ? `(${text} ${fileType || ''} opens in a new tab)` : `(opens in a new tab)`}
  </StyledSpan>
)
