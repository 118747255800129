import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../../utils/GatsbyLink'
import { trackForm } from './HubSpotFormAPI'
import { trackEvent } from '../../../utils/segmentTrack'
import HubspotStyles from './hs-form-styles'

import VimeoIframe from '../../common/VimeoIframe'
import Text from '../../common/Text'
import TextLink from '../../common/TextLink'
import Space from '../../common/Space'
import violetCheckMarkIcon from '../../../../static/icons/check-icons/violet-check-icon.svg'
import getImage from '../../../utils/getImage'
import { theme } from '../../../styles'
import testingVariant from '../../../../config/testingVariant'

const Wrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${p => (p.hasTopPadding ? '80px' : '0px 80px 80px 80px')};
  border-radius: 4px;
  flex: 1;
  width: calc(100% + 250px);
  margin-left: -125px;

  @media (max-width: 1050px) {
    margin: 0px;
    width: 100%;
    flex-direction: column;
    padding: ${p => (p.hasTopPadding ? '32px 0px' : '0px 8px 88px 8px')};
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 930px;
  flex: 1;

  @media (max-width: 1050px) {
    width: 100%;
    a {
      margin: 0 auto;
    }
    .center-mobile {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    align-items: center;
    * {
      text-align: center !important;
    }
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1050px) {
    max-width: 500px;
    display: block;
    margin: 32px auto 0px auto;
    width: 100%;
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const IFrame = styled.iframe`
  width: 100%;
  border-width: 0px;
  @media (max-width: 1050px) {
    height: 100%;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${p => (p.isCentered ? '36.25% 0 0 0' : '56.25% 0 0 0')};

  @media (max-width: 1050px) {
    margin: 0;
    padding: 56.25% 0 0 0;
  }
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-left: 20px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});

    @media (max-width: 1050px) {
      margin: 0 auto;
      text-align: left;
    }

    @media (max-width: 800px) {
      text-align: left !important;
    }
  }
  @media (max-width: 1050px) {
    margin: 0 auto;
  }
`

const ImageWrapper = styled.img`
  width: 100%;
`

const FormLander = ({ primary }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const [success, setSuccess] = useState(false)

  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const checkMarkList = <RichText render={primary.checkmark_list.richText} serializeHyperlink={GatsbyLink} />
  const title = <RichText render={primary.title.richText} serializeHyperlink={GatsbyLink} />
  const formHeading = <RichText render={primary.form_heading.richText} serializeHyperlink={GatsbyLink} />
  const subtitle = idx(primary, _ => _.subtitle.text)
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const imageSrc = idx(primary, _ => _.image.url)
  const formUrl = idx(primary, _ => _.form_url.url)
  const video = idx(primary, _ => _.video_url.url)
  const audioDescLink = idx(primary, _ => _.audio_description_link.url)
  const stagingHubSpotFormId = idx(primary, _ => _.staging_hubspot_form_id)
  const productionHubSpotFormId = idx(primary, _ => _.production_hubspot_form_id)
  const calendlyFollowUpPage = idx(primary, _ => _.calendly_follow_up_page)

  function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors = e.data.data.filter(error => {
        if (error.errorTypes.length) {
          return error
        }
        return null
      })
      const firstErr = errors[0].name
      if (firstErr) {
        const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
        if (toFocus) toFocus.focus()
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (e.data.id === stagingHubSpotFormId || e.data.id === productionHubSpotFormId) {
        trackForm(e.data.data, stagingHubSpotFormId, productionHubSpotFormId)
        setSuccess(true)
        if (calendlyFollowUpPage) {
          navigate(calendlyFollowUpPage)
          window.sessionStorage.setItem('hs-form-data', JSON.stringify(e.data.data))
        }
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(sessionStorage.getItem('utm_params'))
      Object.entries(persistedUtmParams).forEach(param => {
        const hiddenInput = document.querySelector(`input[name="${param[0]}"]`)
        if (hiddenInput) hiddenInput.value = param[1] || ''
      })
    }
  }, [formLoaded])

  useEffect(() => {
    if (formLoaded) {
      const formNameInput = document.querySelector('input[name="form_name"]')
      let formName
      if (formNameInput) {
        formName = formNameInput.value
      }

      const agencyInput = document.querySelector('input[name="agency_or_freelancer"]')
      if (agencyInput) {
        agencyInput.addEventListener('change', e => {
          if (e.target.value === 'Yes') {
            trackEvent(`Agency Input Value Changed`, {
              value: true,
              form: formName,
              page_url: (window && window.location.pathname) || '',
            })
          }
        })
      }
      const testVariantInput = document.querySelector('input[name="variant"]')
      if (testVariantInput) {
        const testData = JSON.stringify(testingVariant())
        testVariantInput.value = testData
      }
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper hasTopPadding={hasTopPadding === null ? true : hasTopPadding} css={HubspotStyles}>
      <LeftWrapper>
        {title && idx(title, _ => _.props.render[0].text) && (
          <>
            <Text heading2>{title}</Text>
            <Space height={32} />
          </>
        )}
        {subtitle && (
          <>
            <Text largeBody>{subtitle}</Text>
            <Space height={24} />
          </>
        )}
        {video && (
          <>
            <VideoWrapper>
              <VimeoIframe title="Feature presentation, no audio" video={video} autoPlay />
            </VideoWrapper>
            <Space height={24} tabletHeight={16} />

            {audioDescLink && (
              <>
                <TextLink to={audioDescLink} text="Play Version with Audio Descriptions" />
                <Space height={24} />
              </>
            )}
          </>
        )}
        {!video && imageSrc && getImage(primary.image, ImageWrapper)}
        <Space height={24} mobileHeight={16} />
        {text && idx(text, _ => _.props.render[0].text) && (
          <>
            <Text richText className="center-mobile">
              {text}
            </Text>
            <Space height={16} />
          </>
        )}
        {checkMarkList && idx(checkMarkList, _ => _.props.render[0].text) && (
          <CheckMarkListWrapper richText>{checkMarkList}</CheckMarkListWrapper>
        )}
      </LeftWrapper>
      <Space width={50} />
      <FormWrapper>
        {formHeading && idx(formHeading, _ => _.props.render[0].text) && (
          <>
            <Text font="heading3" aria-level="2" richText>
              {formHeading}
            </Text>
            <Space height={16} />
          </>
        )}
        {(stagingHubSpotFormId || productionHubSpotFormId) && (
          <>
            {!success && (
              <RequiredWrapper>
                <span>*&nbsp;</span>
                <Text tinyBody color="gray700">
                  Required Field
                </Text>
              </RequiredWrapper>
            )}
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={process.env.GATSBY_ACTIVE_ENV === 'production' ? productionHubSpotFormId : stagingHubSpotFormId}
              loading={<div>Loading...</div>}
              onReady={() => setFormLoaded(true)}
              submitButtonClass="custom-hs-button"
              cssClass="custom-hs-css hs-form-lander"
            />
          </>
        )}
        {formUrl && !stagingHubSpotFormId && !productionHubSpotFormId && <IFrame src={formUrl} />}
      </FormWrapper>
    </Wrapper>
  )
}

export default FormLander

export const query = graphql`
  fragment FormLander on PrismicPageDataBodyFormLander {
    ...SliceBase
    primary {
      has_top_padding
      form_url {
        ...LinkContent
      }
      calendly_follow_up_page
      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      production_hubspot_form_id
      subtitle {
        ...TextContent
      }
      staging_hubspot_form_id
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      title {
        ...TextContent
      }
      video_url {
        link_type
        ...LinkContent
      }
      audio_description_link {
        link_type
        ...LinkContent
      }
      form_heading {
        ...TextContent
      }
    }
  }
`
