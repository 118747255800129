import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import Text from '../common/Text'
import { theme } from '../../styles'

const Content = styled.div`
  padding: 20px;
  background-color: #1d2230;
  border-radius: 8px;

  p {
    font-family: ${theme.fonts.family.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
    margin-bottom: 0 !important;
  }

  pre {
    white-space: normal;
  }
`

const CodeBlock = ({ primary }) => {
  const codeBlock = <RichText render={primary.code_block.richText} />
  const codeColor = idx(primary, _ => _.color)

  return (
    <Content>
      <Text color={codeColor || 'white'} richText>
        {codeBlock}
      </Text>
    </Content>
  )
}

export default CodeBlock

export const query = graphql`
  fragment PostCodeBlock on PrismicPostDataBodyCodeBlock {
    ...SliceBase
    primary {
      code_block {
        ...TextContent
      }
      color
    }
  }
`
