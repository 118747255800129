/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { getPrismicPosts } from '../../../utils/prismicPostAPI'

import Text from '../../common/Text'
import TruncatedPagination from '../../common/TruncatedPagination'
import PressGridItem from './PressGridItem'
import { theme } from '../../../styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  position: relative;
`

const BG = styled.div`
  position: absolute;
  top: 0;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.offWhite};
`

const GridWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  flex-wrap: wrap;
  grid-column-gap: 24px;
  margin: 32px 0px;
  grid-gap: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-bottom: 0px;
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin-top: 24px;
  }
`

const BottomWrapper = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

const PressReleasesGrid = () => {
  const [data, setData] = useState()
  const [truncatedData, setTruncatedData] = useState()
  const [truncated, setTruncated] = useState(false)
  const [page, setPage] = useState(1)
  const [activePage, setActivePage] = useState(1)

  const onPreviousClick = () => {
    if (activePage !== 1) {
      setPage(page - 1)
      setActivePage(page - 1)
    }
  }
  const onNextClick = () => {
    if (activePage !== data.totalPages) {
      setPage(page + 1)
      setActivePage(page + 1)
    }
  }

  useEffect(() => {
    try {
      getPrismicPosts({
        post_type: 'Press',
        page,
        pageSize: 6,
      }).then(res => {
        const pagesArr = new Array(res.total_pages).fill(' ')
        pagesArr.length = 8
        // eslint-disable-next-line no-unused-expressions
        page >= 6 ? setTruncated(true) : setTruncated(false)
        setTruncatedData(pagesArr)
        setData(res)
      })
    } catch (error) {
      console.log(error)
    }
  }, [page])

  return (
    <Wrapper>
      <Header>
        <Text heading2 center>
          Press Releases
        </Text>
      </Header>
      <GridWrapper>
        {data &&
          data.results &&
          data.results.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${item.data.title[0].text}-${i}`}>
              <PressGridItem
                uid={item.uid}
                publishDate={item.data.publish_date}
                title={item.data.title[0].text}
                category={item.data.press_post_category}
                index={i}
              />
            </Fragment>
          ))}
      </GridWrapper>
      <BottomWrapper role="navigation" aria-label="pagination" id="pagination-nav">
        <TruncatedPagination
          data={data}
          page={page}
          activePage={activePage}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          truncated={truncated}
          truncatedData={truncatedData}
          setPage={setPage}
          setActivePage={setActivePage}
        />
      </BottomWrapper>
      <BG className="bg" />
    </Wrapper>
  )
}

export default PressReleasesGrid
