/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import EarningsReportQ22020 from './earning-reports/EarningsReportQ22020'
import EarningsReportQ32020 from './earning-reports/EarningsReportQ32020'
import EarningsReportQ42020 from './earning-reports/EarningsReportQ42020'
import EarningsReportQ12021 from './earning-reports/EarningsReportQ12021'
import EarningsReportQ22021 from './earning-reports/EarningsReportQ22021'
import EarningsReportQ32021 from './earning-reports/EarningsReportQ32021'
import EarningsReportQ42021 from './earning-reports/EarningsReportQ42021'

const PostEarningsTable = ({ primary }) => {
  const { table_title } = primary

  if (table_title.text === 'second-quarter-2020') return <EarningsReportQ22020 />
  if (table_title.text === 'third-quarter-2020') return <EarningsReportQ32020 />
  if (table_title.text === 'fourth-quarter-2020') return <EarningsReportQ42020 />
  if (table_title.text === 'first-quarter-2021') return <EarningsReportQ12021 />
  if (table_title.text === 'second-quarter-2021') return <EarningsReportQ22021 />
  if (table_title.text === 'third-quarter-2021') return <EarningsReportQ32021 />
  if (table_title.text === 'fourth-quarter-2021') return <EarningsReportQ42021 />
  return null
}

export default PostEarningsTable

export const query = graphql`
  fragment PostEarningsTable on PrismicPostDataBodyPostEarningsTable {
    ...SliceBase
    primary {
      table_title {
        ...TextContent
      }
    }
  }
`
