/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'
import GatsbyLink from '../../utils/GatsbyLink'

import Text from '../common/Text'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  bottom: ${p => (p.overlapTop ? '50%' : '0')};
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${p => theme.color[p.backgroundColor] || p.theme.color.white};
`

const Wrapper = styled.div`
  position: relative;
  padding: 24px 0px;
  width: 100%;
  ${p => p.hasMarginBottom && `margin-bottom: 80px;`}

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 40px 0px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  background-color: ${theme.color.white};
  margin: 0 auto;

  @media (max-width: 1050px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const LogosWrapper = styled.div`
  width: 100%;
  max-width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: ${p => (p.hasTitle ? '8px 8px 12px 8px' : '12px 0px')};
`

const Logo = styled.img`
  height: 68px;
  object-fit: contain;
  padding: 16px;

  @media (max-width: 1050px) {
    height: 50px;
    padding: 12px;
  }
`

const FeaturedLogos = ({ items, primary }) => {
  const title = <RichText render={primary.heading.richText} serializeHyperlink={GatsbyLink} />
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const overlapTop = primary.overlap_top
  const hasMarginBottom = primary.has_margin_bottom

  return (
    <Wrapper id={anchorId || null} overlapTop={overlapTop} hasMarginBottom={hasMarginBottom}>
      <Container>
        {title && idx(title, _ => _.props.render[0].text) && (
          <Text font="bodyHeavy" center heading2 richText style={{ paddingTop: '20px' }}>
            {title}
          </Text>
        )}
        <LogosWrapper hasTitle={idx(title, _ => _.props.render[0].text)}>
          {items.map((item, i) =>
            item.logo_link.url ? (
              <a
                href={item.logo_link && item.logo_link.url}
                target={item.logo_link && item.logo_link.url ? '_blank' : null}
                rel="noopener noreferrer"
                key={item.logo_link.url}
              >
                {getImage(item.logo, Logo)}
                {item.logo_link && item.logo_link.url && (
                  <OpenNewTabHiddenSpan
                    overGraphic
                    text={(item.logo && item.logo.alt && item.logo.alt.split('logo')[0]) || ''}
                  />
                )}
              </a>
            ) : (
              getImage(item.logo, Logo, { key: item.logo.url })
            )
          )}
        </LogosWrapper>
      </Container>
      <BG className="bg" backgroundColor={backgroundColor} overlapTop={overlapTop} />
    </Wrapper>
  )
}

export default FeaturedLogos

export const query = graphql`
  fragment FeaturedLogos on PrismicPageDataBodyFeaturedLogos {
    ...SliceBase
    items {
      logo {
        alt
        url
        gatsbyImageData(width: 180)
      }
      logo_link {
        url
        uid
      }
    }
    primary {
      anchor_id
      background_color
      overlap_top
      has_margin_bottom
      heading {
        ...TextContent
      }
    }
  }
`
