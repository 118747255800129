import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'

import { trackEvent } from '../../utils/segmentTrack'
import Text from './Text'
import { OpenNewTabHiddenSpan } from './NewTabHiddenSpan'
import chevronPurple from '../../../static/icons/arrow-icons/chevron-icon-right-purple500.svg'
import rightArrowPurple from '../../../static/icons/arrow-icons/right-arrow-icon-purple500.svg'
import rightArrowWhite from '../../../static/icons/arrow-icons/right-arrow-icon-white.svg'
import chevronWhite from '../../../static/icons/arrow-icons/chevron-icon-right-white.svg'
import rightArrowBlack from '../../../static/icons/arrow-icons/right-arrow-icon-black.svg'
import { addSmoothScrollClass } from '../../utils/addSmoothScrollClass'
import { theme } from '../../styles'

const ButtonLinkWrapper = styled(Link)`
  position: relative;
  background-color: ${theme.color.purple500};
  padding: 16px 32px;
  border-radius: 8px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 150ms;
  z-index: 1;
  height: fit-content;
  width: fit-content;

  p {
    line-height: 1;
    color: ${theme.color.white};
    white-space: nowrap;
  }

  :hover {
    background-color: ${theme.color.purple900};
  }

  :focus {
    background-color: ${theme.color.purple500};
  }

  ${p => {
    switch (p.buttontype) {
      case 'primary':
        return `
          background-color: ${theme.color.purple500};

          p {
            color: ${theme.color.white};
          }

          :hover {
            background-color: ${theme.color.purple900};
          }

          :focus {
            background-color: ${theme.color.purple500};
          }
        `
      case 'secondary':
        return `
          background-color: transparent;
          border-color: ${theme.color.purple500};

          p {
            color: ${theme.color.purple500};
          }

          :hover {
            background-color: transparent;
            border-color: ${theme.color.offBlack};

            p {
              color: ${theme.color.offBlack};
            }
          }

          :focus {
            background-color: transparent;
            border-color: ${theme.color.purple500};

            p {
              color: ${theme.color.purple500};
            }
          }
        `
      case 'tertiary':
        return `
          background-color: ${theme.color.offBlack};
          border-color: transparent;

          p {
            color: ${theme.color.white};
          }

          :hover {
            border-color: ${theme.color.offBlack};
            background-color: transparent;

            p {
              color: ${theme.color.offBlack};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.offBlack};

            p {
              color: ${theme.color.offBlack};
            }
          }
        `
      case 'tertiary reversed':
        return `
          background-color: ${theme.color.white};
          border-color: transparent;

          p {
            color: ${theme.color.offBlack};
          }

          :hover {
            border-color: ${theme.color.white};
            background-color: transparent;

            p {
              color: ${theme.color.white};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.white};
            }
          }
        `
      case 'reversed dark':
        return `
          background-color: transparent;
          border-color: ${theme.color.white};

          p {
            color: ${theme.color.white};
          }

          :hover {
            border-color: transparent;
            background-color: ${theme.color.white};

            p {
              color: ${theme.color.offBlack};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.white};
            }
          }
        `
      case 'reversed dark arrow':
        return `
          background-color: transparent;
          border-color: ${theme.color.white};
          flex-direction: row-reverse;

          p {
            color: ${theme.color.white};
            font-size: ${theme.fonts.size.smallBodyHeavy};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            background: url(${chevronWhite});
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            margin-left: 12px;
            transform: translateY(1px);
          }

          :hover {
            border-color: transparent;
            background-color: ${theme.color.white};

            p {
              color: ${theme.color.offBlack};
            }

            ::before {
              content: '';
              background: url(${rightArrowBlack});
              background-position: center;
              background-repeat: no-repeat;
              transform: translateX(-4px) translateY(1px);
              margin-left: 12px;
            }
          }
          :focus {
            border-color: transparent;
            background-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.offBlack};
            }

            ::before {
              content: '';
              background: url(${rightArrowBlack});
              background-position: center;
              background-repeat: no-repeat;
              transform: translateX(-4px) translateY(1px);
              margin-left: 12px;
            }
          }
          @media(max-width: 800px) {
            ::before {
              display: none;
            }
          }
        `
      case 'link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.purple500};
            font-size: ${theme.fonts.size.smallBodyHeavy};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background: url(${chevronPurple});
            background-position: center;
            top: 0;
            bottom: 0;
            left: 100%;
            background-repeat: no-repeat;
            right: 0;
            width: 20px;
            margin-left: 8px;
            margin-top: 1px;
          }

          :hover {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.purple500};
            }

            ::before {
              background: url(${rightArrowPurple});
              content: '';
              position: absolute;
              background-position: center;
              top: 0;
              bottom: 0;
              left: 100%;
              background-repeat: no-repeat;
              right: 0;
              width: 20px;
              margin-left: 12px;
              margin-top: 1px;
            }
          }
          :focus {
            border-color: transparent;
            background-color: transparent;
            padding: 3px 3px;

            p {
              color: ${theme.color.purple500};
            }

            ::before {
              background: url(${rightArrowPurple});
              content: '';
              position: absolute;
              background-position: center;
              top: 0;
              bottom: 0;
              left: 100%;
              background-repeat: no-repeat;
              right: 0;
              width: 20px;
              margin-left: 4px;
              margin-top: 1px;
              border: none;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `
      case 'white link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.white};
            font-size: ${theme.fonts.size.smallBodyHeavy};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background: url(${chevronWhite});
            background-position: center;
            top: 0;
            bottom: 0;
            left: 100%;
            background-repeat: no-repeat;
            right: 0;
            width: 20px;
            margin-left: 8px;
            margin-top: 1px;
          }

          :hover {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.white};
            }

            ::before {
              background: url(${rightArrowWhite});
              content: '';
              position: absolute;
              background-position: center;
              top: 0;
              bottom: 0;
              left: 100%;
              background-repeat: no-repeat;
              right: 0;
              width: 20px;
              margin-left: 12px;
              margin-top: 1px;
            }
          }
          :focus {
            border-color: transparent;
            background-color: transparent;
            padding: 3px 3px;
            outline: ${theme.darkBgFocusOutline};


            p {
              color: ${theme.color.white};
            }

            ::before {
              background: url(${rightArrowWhite});
              content: '';
              position: absolute;
              background-position: center;
              top: 0;
              bottom: 0;
              left: 100%;
              background-repeat: no-repeat;
              right: 0;
              width: 20px;
              margin-left: 4px;
              margin-top: 1px;
              border: none;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `
      default:
        return null
    }
  }};
  ${p => {
    switch (p.size) {
      case 'small':
        return `
          padding: 8px 24px;

          p {
            font-size: ${theme.fonts.size.smallBodyHeavy};
          }
        `
      default:
        return null
    }
  }};

  @media (max-width: 1050px) {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ButtonWrapper = ButtonLinkWrapper.withComponent('button')
const ExternalButtonWrapper = ButtonLinkWrapper.withComponent('a')

const Button = ({ text, buttonType, size, to, ref, noScroll, ...rest }) => {
  const windowGlobal = typeof window !== 'undefined' && window
  const html = windowGlobal && document.querySelector('html')

  const sameTab = !/http/.test(to) || /^audioeye.com$/.test(to)
  let internalTarget = false
  if (sameTab) {
    internalTarget = to && to.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')
  }
  const opensPdf = to && to.includes('.pdf')

  const onboardingButton = /#get-started-free\/?$/.test(to) || /get-started-free\/?$/.test(to)

  const oldOnClick = rest.onClick || (() => {})

  const onboardingOnClick = e => {
    e.preventDefault()
    html.classList.remove('smooth-scroll')
    navigate('#get-started-free')
  }

  rest.onClick = e => {
    e.stopPropagation()

    trackEvent(`Button Clicked`, {
      text,
      page_url: window.location.href,
      destination: to,
    })

    if (opensPdf) {
      trackEvent(`PDF Downloaded`, {
        pdf_name: to,
      })
    }

    if (onboardingButton) {
      onboardingOnClick(e)
      return
    }

    // Smooth scroll for anchor links
    if (!onboardingButton && !noScroll) addSmoothScrollClass()

    oldOnClick(e)
  }

  const ariaText = typeof text === 'object' && text.props.render.length > 0 ? text.props.render[0].text : text

  const el = (
    <>
      <Text bodyHeavy center>
        {text}
      </Text>
      {!sameTab && <OpenNewTabHiddenSpan text={text} fileType={opensPdf && 'PDF'} isButton />}
    </>
  )

  if (onboardingButton) {
    return (
      <ButtonWrapper
        onClick={onboardingOnClick}
        buttontype={buttonType}
        size={size}
        aria-label={ariaText}
        className={`button ${(rest.className && rest.className) || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonWrapper>
    )
  }

  if (!sameTab && !internalTarget && to) {
    return (
      <ExternalButtonWrapper
        buttontype={buttonType}
        size={size}
        href={to}
        aria-label={ariaText}
        className={`button ${(rest.className && rest.className) || ''}`}
        ref={ref}
        target="_blank"
        {...rest}
      >
        {el}
      </ExternalButtonWrapper>
    )
  }

  if (internalTarget) {
    return (
      <ButtonLinkWrapper
        buttontype={buttonType}
        size={size}
        to={internalTarget}
        aria-label={ariaText}
        className={`button ${(rest.className && rest.className) || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonLinkWrapper>
    )
  }

  if (!internalTarget && !internalTarget && !to) {
    return (
      <ButtonWrapper
        buttontype={buttonType}
        size={size}
        aria-label={ariaText}
        className={`button ${(rest.className && rest.className) || ''}`}
        {...rest}
        ref={ref}
      >
        {el}
      </ButtonWrapper>
    )
  }
  return null
}

export default Button
