/* eslint-disable no-param-reassign */

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { theme } from '../../../styles'
import Text from '../../common/Text'

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: ${theme.color.white};
  height: max-content;
  height: fit-content;
  width: 475px;
  overflow-y: auto;
  border-radius: 10px;

  @media (max-width: 1050px) {
    width: 100%;
    height: auto;
    min-height: 100%;
    justify-content: flex-start;
  }

  @media (max-width: 800px) {
    overflow-y: scroll;
  }

  .focus-trap {
    &:focus {
      outline: none;
    }
  }

  .focus-trap-wrapper {
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
    @media (max-width: 1050px) {
      max-height: 100%;
    }
  }

  .hide-desktop {
    display: none;
    @media (max-width: 1050px) {
      display: flex;
    }
  }

  .hide-mobile {
    display: flex;
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

export const RequiredWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;

  span {
    color: ${theme.color.red500};
    line-height: 1;
  }
`

export const StageWrapper = styled.div`
  flex-direction: ${p => (p.checkSite ? 'column' : 'row')};
  text-align: ${p => (p.checkSite ? 'center' : 'left')};

  ${p =>
    p.open
      ? css`
          position: relative;
          width: 100%;
          display: flex;
        `
      : css`
          position: absolute;
          left: 500vw;
          display: none;
        `}

  @media (max-width: 1050px) {
    flex-direction: column;
    height: fill-available;
    overflow-y: auto;
  }
`

export function prependUrl(url, options) {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``)
  }
  url = url.trim()
  options = {
    https: true,
    ...options,
  }
  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url
  }
  return url.replace(/^(?!(?:\w+:)?\/\/)/, options.https ? 'https://' : 'http://')
}

export const FormHeader = () => (
  <>
    <Text center role="heading" aria-level="2" color="purple500" heading4 id="form-header">
      Start Free 14-Day Trial
    </Text>
    <RequiredWrapper>
      <span>*&nbsp;</span>
      <Text smallBodyHeavy color="gray700" style={{ lineHeight: '1' }}>
        Required
      </Text>
    </RequiredWrapper>
  </>
)
