import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'
import GatsbyLink from '../../utils/GatsbyLink'
import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : p.theme.color.offWhite)};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const BlockWrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 0 10px 3px ${p => p.theme.color.dropShadow};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 16px;
  min-width: 350px;

  @media (max-width: 800px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
    margin: 16px 0px;
    margin-top: 8px;
  }
`

const Header = styled.div`
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : p.theme.color.green100)};
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 20px;
  border-radius: 8px 8px 0px 0px;
`

const BlockBody = styled.div`
  background-color: ${p => p.theme.color.white};
  width: 100%;
  height: 100%;
  padding: 20px;
  min-height: 225px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ul {
    margin-left: 20px;
    li {
      text-align: left;
    }
  }
  @media (max-width: 800px) {
    min-height: 200px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: -16px;
`

const modifyColors = color => {
  switch (color) {
    case 'purple200':
      return {
        headerColor: 'purple200',
        headingColor: 'purple500',
      }
    case 'green100':
      return {
        headerColor: 'green100',
        headingColor: 'green700',
      }
    default:
      return {
        headerColor: 'offWhite',
        headingColor: 'offBlack',
      }
  }
}

const Block = ({ title, text, linkText, linkUrl, headerColor }) => {
  const modifiedColors = modifyColors(headerColor)

  return (
    <BlockWrapper>
      <Header backgroundColor={modifiedColors.headerColor}>
        {title && (
          <>
            <Text heading4 aria-level="3" center color={modifiedColors.headingColor}>
              {title}
            </Text>
            <Space height={12} />
          </>
        )}
      </Header>
      <BlockBody>
        {text && (
          <Text richText font="smallBody" center>
            {text}
          </Text>
        )}
        {linkUrl && (
          <LinkWrapper>
            <Button buttonType="link CTA" to={linkUrl} text={linkText} />
          </LinkWrapper>
        )}
      </BlockBody>
    </BlockWrapper>
  )
}

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 0px 150px;
  position: relative;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding: 50px 0px;
    padding-top: ${p => (p.hasTopPadding ? '48px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '48px' : '0px')};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 80px 24px 80px;
  text-align: center;

  @media (max-width: 1050px) {
    padding: 0px;
    padding-bottom: 16px;
  }

  @media (max-width: 800px) {
    * {
      text-align: center !important;
    }
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  width: calc(100% + 32px);
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -16px;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
  }
`

const BannerBlocks = ({ items, primary }) => {
  const title = idx(primary, _ => _.title.richText[0].text) && (
    <RichText render={primary.title.richText} serializeHyperlink={GatsbyLink} />
  )
  const text = idx(primary, _ => _.text.richText[0].text) && (
    <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  )
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)

  return (
    <BannerWrapper
      id={anchorId || null}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
    >
      {(title || text) && (
        <InfoWrapper>
          {title && (
            <>
              <Text font="heading3" center role="heading" aria-level="2" richText>
                {title}
              </Text>
              <Space height={16} />
            </>
          )}
          {text && (
            <Text center richText>
              {text}
            </Text>
          )}
        </InfoWrapper>
      )}
      <BlocksWrapper>
        {items &&
          items[0] &&
          items.map((item, i) => (
            <Block
              index={i}
              title={idx(item.block_title, _ => _.text)}
              text={<RichText render={item.block_text.richText} serializeHyperlink={GatsbyLink} />}
              linkText={idx(item.link_text, _ => _.text)}
              linkUrl={idx(item, _ => _.link_url.url) || idx(item, _ => _.link_url.uid)}
              headerColor={item.header_color}
              key={`banner-block-${i}`}
            />
          ))}
      </BlocksWrapper>
      <BG className="bg" backgroundColor={backgroundColor} />
    </BannerWrapper>
  )
}

export default BannerBlocks

export const query = graphql`
  fragment BannerBlocks on PrismicPageDataBodyBannerBlocks {
    ...SliceBase
    items {
      header_color
      block_text {
        ...TextContent
      }
      block_title {
        ...TextContent
      }
      link_text {
        ...TextContent
      }
      link_url {
        ...LinkContent
      }
    }
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      text {
        ...TextContent
      }
      title {
        ...TextContent
      }
    }
  }
`
