/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'
import Modal from '@material-ui/core/Modal'
import DialogContent from '@material-ui/core/DialogContent'
import GatsbyLink from '../../utils/GatsbyLink'

import Text from '../common/Text'
import TextLink from '../common/TextLink'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import VimeoIframe from '../common/VimeoIframe'
import violetChevron from '../../../static/icons/arrow-icons/chevron-icon-violet.svg'
import getImage from '../../utils/getImage'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${p => p.theme.color[p.backgroundColor] || p.theme.color.white};

  @media (max-width: 1050px) {
    margin-top: -40px;
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${p =>
    p.title
      ? `
    width: calc(100% + 300px);
    margin-left: -150px;
    padding: 80px 150px;
  `
      : `
    width: 100%;
  `};

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 40px 10px;
  }
`

const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '0px')};

  p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 1300px) {
    padding: ${p => (p.hasLink ? '40px 0px' : '40px 0px 32px 0px')};
  }

  @media (max-width: 1050px) {
    padding: 0px;
  }
`

const ImagesWrapper = styled.div`
  width: 100%;
  max-width: 1050px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: ${p => p.hasLink && '10px'};
  margin-top: 10px;

  @media (max-width: 1300px) {
    margin-bottom: ${p => p.hasLink && '8px'};
  }

  @media (max-width: 1050px) {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Logo = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  padding: 16px;

  @media (max-width: 1050px) {
    width: 125px;
  }
  @media (max-width: 800px) {
    width: 90px;
    padding: 8px;
  }
`

const ArrowIcon = styled.img`
  margin-left: 8px;
  margin-top: 3px;
  width: 16px;
`

const ModalWrapper = styled(DialogContent)`
  height: 100vh;
  padding: 0px;
  overflow-y: unset;
  position: relative;
`

const CloseIcon = styled.img`
  width: 32px;
  height: 32px;
  padding: 9px;
  position: absolute;
  right: 20%;
  top: 22%;
  background-color: ${p => p.theme.color.white};
  border-radius: 50%;
  z-index: 9999;

  @media (max-width: 1900px) {
    top: 29%;
  }

  @media (max-width: 1300px) {
    top: 33%;
  }
  @media (max-width: 1050px) {
    top: 28%;
    right: 4%;
  }

  @media (max-width: 800px) {
    top: 31%;
  }
`

const TrustedByCompanies = ({ items, primary }) => {
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)
  const title = <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  const backgroundColor = primary.background_color
  const linkText = idx(primary, _ => _.link_text.text)
  const linkUrl = idx(primary, _ => _.link_url.url) || idx(primary, _ => _.link_url.uid)
  const video = idx(primary, _ => _.video_link.url)
  const anchorId = primary.anchor_id

  const [open, setOpen] = useState(false)

  return (
    <Wrapper title={typeof title === 'string' ? title : null} id={anchorId || null}>
      {items && items[0] && items[0].company_image && items[0].company_image.url && (
        <CompaniesWrapper
          hasLink={linkText}
          hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
          hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
        >
          <Text font="bodyHeavy" center aria-level="2" role="heading" richText color="gray700">
            {title || `Proudly partnered with`}
          </Text>
          <ImagesWrapper hasLink={linkText}>
            {items.map(item =>
              item.link.url ? (
                <a
                  href={item.link && item.link.url}
                  target={item.link && item.link.url ? '_blank' : null}
                  rel="noopener noreferrer"
                  key={item.company_image.url}
                >
                  {getImage(item.company_image, Logo)}
                  {item.link && item.link.url && <OpenNewTabHiddenSpan overGraphic />}
                </a>
              ) : (
                getImage(item.company_image, Logo, { key: item.company_image.url })
              )
            )}
          </ImagesWrapper>
          {(linkText || video) && (
            <div style={{ display: 'flex' }}>
              {video && (
                <TextLink
                  color="purple500"
                  font="smallBodyHeavy"
                  aria-label="Play AudioEye video opens in a modal"
                  onKeyDown={e => {
                    if (e.key === 'Enter') setOpen(true)
                  }}
                  onClick={() => video && setOpen(true)}
                  text={linkText}
                />
              )}
              {linkText && !video && <TextLink color="purple500" font="smallBodyHeavy" to={linkUrl} text={linkText} />}
              <ArrowIcon src={violetChevron} style={{ transform: 'rotate(270deg)' }} />
            </div>
          )}
          {video && (
            <Modal open={(video && open) === true} onClose={() => video && setOpen(false)} aria-label="Video modal">
              <ModalWrapper>
                <CloseIcon
                  tabIndex="0"
                  src={CrossIcon}
                  aria-label="close"
                  role="button"
                  className="focus-outline"
                  onClick={() => video && setOpen(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') setOpen(false)
                  }}
                />
                <VimeoIframe isModal allowFullScreen video={video} autoPlay={false} />
              </ModalWrapper>
            </Modal>
          )}
        </CompaniesWrapper>
      )}
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default TrustedByCompanies

export const query = graphql`
  fragment TrustedByCompanies on PrismicPageDataBodyTrustedByCompanies {
    ...SliceBase
    items {
      company_image {
        alt
        url
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
      }
    }
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      background_color
      link_url {
        uid
        url
      }
      video_link {
        url
      }
      link_text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
