module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"audioeye-web","accessToken":"MC5YMkQtSHhNQUFDRUF6a1Ja.LG5o77-9De-_vUwR77-977-977-9V--_vQrvv73vv70e77-9Xu-_vT_vv70l77-977-9Tu-_vSPvv71FYCE","promptForAccessToken":true,"apiEndpoint":"https://audioeye-web.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital & Website Accessibility Solutions | AudioEye","short_name":"Digital & Website Accessibility Solutions | AudioEye","description":"AudioEye's patented industry-leading web accessibility solution and team of experts provide full support to help your website reach ADA & WCAG compliance.","start_url":"/","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cbbc7ab39a763fa018c3eb5d7c07ed0f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://beta.audioeye.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1e9b22f0b779494e818ad797b1d82585@o1170430.ingest.sentry.io/6263942","sampleRate":0.2,"tracesSampleRate":0.2},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
