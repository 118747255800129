/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import Feature from '../components/pageSlices/Feature'
import TrustedByCompanies from '../components/pageSlices/TrustedByCompanies'
import BannerTitle from '../components/pageSlices/BannerTitle'
import BannerTitleScan from '../components/pageSlices/BannerTitleScan'
import CTABannerLogos from '../components/pageSlices/CTABannerLogos'
import FaqType from '../components/FaqType'
import FlexibleText from '../components/pageSlices/FlexibleText'
import FeaturedIntegrations from '../components/pageSlices/FeaturedIntegrations'
import IntegrationPlatforms from '../components/pageSlices/IntegrationPlatforms'
import ResourcesPanel from '../components/pageSlices/ResourcesPanel'
import TwoColumnCards from '../components/pageSlices/TwoColumnCards'
import BannerInfo from '../components/pageSlices/BannerInfo'
import BannerBlocks from '../components/pageSlices/BannerBlocks'
import FormLander from '../components/form/hubspot/FormLander'
import BannerForm from '../components/pageSlices/BannerForm'
import RichTextSlice from '../components/pageSlices/RichText'
import Quote from '../components/pageSlices/Quote'
import StaticContentBlock from '../components/pageSlices/StaticContentBlock'
import FeaturedTabs from '../components/pageSlices/FeaturedTabs'
import PricingHero from '../components/pageSlices/pricing/PricingHero'
import ManagedCards from '../components/pageSlices/pricing/ManagedCards'
import FeaturesTable from '../components/pageSlices/pricing/FeaturesTable'
import ScannerBanner from '../components/pageSlices/ScannerBanner'
import AnnouncementBanner from '../components/pageSlices/AnnouncementBanner'
import QuicklinksBar from '../components/pageSlices/QuicklinksBar'
import FeaturedLogos from '../components/pageSlices/FeaturedLogos'
import Image from '../components/pageSlices/Image'
import FourColCards from '../components/pageSlices/FourColCards'
import Sitemap from '../components/pageSlices/Sitemap'

export const renderPageSlices = contentBlocks =>
  contentBlocks
    .filter(x => x)
    .map(({ slice_type = '', ...props }, i) => {
      if (connectors[slice_type]) {
        return React.createElement(connectors[slice_type], {
          key: props.id ? `${slice_type}-${props.id}-${i}` : `${slice_type}-${i}`,
          ...props,
        })
      }
      if (process.env.NODE_ENV !== 'production') console.warn(`No connector found for ${slice_type}`)
      return null
    })

const connectors = {
  feature: Feature,
  'banner-title': BannerTitle,
  'trusted-by-companies': TrustedByCompanies,
  'cta-banner-logos': CTABannerLogos,
  'faq-section': FaqType,
  'flexible-text': FlexibleText,
  'featured-integrations': FeaturedIntegrations,
  'integration-platforms': IntegrationPlatforms,
  'resources-panel': ResourcesPanel,
  'banner-blocks': BannerBlocks,
  'banner-info': BannerInfo,
  'form-lander': FormLander,
  'rich-text': RichTextSlice,
  quote: Quote,
  'static-component': StaticContentBlock,
  'featured-tabs': FeaturedTabs,
  'pricing-hero': PricingHero,
  'managed-cards': ManagedCards,
  'features-table': FeaturesTable,
  'banner-title-scan': BannerTitleScan,
  'two-column-cards': TwoColumnCards,
  scanner: ScannerBanner,
  'announcement-banner': AnnouncementBanner,
  'quicklinks-bar': QuicklinksBar,
  'featured-logos': FeaturedLogos,
  image: Image,
  'four-col-cards': FourColCards,
  sitemap: Sitemap,
  'banner-form': BannerForm,
}

export const query = graphql`
  fragment PageDataBody on PrismicPageDataBodySlicesType {
    # Type-specific fields
    ...Feature
    ...BannerTitle
    ...TrustedByCompanies
    ...CTABannerLogos
    ...FlexibleText
    ...FaqType
    ...FeaturedIntegrations
    ...IntegrationPlatforms
    ...ResourcesPanel
    ...BannerBlocks
    ...BannerInfo
    ...FormLander
    ...RichTextSlice
    ...Quote
    ...FeaturedTabs
    ...StaticContentBlock
    ...PricingHero
    ...ManagedCards
    ...FeaturesTable
    ...BannerTitleScan
    ...TwoColumnCards
    ...ScannerBanner
    ...AnnouncementBanner
    ...QuicklinksBar
    ...FeaturedLogos
    ...Image
    ...FourColCards
    ...Sitemap
    ...BannerForm
  }
`
