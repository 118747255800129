/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Link, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'
import styled from '@emotion/styled'

import GatsbyLink from '../../utils/GatsbyLink'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import { trackEvent } from '../../utils/segmentTrack'
import Text from '../common/Text'
import Space from '../common/Space'
import TextLink from '../common/TextLink'
import chevron from '../../../static/icons/arrow-icons/chevron-icon-violet.svg'
import getImage from '../../utils/getImage'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : p.theme.color.white)};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 150px;
`

const BlockWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0px 0px 14px 3px rgba(8, 55, 219, 0.05);
  background-color: ${p => p.theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  min-height: 175px;
  width: 250px;
  margin: 16px;
  padding: 24px;

  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1050px) {
    margin: 0px 0px 24px 0px;
    width: 80%;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Image = styled.img`
  max-height: 64px;
  max-width: 150px;
  min-width: 110px;

  @media (max-width: 1050px) {
    min-width: 90px;
    max-width: 100%;
  }
`

const Block = ({ image, link, type, newTab, linkText }) => {
  const opensPdf = link && link.includes('.pdf')

  return (
    <BlockWrapper>
      {link ? (
        type ? (
          <Link to={link}>
            <div style={{ display: 'flex', minHeight: '88px', alignItems: 'center' }}>{getImage(image, Image)}</div>
            {linkText && (
              <Text smallBody center underline color="purple500">
                {linkText}
              </Text>
            )}
          </Link>
        ) : (
          <a
            href={link}
            onClick={e => {
              e.stopPropagation()
              if (opensPdf) {
                trackEvent(`PDF Downloaded`, {
                  pdf_name: link,
                })
              }
            }}
            target={newTab ? '_blank' : null}
            rel={newTab ? 'noreferrer' : null}
          >
            <div style={{ display: 'flex', minHeight: '88px', alignItems: 'center' }}>{getImage(image, Image)}</div>
            {linkText && (
              <Text smallBody center underline color="purple500">
                {linkText}
              </Text>
            )}
            {newTab && <OpenNewTabHiddenSpan text={linkText || ''} fileType={opensPdf && 'PDF'} />}
          </a>
        )
      ) : (
        <>
          <div style={{ display: 'flex', minHeight: '88px', alignItems: 'center' }}>{getImage(image, Image)}</div>
          {linkText && (
            <Text smallBody center underline color="purple500">
              {linkText}
            </Text>
          )}
        </>
      )}
    </BlockWrapper>
  )
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;

  @media (max-width: 1050px) {
    padding-bottom: 48px;
  }

  p {
    max-width: 700px;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`

const ArrowIcon = styled.img`
  margin-left: 8px;
  margin-top: 3px;
  width: 16px;
`

const IntegrationPlatforms = ({ items, primary }) => {
  const title = idx(primary, _ => _.title1.text)
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const linkText = idx(primary, _ => _.link_text.text)
  const linkUrl = idx(primary, _ => _.link_url.url)
  const backgroundColor = primary.background_color

  return (
    <Wrapper>
      <InfoWrapper>
        <Text heading3 aria-level="2" center>
          {title}
        </Text>
        {text && (
          <>
            <Space height={25} />
            <Text center>{text}</Text>
          </>
        )}
      </InfoWrapper>
      <BlocksWrapper>
        {items &&
          items[0] &&
          items.map((item, i) => (
            <Block
              image={item?.image}
              type={item?.link?.type}
              link={item?.link?.url}
              linkText={item?.link_text?.text}
              newTab={item?.link?.target === '_blank'}
              key={`integration-block-${i}`}
            />
          ))}
      </BlocksWrapper>
      {linkUrl && linkText && (
        <LinkWrapper>
          <TextLink to={linkUrl} text={linkText} />
          <ArrowIcon src={chevron} style={{ transform: 'rotate(270deg)' }} />
        </LinkWrapper>
      )}
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default IntegrationPlatforms

export const query = graphql`
  fragment IntegrationPlatforms on PrismicPageDataBodyIntegrationPlatforms {
    ...SliceBase
    items {
      image {
        alt
        url
        gatsbyImageData(width: 200)
      }
      link_text {
        ...TextContent
      }
      link {
        ...LinkContent
      }
    }
    primary {
      background_color
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      link_text {
        ...TextContent
      }
      link_url {
        ...LinkContent
      }
    }
  }
`
