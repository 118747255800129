import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import Text from './Text'

const Wrapper = styled.div`
  padding: ${p => (p.size === 'small' ? '8px 12px' : '8px 24px')};
  border-radius: 8px;
  background-color: ${p => theme.color[p.backgroundColor]};
  margin-left: ${p => (p.size === 'small' ? '4px' : '16px')};
  text-align: center;
  min-width: ${p => (p.size === 'regular' ? '100px' : '60px')};
  height: min-content;

  p {
    text-transform: uppercase;
    font-family: ${theme.fonts.family.heading1};
    font-size: ${p => theme.fonts.size[p.fontSize]};
  }

  @media (max-width: 800px) {
    padding: 8px 16px;
  }
`

const modifyColor = bgColor => {
  switch (bgColor) {
    case 'red':
      return {
        backgroundColor: 'red100',
        textColor: 'red900',
      }
    case 'green':
      return {
        backgroundColor: 'green100',
        textColor: 'green900',
      }
    default:
      return {
        backgroundColor: 'red100',
        textColor: 'red900',
      }
  }
}

const Tag = ({ color, text, size = 'regular', fontSize = 'smallBody', ariaHidden }) => {
  const modifiedColor = modifyColor(color)
  return (
    <Wrapper size={size} backgroundColor={modifiedColor.backgroundColor} aria-hidden={ariaHidden} fontSize={fontSize}>
      <Text color={modifiedColor.textColor || 'red900'}>{text || 'Not Compliant'}</Text>
    </Wrapper>
  )
}

export default Tag
