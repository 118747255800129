import React from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import chevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon.svg'

const PageBlockWrapper = styled.button`
  min-width: 40px;
  min-height: 35px;
  border: 1px solid ${p => p.theme.color.offBlack};
  border-radius: 3px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${p => (p.isActive ? p.theme.color.offBlack : p.theme.color.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 0px 10px;
  z-index: 2;

  &:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 1050px) {
    min-width: unset;
    margin-left: 8px;
    margin-top: 8px;
  }
`

const Ellipses = styled.div`
  display: flex;
  margin-left: 10px;
  padding: 0px 8px;
  justify-content: center;
  align-items: flex-end;
`

const Img = styled.img`
  width: 14px;
  transform: ${p => (p.previous ? 'rotate(90deg)' : 'rotate(270deg)')};
`

const modifyPage = page => {
  switch (page) {
    case 'previous':
      return <Img src={chevron} previous alt="" />
    case 'next':
      return <Img src={chevron} next alt="" />
    default:
      return page
  }
}

const PaginatedPageBlockItem = ({ totalPages, page, disabled, activePage, ariaLabel, ...props }) => {
  const modifiedPage = modifyPage(page)

  return (
    <PageBlockWrapper
      aria-label={ariaLabel || ''}
      disabled={disabled}
      isActive={page === activePage}
      aria-current={page === 'previous' || page === 'next' ? '' : page === activePage}
      aria-describedby="pagination-nav"
      key={page}
      {...props}
    >
      {page === 'last' && totalPages ? (
        <Text
          smallBodyHeavy
          color={page === activePage ? 'white' : ''}
          onClick={e => {
            if (e.target.parentNode) e.target.parentNode.blur()
          }}
        >
          {totalPages}
        </Text>
      ) : page === 'previous' || page === 'next' || page === 'last' ? (
        modifiedPage
      ) : (
        <Text
          smallBodyHeavy
          color={page === activePage ? 'white' : ''}
          onClick={e => {
            if (e.target.parentNode) e.target.parentNode.blur()
          }}
        >
          {page}
        </Text>
      )}
    </PageBlockWrapper>
  )
}

const TruncatedPagination = ({
  data,
  page,
  activePage,
  onPreviousClick,
  onNextClick,
  truncated,
  truncatedData,
  setPage,
  setActivePage,
}) => (
  <>
    <PaginatedPageBlockItem
      page="previous"
      ariaLabel="previous"
      disabled={activePage === 1}
      onClick={onPreviousClick}
      activePage={activePage}
    />
    {data &&
      data.total_pages &&
      truncatedData.map((block, i) =>
        page <= data.total_pages - 2 ? (
          <PaginatedPageBlockItem
            key={i}
            page={truncated ? page - 5 + i : i + 1}
            activePage={activePage}
            disabled={false}
            onClick={e => {
              if (truncated) {
                setPage(page - 5 + i)
                setActivePage(page - 5 + i)
              } else {
                setPage(i + 1)
                setActivePage(i + 1)
              }
              e.target.blur()
            }}
          />
        ) : (
          <>
            <PaginatedPageBlockItem
              key={i}
              page={data.total_pages - 7 + i}
              setPage={setPage}
              activePage={activePage}
              disabled={false}
              onClick={e => {
                if (e.target.textContent) {
                  setPage(Number(e.target.textContent))
                  setActivePage(Number(e.target.textContent))
                }
                e.target.blur()
              }}
            />
          </>
        )
      )}
    {data && data.total_pages && page <= data.total_pages - 4 && (
      <Ellipses aria-label="Ellipses. Additional pages hidden. Click on next button to proceed">
        <Text smallBodyHeavy aria-hidden>
          ...
        </Text>
      </Ellipses>
    )}
    {data && data.total_pages && page <= data.total_pages - 3 && (
      <PaginatedPageBlockItem
        page="last"
        ariaLabel={`${data && data.total_pages && data.total_pages}. Last page`}
        totalPages={data.total_pages}
        activePage={activePage}
        onClick={() => {
          setPage(data.total_pages)
          setActivePage(data.total_pages)
        }}
      />
    )}
    <PaginatedPageBlockItem
      disabled={data && data.total_pages && page === data.total_pages}
      page="next"
      ariaLabel="next"
      onClick={onNextClick}
      activePage={activePage}
    />
  </>
)

export default TruncatedPagination
