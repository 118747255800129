const checkAgencyKeywords = async (url, updateFormData) => {
  const threshold = 1
  let thresholdCount = 0
  const keywords = ['design', 'agency', 'web', 'studio', 'seo', 'digital', 'marketing']
  const keywordsFoundResponse = await fetch(`/hubspot/${'google'}`)
  if (keywordsFoundResponse && keywordsFoundResponse.ok) {
    const keywordResBody = await keywordsFoundResponse.json()
    keywords.forEach(word => {
      if (keywordResBody?.toLowerCase().includes(word)) {
        thresholdCount += 1
      }
    })

    thresholdCount >= threshold
      ? updateFormData({ agencyKeywordsFound: 'Yes' })
      : updateFormData({ agencyKeywordsFound: 'No' })
  }
}

export default checkAgencyKeywords
