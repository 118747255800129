import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import VimeoIframe from '../common/VimeoIframe'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 40px 0px;
`

const PostVideo = ({ primary }) => (
  <Wrapper>
    <VimeoIframe allowFullScreen video={primary.iframe.url} />
  </Wrapper>
)

export default PostVideo

export const query = graphql`
  fragment PostVideo on PrismicPostDataBodyVideo {
    ...SliceBase
    primary {
      iframe {
        ...LinkContent
      }
    }
  }
`
