/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import formatDate from '../../utils/formatDate'

import Text from '../common/Text'
import Space from '../common/Space'
import SharePost from './SharePost'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const LinkWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  p {
    text-decoration: none;
  }
`

const AnchorWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 48px;

  @media (max-width: 1050px) {
    padding-right: 16px;
  }
`

const ShareWrapper = styled.div`
  padding-top: 8px;
`

const VerticalHr = styled.div`
  height: 80px;
  width: 1px;
  background-color: ${p => p.theme.color.gray400};
  @media (max-width: 1050px) {
    display: none;
  }
`

const Img = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  margin-right: 24px;

  @media (max-width: 800px) {
    display: none;
  }
`

const AuthorContent = ({ image, name = 'Back', publishDate, description, title, inPost }) => (
  <>
    {getImage(image, Img)}
    <Col>
      <Space height={8} />
      {inPost ? <Text heading4>{name}</Text> : <Text heading1>{name}</Text>}
      <Space height={8} />
      {publishDate ? (
        <Text smallBody>Posted {publishDate && formatDate(publishDate)}</Text>
      ) : (
        <Text numberOfLines={3}>{description}</Text>
      )}
      {title && <Text bodyHeavy>{title}</Text>}
    </Col>
  </>
)

const AuthorSection = meta => (
  <Wrapper>
    {meta.uid ? (
      <LinkWrapper to={`/author/${meta.uid}`}>
        <AuthorContent {...meta} />
      </LinkWrapper>
    ) : (
      <AnchorWrapper href={meta.url} target={meta.target}>
        <AuthorContent {...meta} />
      </AnchorWrapper>
    )}

    {meta.date && (
      <>
        <VerticalHr />
        <Space width={48} tabletWidth={16} />
        <ShareWrapper>
          <SharePost />
        </ShareWrapper>
      </>
    )}
  </Wrapper>
)

export default AuthorSection
