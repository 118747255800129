import React from 'react'
import { graphql } from 'gatsby'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import styled from '@emotion/styled'
import GatsbyLink from '../../utils/GatsbyLink'
import Text from '../common/Text'
import Space from '../common/Space'
import Scanner from '../form/Scanner'

export const BG = styled.div`
  position: absolute;
  background-color: ${p => p.theme.color[p.bgColor] || p.theme.color.white};
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  margin-top: -80px;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 80px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '16px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '16px')};
`

const ScannerBanner = ({ primary }) => {
  const title = idx(primary, _ => _.title1.richText[0].text) && (
    <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  )
  const text = idx(primary, _ => _.text.richText[0].text) && (
    <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  )
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)

  return (
    <Wrapper
      id={anchorId || null}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
    >
      {title && (
        <>
          <Text font="heading2" center richText>
            {title}
          </Text>
          <Space height={32} />
        </>
      )}
      {text && (
        <>
          <Text center richtext>
            {text}
          </Text>
          <Space height={32} />
        </>
      )}
      <Scanner />
      <BG className="bg" bgColor={backgroundColor} />
    </Wrapper>
  )
}

export default ScannerBanner

export const query = graphql`
  fragment ScannerBanner on PrismicPageDataBodyScanner {
    ...SliceBase
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      title1 {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
  }

  fragment PostScanner on PrismicPostDataBodyScanner {
    ...SliceBase
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      title1 {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
  }
`
