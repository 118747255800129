module.exports = {
  title: 'Digital & Website Accessibility Solutions | AudioEye', // Navigation and Site Title
  titleAlt: 'Digital & Website Accessibility Solutions | AudioEye', // Title for JSONLD
  headline: 'Digital & Website Accessibility Solutions | AudioEye',
  description:
    "AudioEye's patented industry-leading web accessibility solution and team of experts provide full support to help your website reach ADA & WCAG compliance.",
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/meta/audioeye-default-social-banner-image.png', // Used for SEO
  bannerAlt:
    'Illustration of Artificial Intelligence Technology and Human Expertise and a quote stating Making Your Website Accessible For Everyone',
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Prismic', // shortname for manifest. MUST be shorter than 12 characters
  author: 'LekoArts', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@audioeyeinc', // Twitter Username
  facebook: 'AudioEye', // Facebook Site Name
  skipNavId: 'content', // ID for the "Skip to content" a11y feature
}
