/* eslint-disable no-unused-expressions */
import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import './src/styles/global.css'
import { trackEvent } from './src/utils/segmentTrack'
import { linkResolver } from './src/utils/linkResolver'
import { theme } from './src/styles'
import highlander from './src/utils/highlander'
import alternator from './src/utils/alternator'
import PageTemplate from './src/templates/page'
import PostTemplate from './src/templates/post'
import AuthorTemplate from './src/templates/author'

// Activates Google Optimize experiments
const activateOptimize = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'optimize.activate' })
}

const hasServiceWorker = async () => {
  const registrations = navigator.serviceWorker && (await navigator.serviceWorker.getRegistrations())
  if (registrations) {
    const serviceWorker = registrations.find(sw => sw.scope.includes('audioeye.com'))
    if (typeof serviceWorker !== 'undefined') {
      await serviceWorker.unregister()
      return true
    }
    return false
  }
  return false
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'audioeye-web',
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          post: PostTemplate,
          author: AuthorTemplate,
        }),
      },
    ]}
  >
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </PrismicPreviewProvider>
)

export const onRouteUpdate = async ({ prevLocation }) => {
  activateOptimize()

  // Tell Drift that the page has changed
  window.drift.on('ready', () => {
    window.drift.page()
  })

  const pageTrackEvents = () => {
    // For Segment global page event - wait until analytics script is loaded
    window.analytics.ready(() => {
      const user = window.analytics.user()
      const anonymousID = user.anonymousId()

      trackEvent(`Page Viewed`, {
        path: window.location.pathname,
        referrer: document.referrer || (prevLocation && prevLocation.pathname) || '',
        search: window.location.search,
        title: document.title,
        url: window.location.href,
        eng: {
          service_worker_detected: serviceWorkerFound,
        },
      })
      window.analytics.page({
        segment_anonymousID: anonymousID,
        referrer: document.referrer || (prevLocation && prevLocation.pathname) || '',
      })
    })
  }
  const serviceWorkerFound = await hasServiceWorker()

  if (prevLocation) {
    pageTrackEvents()
  }
  if (serviceWorkerFound) window.location.reload()

  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]
}

export const onClientEntry = () => {
  localStorage.setItem('ae_analytics_disabled', false)

  // Ensure segment doesn't fire if bot query params are present
  if (window.location.search.includes('ae_analytics_enabled=0')) {
    localStorage.setItem('ae_analytics_disabled', true)
  }
  if (window.location.search.includes('ae_analytics_enabled=1')) {
    localStorage.setItem('ae_analytics_disabled', false)
  }
  const analyticsDisabled = localStorage.getItem('ae_analytics_disabled')
  if (analyticsDisabled === 'false') {
    window.analytics.load(process.env.GATSBY_SEGMENT_KEY)
  }

  // Manually load control test variant using cookie
  const variantReloaded = window.sessionStorage.getItem('variant_reloaded')
  if (window.location.search.includes('test_variant=control') && !variantReloaded) {
    window.sessionStorage.setItem('variant_reloaded', true)
    document.cookie = 'nf_ab=.01'
    window.location.reload(true)
  }

  const params = new URLSearchParams(typeof window !== 'undefined' && window.location.search)

  const redirected = window.sessionStorage.getItem('redirected')
  const utmSourceParam = params.get('utm_source')
  if (utmSourceParam?.includes('intent') && !redirected) {
    window.location.pathname = '/get-started'
    window.sessionStorage.setItem('redirected', true)
  }

  const utmParams = {
    utm_campaign: params.get('utm_campaign') || '',
    utm_content: params.get('utm_content') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_source: params.get('utm_source') || '',
    utm_term: params.get('utm_term') || '',
    utm_placement: params.get('utm_placement') || '',
  }
  sessionStorage.setItem('utm_params', JSON.stringify(utmParams))
}

export const onInitialClientRender = async () => {
  const serviceWorkerFound = await hasServiceWorker()

  window.analytics.ready(() => {
    const user = window.analytics.user()
    const anonymousID = user.anonymousId()

    window.analytics.page({
      segment_anonymousID: anonymousID,
      referrer: document && document.referrer,
    })

    trackEvent(`Page Viewed`, {
      path: window.location.pathname,
      referrer: document.referrer || '',
      search: window.location.search,
      title: document.title,
      url: window.location.href,
      eng: {
        service_worker_detected: serviceWorkerFound,
      },
    })
  })

  // fix h1 issues
  highlander()
  alternator()
}
