import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import getImage from '../../utils/getImage'
import GatsbyLink from '../../utils/GatsbyLink'

import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'

import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'

const Wrapper = styled.div`
  padding: 64px 0px;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '16px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '16px')};

  @media (max-width: 1050px) {
    width: 100%;
    padding: 32px 0px;
  }
`

export const ImageEl = styled.img``

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapper = styled.div`
  h1,
  h2,
  h3,
  h4 {
    margin-top: 2.5rem;
    margin-bottom: -1rem;
  }
  p {
    margin-top: 1.75rem;
  }
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-block-start: 8px;
    margin-block-end: 8px;
    margin-top: 1.75rem;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});
  }

  @media (max-width: 800px) {
    li {
      margin-bottom: 16px;
    }
  }
`

const ContentBlock = ({ primary }) => {
  const imageSrc = idx(primary, _ => _.image.url)
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const title = <RichText render={primary.heading.richText} serializeHyperlink={GatsbyLink} />
  const checkMarkList = <RichText render={primary.checkmark_list.richText} serializeHyperlink={GatsbyLink} />
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)

  return (
    <Wrapper hasTopPadding={hasTopPadding} hasBottomPadding={hasBottomPadding}>
      {imageSrc && getImage(primary.image, ImageEl)}
      {title && idx(title, _ => _.props.render[0].text) && (
        <TextWrapper>
          <Text richText>{title}</Text>
        </TextWrapper>
      )}
      {text && idx(text, _ => _.props.render[0].text) && (
        <TextWrapper>
          <Text richText>{text}</Text>
        </TextWrapper>
      )}
      {checkMarkList && idx(checkMarkList, _ => _.props.render[0].text) && (
        <>
          <CheckMarkListWrapper richText>{checkMarkList}</CheckMarkListWrapper>
          <Space height={24} />
        </>
      )}
      {buttonUrl && (
        <ButtonWrapper>
          <Button text={buttonText} to={buttonUrl} />
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

export default ContentBlock

export const query = graphql`
  fragment PostContentBlock on PrismicPostDataBodyContentBlock {
    ...SliceBase
    primary {
      has_top_padding
      has_bottom_padding
      image {
        alt
        url
        gatsbyImageData(width: 750)
      }
      heading {
        ...TextContent
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      button_text {
        ...TextContent
      }
      button_link {
        ...LinkContent
      }
    }
  }
`
