/* eslint-disable indent */
/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { useEffect, useState, Component } from 'react'
import PropTypes from 'prop-types'
import { Global, css, ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import idx from 'idx'

import '@reach/skip-nav/styles.css'

import Footer from './Footer'
import Header from './header/Header'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import { reset, theme } from '../styles'
import highlander from '../utils/highlander'
import OnboardingModal from './form/Onboarding/OnboardingModal'

const globalStyle = css`
  ${reset}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.color.offBlack};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.25rem;
    font-family: 'GT-America-Light', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    line-height: ${theme.fonts.lineHeight.body};
    /* Fix overflow issue on safari */
    overflow-x: hidden;

    &.no-scroll {
      height: 100vh;
      overflow-y: hidden;
      padding-right: var(--scrollbar-width);
    }
  }
  body {
    color: ${theme.color.offBlack};
    background-color: ${theme.color.white};
    padding: 0px 150px !important;
    overflow-x: hidden;

    @media (max-width: 1050px) {
      padding: 0px 24px !important;
    }
  }
  ::selection {
    color: ${theme.color.white};
    background-color: ${theme.color.offBlack};
  }
  a,
  input,
  button,
  textarea {
    border: none;
  }
  button {
    cursor: pointer;
    position: relative;
  }
  p a,
  a span,
  a span p,
  p a strong,
  strong a,
  li:not(.link-list-item) a:not(.button),
  .menu-button,
  .dark-bg-link {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: ${theme.color.purple500};
    background-image: linear-gradient(to bottom, ${theme.color.purple500} 0%, ${theme.color.purple500} 100%);
    background-position: 0% 110%;
    background-repeat: repeat-x;
    background-size: 0px 0px;
    transition: color 150ms, background-size 150ms;
    padding: 0px 3px;
    margin: 0px -3px;
    position: relative;

    &.dark-bg-link {
      background-image: linear-gradient(to bottom, ${theme.color.white} 0%, ${theme.color.white} 100%);
      text-decoration: none;
      color: ${theme.color.white};
    }

    &:hover {
      text-decoration: none;
      color: ${theme.color.white};
      background-size: 2px 100%;
      z-index: 1;
      &.dark-bg-link {
        color: ${theme.color.purple500}!important;
      }
    }
    @media (max-width: 800px) {
      &:hover {
        text-decoration: none;
        background-size: 0px 0px;
        color: ${theme.color.purple500}!important;
        &.dark-bg-link {
          text-decoration: none;
          color: ${theme.color.white}!important;
        }
      }
    }
  }

  a:focus,
  input:focus,
  select:focus,
  button:focus,
  textarea:focus,
  .focus-outline:focus {
    border-radius: 8px;
    outline-offset: 4px;
    outline: ${theme.focusOutline};

    &.dark-bg-focus,
    &.dark-bg-link {
      outline: ${theme.darkBgFocusOutline}!important;
    }
  }
  a:focus {
    border-radius: 4px;
  }
  a,
  .external-link-wrapper {
    text-decoration: none;
    position: relative;
    &:focus,
    &:hover,
    &:active {
      .external-link {
        top: 1.5em;
        left: 1em;
        width: auto;
        height: auto;
        min-width: 150px;
        color: ${theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${theme.color.gray400};
        font-size: 14px !important;
        text-decoration: none;
        background-image: none;
        background-color: ${theme.color.white};
        overflow: visible;

        &.over-graphic {
          top: 6.5em;
        }

        &.short-over-graphic {
          top: 4em;
        }

        &.external-button {
          top: 4em;
          left: 0em;
        }
        &:hover {
          background-color: ${theme.color.white};
        }
      }
    }
  }
  a.no-focus {
    border: none !important;
    outline: none;
  }
  [data-reach-skip-link] {
    opacity: 0;
    z-index: -999;
  }
  [data-reach-skip-link]:focus {
    opacity: 1;
    color: ${theme.color.purple500};
    z-index: 100;
  }
  li {
    margin-bottom: 8px;
  }
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  .overflow-hidden {
    overflow: hidden !important;
    body {
      padding-right: 165px !important;
      @media (max-width: 800px) {
        padding-right: 10px !important;
      }
    }
  }
  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  /* Global font defaults */
  h1 {
    font-size: ${theme.fonts.size.heading1}!important;
    font-family: ${theme.fonts.family.heading1}!important;
    line-height: ${theme.fonts.lineHeight.heading1}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading1}!important;
  }
  h2 {
    font-size: ${theme.fonts.size.heading2}!important;
    font-family: ${theme.fonts.family.heading2}!important;
    line-height: ${theme.fonts.lineHeight.heading2}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading2}!important;
  }
  h3 {
    font-size: ${theme.fonts.size.heading3}!important;
    font-family: ${theme.fonts.family.heading3}!important;
    line-height: ${theme.fonts.lineHeight.heading3}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading3}!important;
  }
  h4 {
    font-size: ${theme.fonts.size.heading4}!important;
    font-family: ${theme.fonts.family.heading4}!important;
    line-height: ${theme.fonts.lineHeight.heading4}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading4}!important;
  }
  p,
  li,
  span {
    font-size: ${theme.fonts.size.body};
    font-family: ${theme.fonts.family.body};
    line-height: ${theme.fonts.lineHeight.body};
    letter-spacing: ${theme.fonts.letterSpacing.body};
  }
  button {
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.bodyHeavy};
    letter-spacing: ${theme.fonts.letterSpacing.body};
  }
  pre {
    font-family: ${theme.fonts.family.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
  }

  @media (max-width: 800px) {
    html {
      font-size: 1.125rem;
    }
  }
`

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1160px;
`

const PureLayout = ({
  children,
  customSEO,
  homePage,
  showHeader,
  showFooter,
  hideHeaderFooterNav,
  showCTA,
  homePageLink,
  secondaryCTAText,
  secondaryCTALink,
  modalOpen,
}) => {
  const windowGlobal = typeof window !== `undefined` && window
  const modalShouldOpen = windowGlobal && window.location.hash.includes('get-started-free')
  const [open, setOpen] = useState(modalShouldOpen)

  useEffect(() => {
    setOpen(modalShouldOpen)
  }, [modalShouldOpen])

  useEffect(() => {
    const els = document.getElementsByClassName('skip')
    if (els && els[1]) {
      const isSibling = els[0].nextElementSibling === els[1]

      const el = els[isSibling ? 1 : 0]
      if (el.nextElementSibling) {
        const sliceBG = el.nextElementSibling.querySelector('.bg')

        if (sliceBG) {
          sliceBG.style.boxShadow = `inset 0 20px 20px -20px #f1f1f1`
          return
        }

        el.nextElementSibling.style.boxShadow = `inset 0 20px 20px -20px #f1f1f1`
      }
    } else if (els && els[0]) {
      els[0].nextElementSibling === els[1]

      const el = els[els.length - 1]
      if (!el.nextElementSibling) {
        return
      }
      const sliceBG = el.nextElementSibling.querySelector('.bg')
      if (sliceBG) {
        sliceBG.style.boxShadow = `inset 0 20px 20px -20px #f1f1f1`
        return
      }
      el.nextElementSibling.style.boxShadow = `inset 0 20px 20px -20px #f1f1f1`
    }
    highlander()
  }, [])

  useEffect(() => {
    document.querySelectorAll('span.external-link').forEach(toolTip => {
      toolTip.parentElement.addEventListener('keydown', e => {
        if (e.key === 'Escape') toolTip.parentElement.blur()
      })
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const scroller = document?.scrollingElement
      requestAnimationFrame(() => {
        scroller?.style.setProperty('--scrollbar-width', `${window.innerWidth - scroller.clientWidth}px`)
      })
    }, 0)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div id="at_message" aria-live="polite" />
      <SkipNavLink />
      <OnboardingModal open={open} setOpen={setOpen} />
      {showHeader !== false ? (
        <Header
          homePage={homePage}
          hideHeaderFooterNav={hideHeaderFooterNav}
          showCTA={showCTA}
          secondaryCTAText={idx(secondaryCTAText, _ => _.text)}
          secondaryCTALink={idx(secondaryCTALink, _ => _.url) || idx(secondaryCTALink, _ => _.uid)}
          homePageLink={homePageLink}
          modalOpen={modalOpen}
        />
      ) : null}
      <Global styles={globalStyle} />
      {!customSEO && <SEO />}
      <Wrapper id="content" role="main">
        {children}
      </Wrapper>
      {showFooter !== false ? <Footer hideHeaderFooterNav={hideHeaderFooterNav} /> : null}
    </ThemeProvider>
  )
}

class Layout extends Component {
  render() {
    return (
      <PureLayout
        showHeader={this.props.showHeader}
        showFooter={this.props.showFooter}
        hideHeaderFooterNav={this.props.hideHeaderFooterNav}
        homePage={this.props.homePage}
        showCTA={this.props.showCTA}
        secondaryCTAText={this.props.secondaryCTAText}
        secondaryCTALink={this.props.secondaryCTALink}
        homePageLink={this.props.homePageLink}
        modalOpen={this.props.modalOpen}
      >
        {this.props.children}
      </PureLayout>
    )
  }
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool,
  homePage: PropTypes.bool,
  hideHeaderFooterNav: PropTypes.bool,
  showCTA: PropTypes.bool,
  homePageLink: PropTypes.bool,
  modalOpen: PropTypes.bool,
}

PureLayout.defaultProps = {
  customSEO: false,
  homePage: false,
  hideHeaderFooterNav: false,
  showCTA: false,
  homePageLink: false,
  modalOpen: false,
}
