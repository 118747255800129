/* eslint-disable indent */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-fragments */
import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { theme } from '../../styles'
import companyLogo from '../../../static/logos/company-logo-violet.svg'
import Space from '../common/Space'
import Button from '../common/Button'
import MobileMenu from './MobileMenu'
import { Menu, HeaderTextLink } from './common'

const Wrapper = styled.div`
  position: relative;
  height: 80px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-left: -150px;
  padding: 0px 71px;
  z-index: 5;
  border-top: 5px solid ${theme.color.green500};
  pointer-events: auto;

  ${p =>
    p.isAnyMenuOpen &&
    `
    pointer-events: none;
  `}

  @media (max-width: 1312px) {
    padding: 0px 30px;
  }

  @media (max-width: 1200px) {
    height: ${p => (p.hideHeaderFooterNav ? 'auto' : '80px')};
    margin: 0px;
    width: 100vw;
    margin-left: -150px;
    padding: 0px 11px;

    ${p =>
      p.isAnyMenuOpen &&
      `
        pointer-events: auto;
    `}
  }
  @media (max-width: 1050px) {
    padding: 0px 24px;
    margin-left: -24px;
  }
  @media (max-width: 800px) {
    .button {
      width: auto;
    }
  }

  .header-text-link a {
    text-decoration: none;
    display: flex;

    span {
      white-space: nowrap;
      padding: 2px 3px;
    }

    .external-link {
      color: ${theme.color.offBlack}!important;
    }

    &:hover {
      span {
        color: ${theme.color.white};
      }
    }
    &:focus,
    &:hover,
    &:active {
      .external-link {
        top: 2.3em;
        left: 0em;
      }
    }
  }
`

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`

const AudioEyeLogo = styled.img`
  width: 132px;
`

const Header = ({
  homePage = false,
  hideHeaderFooterNav,
  showCTA,
  homePageLink,
  secondaryCTAText,
  secondaryCTALink,
  modalOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnyMenuOpen, setIsAnyMenuOpen] = useState(false)
  const path = typeof window !== 'undefined' ? window.location.href : ''

  const data = useStaticQuery(graphql`
    query Header {
      prismicHeaderNavigation {
        data {
          header_links {
            top_level_title
            url {
              link_type
              uid
              url
            }
            fallback_url
            submenu
            category
            show_on_mobile
            icon {
              alt
              url
              gatsbyImageData(width: 40)
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper role="banner" homePage={homePage} hideHeaderFooterNav={hideHeaderFooterNav} isAnyMenuOpen={isAnyMenuOpen}>
      <>
        {/* Desktop */}
        <Left>
          {(!hideHeaderFooterNav || homePageLink) && (
            <Link to="/" tabIndex={modalOpen ? '-1' : '0'}>
              <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
            </Link>
          )}
          {hideHeaderFooterNav && !homePageLink && (
            <Link to={path} tabIndex={modalOpen ? '-1' : '0'}>
              <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
            </Link>
          )}
          <Space width={45} height={40} />

          {!hideHeaderFooterNav && (
            <Nav role="navigation" aria-label="main menu">
              <Menu
                headerLinks={data.prismicHeaderNavigation.data.header_links}
                mobile={false}
                isAnyMenuOpen={isAnyMenuOpen}
                setIsAnyMenuOpen={setIsAnyMenuOpen}
              />
            </Nav>
          )}
        </Left>
        <Right>
          {!hideHeaderFooterNav && (
            <>
              <HeaderTextLink
                text="Login"
                to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
                tabIndex={modalOpen ? '-1' : '0'}
              />
              <Space width={24} />
            </>
          )}
          {secondaryCTAText && secondaryCTALink && (
            <>
              <Button
                text={secondaryCTAText}
                to={secondaryCTALink}
                size="small"
                buttonType="secondary"
                tabIndex={modalOpen ? '-1' : '0'}
              />
              <Space width={16} tabletWidth={0} />
            </>
          )}
          {showCTA && (
            <Button
              text="Start Free Trial"
              aria-label="Start Free Trial"
              to="#get-started-free"
              size="small"
              tabIndex={modalOpen ? '-1' : '0'}
            />
          )}
        </Right>

        {/* Mobile */}
        <MobileMenu
          path={path}
          showCTA={showCTA}
          hideHeaderFooterNav={hideHeaderFooterNav}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          homePageLink={homePageLink}
          secondaryCTAText={secondaryCTAText}
          secondaryCTALink={secondaryCTALink}
          headerLinks={data.prismicHeaderNavigation.data.header_links}
          isAnyMenuOpen={isAnyMenuOpen}
          setIsAnyMenuOpen={setIsAnyMenuOpen}
        />
      </>
    </Wrapper>
  )
}

export default Header
