import React from 'react'
import styled from '@emotion/styled'
import BlackCrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import chevronIconOffBlack from '../../../static/icons/arrow-icons/chevron-icon-offBlack.svg'
import { theme } from '../../styles'
import chevronIcon from '../../../static/icons/arrow-icons/chevron-icon-gray300.svg'
import chevronIconHover from '../../../static/icons/arrow-icons/chevron-icon-hover.svg'

export const FormHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const FormInner = styled.div`
  width: 100%;
  padding: 40px 48px;
  min-height: 100%;

  &:focus {
    outline: none;
  }

  @media (max-width: 800px) {
    padding: 48px 24px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  position: relative;
`

export const CheckboxLabel = styled.label`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  padding: 8px 16px 8px 0px;
  cursor: pointer;

  :hover {
    div {
      border-color: ${theme.color.purple500};
    }
  }
`

export const CheckboxControl = styled.div`
  background: ${theme.color.white};
  border-radius: 6px;
  border: 1px solid ${theme.color.gray300};
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  margin-right: 16px;
  margin-top: 1px;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  ${p =>
    p.selected &&
    `
    background-color: ${theme.color.purple500};

    &:after {
      display: block;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 11px;
      border: solid ${theme.color.white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    `}
`

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  padding: 0;
  margin: 0;
  width: 0;
  z-index: -99999;

  :focus {
    border: none;
    outline: none;
  }

  &:focus + ${CheckboxLabel} {
    ${CheckboxControl} {
      span.focused-border {
        width: auto;
        height: auto;
        border: 3px solid ${theme.color.purple500};
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .button {
    width: 100%;
  }
`

export const NavButton = styled.div`
  position: absolute;
  top: 16px;
  display: inline-flex;
  align-items: center;
  font-size: ${theme.fonts.size.smallCaps};
  border: 3px solid transparent;
  z-index: 1;
  border-radius: 50%;

  &.left {
    left: 12px;

    > img {
      width: 14px;
      height: 16px;
    }
  }

  &.right {
    right: 16px;
  }
`

export const CustomSelect = styled.select`
  width: 100%;
  background-color: ${theme.color.white};
  font-size: ${theme.fonts.size.smallBody};
  font-family: ${theme.fonts.family.smallBody};
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.color.gray300};
  color: ${theme.color.offBlack};
  cursor: pointer;
  transition: all 50ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${chevronIcon});
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 97%;
  background-position-y: 50%;

  ::placeholder {
    color: ${theme.color.gray700};
    font-weight: 300;
  }

  &:hover {
    background-image: url(${chevronIconHover});
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.color.gray300}!important;

    + span.focused-border {
      width: auto;
      height: auto;
      border: 3px solid ${theme.color.purple500};
    }
  }

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

export const FocusedBorder = styled.span`
  content: '';
  position: absolute;
  top: -6px;
  bottom: -6px;
  left: -6px;
  right: -6px;
  border-radius: 14px;
  border: 3px solid transparent;
  width: 0px;
  height: 0px;

  &.reduced-radius {
    border-radius: 10px;
  }
`

export const CloseButton = ({ closeModal }) => (
  <NavButton
    className="right focus-outline"
    tabIndex="0"
    aria-label="close"
    role="button"
    onClick={closeModal}
    onKeyDown={e => {
      if (e.key === 'Enter') closeModal()
    }}
  >
    <img alt="" src={BlackCrossIcon} style={{ width: '15px', height: '15px' }} />
  </NavButton>
)

export const BackButton = ({ setStageOne }) => (
  <NavButton
    tabIndex="0"
    className="left focus-outline"
    onClick={setStageOne}
    role="button"
    aria-label="back"
    onKeyDown={e => {
      if (e.key === 'Enter') setStageOne()
    }}
  >
    <img alt="" src={chevronIconOffBlack} style={{ transform: 'rotate(90deg)' }} />
  </NavButton>
)
