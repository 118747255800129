import React from 'react'
import styled from '@emotion/styled'
import QMSecFilings from './QMSecFilings'
import Text from '../common/Text'

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  iframe {
    min-height: 500px;
  }
`

const Chart = () => (
  <Wrapper>
    <Text id="sec-filings-title" heading3 aria-level="2">
      SEC Filings
    </Text>
    <QMSecFilings />
  </Wrapper>
)

export default Chart
