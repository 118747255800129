import React, { useState } from 'react'
import styled from '@emotion/styled'
import PageBlock from './PageBlock'

const Wrapper = styled.div`
  position: relative;
  @media (max-width: 1050px) {
    min-width: 0px;
    width: 100%;
  }
`

const BottomWrapper = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const Carousel = ({ renderItem, items, limit = 5, ariaLabel, darkBG }) => {
  const slides = items.slice(0, limit)
  const [slide, setSlide] = useState(1)
  const item = slides[slide - 1]

  return (
    <Wrapper>
      {item && renderItem(item)}
      {items.length > 1 && (
        <BottomWrapper aria-label={ariaLabel || 'Pagination'} role="navigation">
          <PageBlock
            page="previous"
            ariaLabel="previous page"
            onClick={() => slide > 1 && setSlide(slide - 1)}
            disabled={slide === 1}
            darkBG={darkBG}
          />
          {slides.map((_, i) => (
            <React.Fragment key={`carousel-block-${i}`}>
              <PageBlock
                page={i + 1}
                ariaLabel={`page ${i + 1}${i + 1 === slide ? ' selected' : ''}`}
                isSelected={i + 1 === slide}
                onClick={() => setSlide(i + 1)}
                darkBG={darkBG}
              />
            </React.Fragment>
          ))}
          <PageBlock
            page="next"
            ariaLabel="next page"
            onClick={() => slide < slides.length && setSlide(slide + 1)}
            disabled={slides && slides.length && slide === slides.length}
            darkBG={darkBG}
          />
        </BottomWrapper>
      )}
    </Wrapper>
  )
}

export default Carousel
