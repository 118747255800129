/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import Input from './Input'
import Button from '../common/Button'
import { theme } from '../../styles'
import Text from '../common/Text'
import useUrlParam from '../../utils/useUrlParam'

const Form = styled.form`
  display: flex;
  width: 100%;

  label {
    margin-bottom: 0px;
  }

  .button {
    border-radius: 0px 8px 8px 0px;
    align-self: flex-end;
    padding: 17px 32px;
    height: auto;
    margin-left: 0px;

    @media (max-width: 1050px) {
      border-radius: 8px;
      width: 100%;
      margin-top: 20px;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    label {
      width: 100%;
    }
  }
`

const ScannerInput = styled(Input)`
  border-radius: 8px 0px 0px 8px;
  padding: 15px;
  border-width: 2px;
  border-color: ${theme.color.purple500};

  &:focus {
    border-width: 2px;
    border-color: ${theme.color.purple500}!important;
  }

  @media (max-width: 1050px) {
    border-radius: 8px;
    width: 100%;
    padding: 14px;
  }
`

const Scanner = ({ partner = false, darkBG, labelText, secondaryScan }) => {
  const windowGlobal = typeof window !== `undefined` && window

  const [inputURL, setInputURL] = useState('')
  const [validationError, setValidationError] = useState('')
  const [gateForm] = useState(windowGlobal ? useUrlParam(window.location.search, 'gate') : false)

  useEffect(() => {
    const showForm = windowGlobal && window.sessionStorage.getItem('gate')
    if (gateForm === 'false' || showForm === 'false') {
      window.sessionStorage.setItem('gate', false)
    } else {
      window.sessionStorage.setItem('gate', true)
    }
  }, [])

  function handler(e) {
    e.preventDefault()
    if (windowGlobal) {
      if (inputURL.match(/^((ftp|http|https):\/\/)?([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/i)) {
        window.sessionStorage.setItem('scan_url', inputURL)
        secondaryScan
          ? navigate(`/scan/secondary-scan/?url=${inputURL}`)
          : navigate(encodeURI(`/scan/results/?url=${inputURL}&&partner=${partner}`))
      } else setValidationError('Please use a valid website address.')
    }
  }

  return (
    <>
      <Form onSubmit={handler}>
        <ScannerInput
          label={labelText || 'Enter Website URL'}
          value={inputURL}
          onChange={e => setInputURL(e.target.value)}
          placeholder="www.yourwebsite.com"
          name="website_url"
          type="text"
          scanner
          required
        />
        <Button text="Free Scan" type="submit" noScroll buttonType="primary" />
      </Form>
      {validationError && (
        <Text tinyBody color={darkBG ? 'orange500' : 'red500'} role="alert" aria-live="polite" small>
          {validationError}
        </Text>
      )}
    </>
  )
}

export default Scanner
