/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from '@emotion/styled'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import { trackEvent } from '../utils/segmentTrack'

import Text from '../components/common/Text'
import Space from '../components/common/Space'
import BackButton from '../components/common/BackButton'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { renderPostSlices } from '../utils/renderPostSlices'

import AuthorSection from '../components/postSlices/AuthorSection'
import SharePost from '../components/postSlices/SharePost'

const Wrapper = styled.div`
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1050px) {
    margin: 0px;
    width: 100%;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 1em;
`

const PostWrapper = styled.div``

const SharePostWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const PostPage = ({ location, data }) => {
  if (!(data && data.prismicPost && data.prismicPost.data)) return null

  const [password, setPassword] = useState()
  const [authenticated, setAuthenticated] = useState(false)

  const postPassword = data.prismicPost.data.password

  useEffect(() => {
    if (!authenticated && postPassword && postPassword.length) {
      setAuthenticated(false)
      // eslint-disable-next-line no-alert
      const passwordValue = typeof window !== 'undefined' ? window.prompt('Password required') : undefined
      setPassword(passwordValue)
      if (passwordValue === postPassword) {
        setAuthenticated(true)
      }
    } else {
      setAuthenticated(true)
    }
  }, [authenticated, password])

  const mappedPost = data.prismicPost.data

  const [author, setAuthor] = useState({
    first_name: { text: 'AudioEye' },
    last_name: { text: '' },
    profile_image: {
      url: 'https://audioeye-web.cdn.prismic.io/audioeye-web/0c3d9cf6-5954-421a-a2fb-b73616290d10_audioeye-author-photo.svg',
    },
    description: [{ text: '' }],
    uid: 'audioeye',
    id: null,
    url: null,
  })

  useEffect(() => {
    if (idx(mappedPost, _ => _.author.document.data.first_name)) {
      setAuthor({
        first_name: mappedPost.author.document.data.first_name,
        last_name: mappedPost.author.document.data.last_name,
        profile_image: mappedPost.author.document.data.profile_image,
        description: mappedPost.author.document.data.description.text,
        uid: mappedPost.author.document.uid,
        id: mappedPost.author.document.id,
      })
    } else if (idx(mappedPost, _ => _.author.url)) {
      setAuthor({
        first_name: { text: idx(mappedPost, _ => _.guest_author_name.text) || 'Guest Author' },
        last_name: { text: '' },
        profile_image: {
          url: 'https://images.prismic.io/audioeye-web/a113e748-692f-4650-86b5-1e65a4495653_author-icon.png?auto=compress,format',
        },
        description: [{ text: '' }],
        url: mappedPost.author.url,
        target: mappedPost.author.target,
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      trackEvent(`Blog Post Viewed`, {
        blog_post_url: window.location.href,
        blog_post_title: mappedPost.title.text,
        blog_post_author: `${author.first_name.text} ${author.last_name.text}`,
      })
    }
  }, [])

  const title = <RichText render={mappedPost.title.richText} />

  if (authenticated || (postPassword && !postPassword.length)) {
    return (
      <Layout showHeader customSEO showCTA>
        <SEO
          bannerUrl={mappedPost && mappedPost.banner && mappedPost.banner.url}
          title={idx(mappedPost, _ => _.title.text)}
          pathname={location.pathname}
          desc={idx(mappedPost, _ => _.description)}
          node={mappedPost}
        />
        <Wrapper>
          <TitleWrapper>
            <Space height={24} tabletHeight={0} />
            <BackButton
              text={`Back to ${mappedPost.post_type === 'Press' ? 'Press' : 'Blog'}`}
              to={`/${mappedPost.post_type === 'Press' ? 'press' : 'blog'}`}
            />
            <Space height={24} />
            {mappedPost.title && (
              <Text font="heading1" richText numberOfLines={4}>
                {title}
              </Text>
            )}
          </TitleWrapper>
          <PostWrapper>
            <AuthorSection
              uid={idx(author, _ => _.uid)}
              id={idx(author, _ => _.id)}
              publishDate={mappedPost.publish_date}
              image={author.profile_image}
              name={`${author.first_name.text} ${author.last_name.text}`}
              inPost
              url={idx(author, _ => _.url)}
              target={idx(author, _ => _.target)}
            />
            {renderPostSlices(mappedPost.body)}
            <SharePostWrapper>
              <SharePost />
            </SharePostWrapper>
            <Space height={31} />
          </PostWrapper>
        </Wrapper>
        <Space height={64} />
      </Layout>
    )
  }
  return (
    <SEO
      bannerUrl={mappedPost && mappedPost.banner && mappedPost.banner.url}
      title={idx(mappedPost, _ => _.title.text)}
      pathname={location.pathname}
      desc={idx(mappedPost, _ => _.description)}
      node={mappedPost}
    />
  )
}

export default withPrismicPreview(PostPage)

export const query = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        post_type
        password
        banner {
          alt
          url
        }
        title {
          ...TextContent
        }
        description
        publish_date
        guest_author_name {
          ...TextContent
        }
        author {
          link_type
          url
          document {
            ...PostAuthor
          }
        }
        body {
          ...PostDataBody
        }
      }
    }
  }
`
