/* eslint-disable prettier/prettier */
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

const DefaultImage = styled.img``

export default function getImageReactDom(image, ImageComponent = DefaultImage, properties = {}) {
  const {key, loading, alt, noAlt} = properties
  if (!image || !image.url) return null

  return image && (image.url.includes('.png') || image.url.includes('.jpg') || !image.url.includes('.svg')) ? (
    <ImageComponent
      as={GatsbyImage}
      image={getImage(image)}
      alt={noAlt ? '' : alt || image.alt || ''}
      key={key} loading={loading}
    />
  ) : (
    <ImageComponent src={image.url} alt={alt || image.alt || ''} key={key} loading={loading} />
  )
}
