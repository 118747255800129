import React from 'react'
import Text from '../../common/Text'
import Space from '../../common/Space'
import TextLink from '../../common/TextLink'
import Button from '../../common/Button'
import { StageWrapper } from './utils'
import { CloseButton, BackButton, FormHeaderWrapper, FormInner, ButtonWrapper } from '../common'

import LoadingGraphic from '../../../../static/get-started-free/icon-rotate.svg'

const CheckSite = ({ stage, closeModal, setStageOne, formData, partnerName }) => (
  <>
    <StageWrapper open={stage === 2} aria-hidden={stage === 2 ? 'false' : 'true'} checkSite>
      {stage === 2 && (
        <>
          <FormHeaderWrapper>
            <BackButton setStageOne={setStageOne} />
            <CloseButton closeModal={closeModal} />
          </FormHeaderWrapper>
          <FormInner>
            <Text heading1 color="purple500">
              Welcome to AudioEye!
            </Text>
            <Space height={16} />
            <Text heading3>We're getting everything set up for you to get started.</Text>
            <Space height={24} />
            <div>
              <img src={LoadingGraphic} alt="" />
            </div>
            <Space height={24} />
            <Text bodyHeavy aria-live="polite">
              You'll be redirected momentarily.
            </Text>
          </FormInner>
        </>
      )}
    </StageWrapper>
    <StageWrapper open={stage === 'partner'} aria-hidden={stage === 'partner' ? 'false' : 'true'} checkSite>
      {stage === 'partner' && (
        <>
          <FormHeaderWrapper>
            <BackButton setStageOne={setStageOne} />
            <CloseButton closeModal={closeModal} />
          </FormHeaderWrapper>
          <FormInner>
            <Text heading3>This website is already registered with AudioEye: </Text>
            <Space height={16} />
            <Text heading3 color="purple500">
              {formData.url}
            </Text>
            <Space height={16} />
            <Text heading3>
              If you are the owner, please{' '}
              <TextLink
                text="contact us"
                to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
                color="purple500"
                font="heading3"
              />
              .
            </Text>
          </FormInner>
        </>
      )}
    </StageWrapper>
    <StageWrapper open={stage === 'cms'} aria-hidden={stage === 'cms' ? 'false' : 'true'} checkSite>
      {stage === 'cms' && (
        <>
          <FormHeaderWrapper>
            <BackButton setStageOne={setStageOne} />
            <CloseButton closeModal={closeModal} />
          </FormHeaderWrapper>
          <FormInner>
            <Text heading3>This website is hosted by one of our partners:</Text>
            <Space height={16} />
            <Text heading3 color="purple500">
              {formData.url}
            </Text>
            <Space height={16} />
            <Text heading3>
              Please contact {partnerName} to register this site or{' '}
              <TextLink
                text="contact us"
                to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
                color="purple500"
                font="heading3"
              />{' '}
              for more information.
            </Text>
          </FormInner>
        </>
      )}
    </StageWrapper>
    <StageWrapper open={stage === 'exists'} aria-hidden={stage === 'exists' ? 'false' : 'true'} checkSite>
      {stage === 'exists' && (
        <>
          <FormHeaderWrapper>
            <BackButton setStageOne={setStageOne} />
            <CloseButton closeModal={closeModal} />
          </FormHeaderWrapper>
          <FormInner>
            <Text heading3>This account already exists.</Text>
            <Space height={16} />
            <Text smallBodyHeavy>Please login to your AudioEye Portal to add additional websites.</Text>
            <Space height={24} />
            <ButtonWrapper>
              <Button
                text="AudioEye Portal"
                to={`${process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}?email=${encodeURIComponent(formData.email)}`}
              />
            </ButtonWrapper>
          </FormInner>
        </>
      )}
    </StageWrapper>
    <StageWrapper open={stage === 'error'} aria-hidden={stage === 'error' ? 'false' : 'true'} checkSite>
      {stage === 'error' && (
        <>
          <FormHeaderWrapper>
            <BackButton setStageOne={setStageOne} />
            <CloseButton closeModal={closeModal} />
          </FormHeaderWrapper>
          <FormInner>
            <Text heading3>We encountered an error while trying to create your account.</Text>
            <Space height={16} />
            <Text heading3>
              Please try again or{' '}
              <TextLink
                text="contact us"
                to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
                color="purple500"
                font="heading3"
              />{' '}
              if the problem persists.
            </Text>
          </FormInner>
        </>
      )}
    </StageWrapper>
  </>
)

export default CheckSite
