/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import formatDate from '../../../utils/formatDate'
import Text from '../../common/Text'
import generateSrcSet from '../../../utils/generateSrcSet'
import { theme } from '../../../styles'
import { OpenNewTabHiddenSpan } from '../../common/NewTabHiddenSpan'

const Card = styled.li`
  box-shadow: 0px 0px 14px 3px ${p => p.theme.color.dropShadow};
  border-width: 0px;
  border-radius: 6px;
  background: ${theme.color.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 125ms ease-in-out;
  margin-bottom: 0px;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.025);
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${p => p.theme.color.dropShadow};
      transform: none;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 130px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background: ${theme.color.purple200};
`

const Img = styled.img`
  max-height: 64px;
  object-fit: contain;
`

const CardContent = styled.div`
  padding: 24px;
  background: ${theme.color.white};
`

const DividerLine = styled.div`
  width: 32px;
  margin: 8px auto;
  height: 2px;
  background-color: ${theme.color.purple200};
`

const PressGridItem = ({ date, title, url, img }) => {
  let modifiedImg = img
  if (!img.url) {
    modifiedImg = {
      url: 'https://images.prismic.io/audioeye-web/d0bfbb1e-323b-4ba0-8a71-c7d8693bb88d_default-news-Image-purple200.png?auto=compress,format&w=400',
      alt: 'AudioEye Logo',
      dimensions: {},
    }
  }

  const src = idx(modifiedImg, _ => _.url)
  const srcSet = generateSrcSet(
    src,
    idx(modifiedImg, _ => _.dimensions),
    400
  )

  return (
    <Card
      className="link-list-item external-link-wrapper focus-outline"
      onClick={() => typeof window !== 'undefined' && window.open(url, '_blank')}
      onKeyDown={e => {
        if (e.key === 'Enter' && typeof window !== 'undefined') window.open(url, '_blank')
      }}
      tabIndex="0"
    >
      <ImageWrapper>
        <Img alt={idx(modifiedImg, _ => _.alt) || ''} src={src} loading="lazy" srcSet={srcSet} />
      </ImageWrapper>
      <CardContent>
        <a href={url} targe="_blank" onClick={e => e.stopPropagation()} className="no-focus" tabIndex="-1">
          <Text bodyHeavy numberOfLines={2} center>
            {title}
          </Text>
        </a>
        <DividerLine className="divider-line" />
        <Text tinyBody color="gray700" center>
          {date && formatDate(date, 1)}
        </Text>
      </CardContent>
      <OpenNewTabHiddenSpan text={title || ''} overGraphic />
    </Card>
  )
}

export default PressGridItem
