/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from '@emotion/styled'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'

import GatsbyLink from '../utils/GatsbyLink'
import Text from '../components/common/Text'
import Space from '../components/common/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AuthorSection from '../components/postSlices/AuthorSection'

const Wrapper = styled.div`
  position: relative;
  max-width: 760px;
`

const AuthorPage = ({ data: { prismicAuthor }, location }) => {
  if (!prismicAuthor) {
    return null
  }
  const { first_name, last_name, profile_image, title, description } = prismicAuthor.data
  const richDescription = <RichText render={description.richText} serializeHyperlink={GatsbyLink} />

  return (
    <Layout showHeader customSEO showCTA>
      <SEO
        bannerUrl={profile_image && profile_image.url}
        title={`${idx(first_name, _ => _.text)} ${idx(last_name, _ => _.text)} | AudioEye`}
        pathname={location.pathname}
        desc={description.text}
        node={prismicAuthor}
      />
      <Wrapper>
        <Space height={80} />
        <AuthorSection
          uid={idx(prismicAuthor, _ => _.uid)}
          id={idx(prismicAuthor, _ => _.id)}
          image={profile_image}
          name={`${idx(first_name, _ => _.text)} ${idx(last_name, _ => _.text)}`}
          title={idx(title, _ => _.text)}
        />
        <Space height={35} />
        <Text font="body" richText>
          {richDescription}
        </Text>
        <Space height={35} />
      </Wrapper>
    </Layout>
  )
}

export default withPrismicPreview(AuthorPage)

export const query = graphql`
  query AuthorBySlug($uid: String!) {
    prismicAuthor(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      _previewable
      uid
      id
      data {
        description {
          ...TextContent
        }
        first_name {
          ...TextContent
        }
        last_name {
          ...TextContent
        }
        profile_image {
          alt
          url
          gatsbyImageData(width: 60)
        }
        title {
          ...TextContent
        }
      }
    }
  }
`
