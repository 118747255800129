import React from 'react'
import { FocusedBorder, InputContainer, CheckboxInput, CheckboxLabel, CheckboxControl, RadioContainer } from '../common'
import Text from '../../common/Text'
import Input, { RequiredSpan } from '../Input'
import Space from '../../common/Space'
import { trackEvent } from '../../../utils/segmentTrack'

export const AgencyFormFields = ({ formData, updateFormData, numWebsitesErr, onboarding }) => (
  <>
    <Space height={4} />
    <InputContainer style={{ flexDirection: 'column' }}>
      <Text id="agency-or-freelancer" smallBodyHeavy>
        Are you an agency?
      </Text>
      <CheckboxInput
        name="agency_or_freelancer"
        type="checkbox"
        value="Yes"
        aria-labelledby="agency-or-freelancer"
        aria-describedby="agency-label"
        id="yes"
        onChange={e => {
          // eslint-disable-next-line no-unused-expressions
          formData.freelancerAgency !== e.target.value
            ? updateFormData({ freelancerAgency: e.target.value })
            : updateFormData({ freelancerAgency: '' })
          if (e.target.value === 'Yes' && formData.freelancerAgency !== e.target.value) {
            trackEvent(`Agency Input Value Changed`, {
              value: true,
              form: formData.form_name,
              page_url: (window && window.location.pathname) || '',
            })
          }
        }}
      />
      <CheckboxLabel htmlFor="yes">
        <CheckboxControl selected={formData.freelancerAgency === 'Yes'}>
          <FocusedBorder className="focused-border reduced-radius" />
        </CheckboxControl>
        <Text smallBody id="agency-label">
          Yes, I'm looking for my clients
        </Text>
      </CheckboxLabel>
    </InputContainer>
    {formData.freelancerAgency && (
      <>
        <Space height={4} />
        <InputContainer style={{ flexDirection: 'column' }}>
          <Text id="number-of-websites" smallBodyHeavy>
            How many websites do you manage? <RequiredSpan>*</RequiredSpan>
          </Text>

          <RadioContainer>
            <CheckboxInput
              name="record_type"
              aria-required="true"
              aria-invalid={numWebsitesErr}
              type="radio"
              aria-labelledby={numWebsitesErr ? 'num-error-alert' : 'number-of-websites'}
              aria-describedby="self_service_agency"
              value="self_service_agency"
              id="self_service_agency"
              onChange={e => updateFormData({ numWebsites: e.target.value })}
            />
            <CheckboxLabel htmlFor="self_service_agency">
              <CheckboxControl selected={formData.numWebsites === 'self_service_agency'}>
                <FocusedBorder className="focused-border reduced-radius" />
              </CheckboxControl>
              <Text smallBody id="self_service_agency">
                2-50
              </Text>
            </CheckboxLabel>
            <CheckboxInput
              name="record_type"
              aria-required="true"
              aria-invalid={numWebsitesErr}
              type="radio"
              aria-labelledby="number-of-websites"
              aria-describedby="small_medium_size_agency"
              value="small_medium_size_agency"
              id="small_medium_size_agency"
              onChange={e => updateFormData({ numWebsites: e.target.value })}
            />
            <CheckboxLabel htmlFor="small_medium_size_agency">
              <CheckboxControl selected={formData.numWebsites === 'small_medium_size_agency'}>
                <FocusedBorder className="focused-border reduced-radius" />
              </CheckboxControl>
              <Text smallBody id="small_medium_size_agency">
                51-1000
              </Text>
            </CheckboxLabel>
            <CheckboxInput
              name="record_type"
              aria-required="true"
              aria-invalid={numWebsitesErr}
              type="radio"
              aria-labelledby="number-of-websites"
              aria-describedby="large_agency"
              value="large_agency"
              id="large_agency"
              onChange={e => updateFormData({ numWebsites: e.target.value })}
            />
            <CheckboxLabel htmlFor="large_agency">
              <CheckboxControl selected={formData.numWebsites === 'large_agency'}>
                <FocusedBorder className="focused-border reduced-radius" />
              </CheckboxControl>
              <Text smallBody id="large_agency">
                1000+
              </Text>
            </CheckboxLabel>
            <CheckboxInput
              name="record_type"
              aria-required="true"
              aria-invalid={numWebsitesErr}
              type="radio"
              aria-labelledby="number-of-websites"
              aria-describedby="agency_size_unknown"
              value="agency_size_unknown"
              id="agency_size_unknown"
              onChange={e => updateFormData({ numWebsites: e.target.value })}
            />
            <CheckboxLabel htmlFor="agency_size_unknown">
              <CheckboxControl selected={formData.numWebsites === 'agency_size_unknown'}>
                <FocusedBorder className="focused-border reduced-radius" />
              </CheckboxControl>
              <Text smallBody id="agency_size_unknown">
                Not sure
              </Text>
            </CheckboxLabel>
          </RadioContainer>
          {numWebsitesErr && (
            <Text microBody color="red500" style={{ marginTop: '4px' }} id="num-error-alert" role="alert">
              Please select the number of websites you manage
            </Text>
          )}
        </InputContainer>
        {onboarding && (
          <>
            <Space height={4} />
            <InputContainer id="company-wrapper">
              <Input
                label="Company"
                name="company"
                required
                value={formData.company}
                onChange={e => updateFormData({ company: e.target.value })}
                id="company-input"
              />
            </InputContainer>
            <Space height={4} />
          </>
        )}
      </>
    )}
  </>
)
