/* eslint-disable indent */
/* eslint-disable react/jsx-fragments */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../../utils/GatsbyLink'
import { theme } from '../../../styles'

import Text from '../../common/Text'
import Button from '../../common/Button'
import blackCheckIcon from '../../../../static/icons/check-icons/black-check-icon.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0px 80px 0px;

  @media (max-width: 800px) {
    padding: 24px 0px 80px 0px;
  }
`

const Header = styled.div`
  max-width: 1000px;
  padding-bottom: 56px;

  @media (max-width: 800px) {
    padding-bottom: 32px;
  }
`

const CardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 1050px) {
    align-items: center;
    flex-direction: column;
  }
`

const PlanCard = styled.div`
  background-color: ${theme.color.offWhite};
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 48px;
  flex: 1;
  box-shadow: 0px 1px 12px 0px ${theme.color.dropShadow};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1050px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    padding: 32px 24px;
    padding-bottom: 40px;
    width: 100%;
  }
`

const ManagedCard = styled(PlanCard)`
  border-top: 8px solid ${theme.color.purple500};
  margin-right: 48px;

  @media (max-width: 1050px) {
    margin-right: 0px;
  }
`

const EnterpriseCard = styled(PlanCard)`
  border-top: 8px solid ${theme.color.green500};

  @media (max-width: 1050px) {
    margin-top: 16px;
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
`

const CardListWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    min-width: unset;
  }

  @media (max-width: 800px) {
    padding: 16px;
  }

  li {
    display: flex;
    margin: 8px;
    margin-bottom: 16px;
    margin-left: 0px;
    list-style-type: none;
    text-align: left;

    &:before {
      margin-right: 12px;
      margin-top: 1px;
      content: url(${blackCheckIcon});

      @media (max-width: 1050px) {
        transform: scale(0.75);
        max-height: 30px;
      }
    }

    @media (max-width: 1050px) {
      margin: 0px;
      margin-bottom: 8px;
      margin-right: 4px;
    }
  }
`

const PricingHero = ({ primary }) => {
  const title = <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  const managedTitle = primary.managed_title
  const managedSubtitle = primary.managed_subtitle
  const managedList = <RichText render={primary.managed_list.richText} serializeHyperlink={GatsbyLink} />
  const managedCTAButtonText = primary.managed_cta_button_text.text
  const enterpriseTitle = primary.enterprise_title
  const enterpriseSubtitle = primary.enterprise_subtitle
  const enterpriseList = <RichText render={primary.enterprise_list.richText} serializeHyperlink={GatsbyLink} />
  const enterpriseCTAButtonText = primary.enterprise_cta_button_text.text

  const anchorId = primary.anchor_id
  return (
    <Wrapper id={anchorId || null}>
      <Header>
        <Text richText font="heading1" center>
          {title}
        </Text>
      </Header>
      <CardWrapper>
        <ManagedCard>
          <Text heading2 center style={{ marginBottom: '16px' }}>
            {managedTitle.text}
          </Text>
          <Text center smallBody>
            {managedSubtitle.text}
          </Text>
          <Text center heading2 aria-level="3">
            $49/mo
          </Text>
          <CardListWrapper>
            <Text richText font="smallBody">
              {managedList}
            </Text>
          </CardListWrapper>
          <Button to="#get-started-free" text={managedCTAButtonText} />
        </ManagedCard>
        <EnterpriseCard>
          <Text heading3 aria-level="2" center style={{ marginBottom: '16px' }}>
            {enterpriseTitle.text}
          </Text>
          <Text smallBody center>
            {enterpriseSubtitle.text}
          </Text>
          <CardListWrapper>
            <Text smallBodyHeavy>Everything in the Base Plans, Plus:</Text>
            <Text richText font="smallBody">
              {enterpriseList}
            </Text>
          </CardListWrapper>
          <Button to="/enterprise-quote" text={enterpriseCTAButtonText} />
        </EnterpriseCard>
      </CardWrapper>
    </Wrapper>
  )
}

export default PricingHero

export const query = graphql`
  fragment PricingHero on PrismicPageDataBodyPricingHero {
    ...SliceBase
    primary {
      anchor_id
      title1 {
        ...TextContent
      }
      managed_title {
        ...TextContent
      }
      managed_subtitle {
        ...TextContent
      }
      managed_cta_button_text {
        ...TextContent
      }
      managed_list {
        ...TextContent
      }
      enterprise_title {
        ...TextContent
      }
      enterprise_subtitle {
        ...TextContent
      }
      enterprise_list {
        ...TextContent
      }
      enterprise_cta_button_text {
        ...TextContent
      }
    }
  }
`
