/* eslint-disable indent */

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  margin: 0 auto;

  video {
    max-width: 100%;
  }

  @media (max-width: 1050px) {
    max-width: 570px;
  }

  @media (max-width: 800px) {
    min-height: auto;
  }
`

const AnimatedVideo = ({ videoSrc, poster }) => {
  const [showVideo, setShowVideo] = useState(false)
  const wrapperRef = React.createRef()
  const observerOptions = {
    root: null,
    rootMargin: '100px',
    threshold: [0, 0.25, 0.5, 0.75, 1],
  }

  function randID() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(2, 10)
  }
  const uniqueId = randID()

  function updateShowVideo(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setShowVideo(true)
      } else {
        setShowVideo(false)
      }
    })
  }

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(updateShowVideo, observerOptions)
      observer.observe(wrapperRef.current)
    } else {
      setShowVideo(true)
    }
    return () => {
      setShowVideo(false)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && showVideo) {
      const video = document.querySelector(`#video-${uniqueId}`)
      video.addEventListener('ended', () => {
        video.src = poster
        video.controls = false
      })
      return () => {
        setShowVideo(false)
      }
    }
  }, [showVideo])

  return (
    <Wrapper ref={wrapperRef}>
      {showVideo && (
        <video
          id={`video-${uniqueId}`}
          src={videoSrc}
          poster={poster || ''}
          autoPlay
          muted
          playsInline
          controls
          controlsList="nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
          preload="auto"
        />
      )}
    </Wrapper>
  )
}

export default AnimatedVideo
