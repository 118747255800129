import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Player from '@vimeo/player'
import sanitizeVimeo from '../../utils/sanitizeVimeo'
import { trackEvent } from '../../utils/segmentTrack'

const Iframe = styled.iframe`
  position: absolute;
  border-width: 0px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${p =>
    p.isModal &&
    css`
      position: fixed;
      width: 50vw;
      margin-bottom: 0px;
      left: 25%;

      @media (max-width: 1050px) {
        display: grid;
        width: 85vw;
        margin: 0 auto;
        position: relative;
        top: unset;
        left: unset;
        align-items: center;
        max-width: 85vw;
      }
    `}
`

const VimeoIframe = ({ isModal, title, video, autoPlay, allowFullScreen }) => {
  const [showVideo, setShowVideo] = useState(false)

  const wrapperRef = React.createRef()
  const observerOptions = {
    root: null,
    rootMargin: '100px',
    threshold: [0, 0.25, 0.5, 0.75, 1],
  }

  function updateShowVideo(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setShowVideo(true)
      } else {
        setShowVideo(false)
      }
    })
  }

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(updateShowVideo, observerOptions)
      observer.observe(wrapperRef.current)
    } else {
      setShowVideo(true)
    }
    return () => {
      setShowVideo(false)
    }
  }, [])

  const getCurrentData = async player => {
    const fullScreen = await player.getFullscreen()
    const currentPosition = await player.getCurrentTime()
    return { fullScreen, currentPosition }
  }

  useEffect(async () => {
    if (typeof window !== 'undefined' && showVideo) {
      const iframe = document.querySelector('.vimeo-iframe')
      const player = new Player(iframe)

      const videoTitle = await player.getVideoTitle()
      const duration = await player.getDuration()
      const urlAutoPlay = /autoplay=1?/.test(iframe.src)

      if (!urlAutoPlay) {
        player.on('play', async () => {
          const { currentPosition, fullScreen } = await getCurrentData(player)
          trackEvent(`Video Played`, {
            title: videoTitle,
            position: currentPosition,
            total_length: duration,
            full_screen: fullScreen,
          })
          player.off('play')
        })

        player.on('pause', async () => {
          const { currentPosition, fullScreen } = await getCurrentData(player)
          trackEvent(`Video Paused`, {
            title: videoTitle,
            position: currentPosition,
            total_length: duration,
            full_screen: fullScreen,
          })
          player.off('pause')
        })

        player.on('timeupdate', async ({ percent }) => {
          let completed
          if (percent >= '.8') {
            completed = true
          }
          if (completed) {
            player.off('timeupdate')
            const { currentPosition, fullScreen } = await getCurrentData(player)
            trackEvent(`Video Completed`, {
              title: videoTitle,
              position: currentPosition,
              total_length: duration,
              full_screen: fullScreen,
            })
          }
        })
      }
      return () => {
        setShowVideo(false)
      }
    }
  }, [showVideo])

  return (
    <div ref={wrapperRef}>
      {showVideo && (
        <Iframe
          autoPlay={autoPlay}
          isModal={isModal}
          allowFullScreen={allowFullScreen}
          webkitAllowFullScreen={allowFullScreen}
          mozAllowFullScreen={allowFullScreen}
          title={title || ''}
          src={sanitizeVimeo(video, autoPlay)}
          className="vimeo-iframe"
        />
      )}
    </div>
  )
}
export default VimeoIframe
