import React from 'react'
import { graphql } from 'gatsby'
import idx from 'idx'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../utils/GatsbyLink'

import Text from '../common/Text'

export const Wrapper = styled.div`
  margin-bottom: 56px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;
    margin-bottom: -1rem;
  }
  p,
  li {
    font-size: ${p => p.theme.fonts.size.body};
    font-family: ${p => p.theme.fonts.family.body};
    line-height: ${p => p.theme.fonts.lineHeight.body};
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
    p {
      display: inline;
    }
  }
  p {
    margin-top: 1.75rem;
  }
`

const BodyText = ({ primary }) => {
  const { anchorId } = primary
  const title = <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />

  return (
    <Wrapper id={anchorId || null}>
      {title && idx(title, _ => _.props.render[0].text) && <Text richText>{title}</Text>}
      {text && idx(text, _ => _.props.render[0].text) && <Text richText>{text}</Text>}
    </Wrapper>
  )
}

export default BodyText

export const query = graphql`
  fragment PostBodyText on PrismicPostDataBodyText {
    ...SliceBase
    primary {
      anchor_id
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
