/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import idx from 'idx'
import { renderPageSlices } from '../utils/renderPageSlices'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

class Page extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: undefined,
    }
  }

  render() {
    const prismic = this.props.data.prismicPage.data

    const { password } = prismic
    if (password && password.text.length > 0) {
      const pagePassword = password.text
      if (pagePassword && this.state.password !== pagePassword) {
        // eslint-disable-next-line no-alert
        const passwordValue = typeof window !== 'undefined' ? window.prompt('Password required') : undefined
        this.setState({ password: passwordValue })
        return null
      }
    }

    const {
      body,
      banner,
      title,
      description,
      show_header,
      show_footer,
      hide_header_footer_nav,
      show_cta,
      homepage_logo_link,
      secondary_cta_text,
      secondary_cta_link,
      noindex,
    } = prismic

    return (
      <Layout
        customSEO
        showHeader={show_header}
        showFooter={show_footer}
        hideHeaderFooterNav={hide_header_footer_nav}
        showCTA={show_cta === null ? true : show_cta}
        homePageLink={homepage_logo_link === null ? false : homepage_logo_link}
        secondaryCTAText={secondary_cta_text}
        secondaryCTALink={secondary_cta_link}
      >
        <SEO
          bannerUrl={banner && banner.url}
          title={idx(title, _ => _.text)}
          pathname={this.props.location.pathname}
          desc={idx(description, _ => _.richText[0].text)}
          node={this.props.data.prismicPage}
          noIndex={noindex}
        />
        {renderPageSlices(body)}
      </Layout>
    )
  }
}

export default withPrismicPreview(Page)

export const query = graphql`
  query PageBySlug($id: ID!) {
    prismicPage(prismicId: { eq: $id }) {
      _previewable
      id
      first_publication_date
      last_publication_date
      data {
        banner {
          url
        }
        title {
          ...TextContent
        }
        description {
          ...TextContent
        }
        body {
          ...PageDataBody
        }
        hide_header_footer_nav
        noindex
        show_cta
        show_footer
        show_header
        homepage_logo_link
        secondary_cta_text {
          ...TextContent
        }
        secondary_cta_link {
          ...LinkContent
        }
        password {
          text
        }
      }
    }
  }
`
