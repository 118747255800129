/* eslint-disable indent */

import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from '@emotion/styled'
import idx from 'idx'
import Scanner from '../form/Scanner'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import GatsbyLink from '../../utils/GatsbyLink'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'
import meshGradient from '../../../static/gradients/mesh-gradient-large.png'

const BG = styled.div`
  position: absolute;
  ${p =>
    p.hasGradientBG
      ? `
  width: 100vw;
  height: 92%;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url(${meshGradient});
  z-index: -1;

  @media (max-width: 1050px) {
    height: 100%;
    background-position: top center;
  }
  `
      : `
  background-color: ${theme.color[p.backgroundColor]};
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
`}
`

const BannerWrapper = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.isCentered ? '1fr' : '1fr 1fr')};
  justify-items: center;
  position: relative;
  grid-column-gap: 6%;
  padding: 80px 0px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-column-gap: 0;
    grid-row-gap: 64px;
    grid-template-columns: 1fr;
    width: 100%;
    margin-left: 0px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 1050px) {
    padding-top: 80px;
    padding-bottom: 60px;
    grid-row-gap: 40px;
  }

  ${p =>
    p.hasGradientBG &&
    `
      padding-top: 56px;
      padding-bottom: 100px;


      @media (max-width: 1050px) {
        padding-bottom: 60px;
      }
      `}
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin-top: 24px;

  ${p =>
    p.isCentered &&
    `
    max-width: 700px;
    align-items: center;
    margin: 16px 0px 56px 0px;
    h1, p {
      text-align: center;
    }
  `}

  h1 {
    margin: 0 auto;
  }

  @media (max-width: 1600px) {
    margin-top: 24px;
  }

  @media (max-width: 1200px) {
    margin-top: 0px;
    align-items: center;
    width: 90%;

    h1,
    p {
      text-align: center;
      margin: 0 auto;
    }
    label p {
      text-align: left;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1050px) {
    display: none;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 1200px) {
    width: 75%;
  }
`

const ScannerWrapper = styled.div`
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 32px;
  width: 100%;
  max-width: 600px;
  margin: 32px 0px;

  label p {
    text-align: left;
  }
`

const BannerTitleScan = ({ primary }) => {
  const title = <RichText render={primary.title.richText} serializeHyperlink={GatsbyLink} />
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const smallCapsText = <RichText render={primary.small_caps_text.richText} serializeHyperlink={GatsbyLink} />
  const scannerLabelText = idx(primary, _ => _.scanner_input_label.text)
  const imageSrc = idx(primary, _ => _.image.url)
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const showScanner = idx(primary, _ => _.show_scanner)
  const hasGradientBG = idx(primary, _ => _.has_gradient_background)
  const backgroundColor = primary.background_color
  const isCentered = idx(primary, _ => _.is_centered)

  return (
    <BannerWrapper isCentered={isCentered} hasGradientBG={hasGradientBG}>
      <InfoWrapper isCentered={isCentered}>
        <Text font="smallCaps" richText color={hasGradientBG ? 'green500' : 'purple500'}>
          {smallCapsText || 'Free web accessibility checker'}
        </Text>
        {title && idx(title, _ => _.props.render[0].text) && (
          <Text
            font="heading1"
            style={{ marginBottom: '1.45rem' }}
            richText
            color={hasGradientBG ? 'white' : 'offBlack'}
          >
            {title}
          </Text>
        )}
        {text && idx(text, _ => _.props.render[0].text) && (
          <Text color={hasGradientBG ? 'white' : 'offBlack'} richText>
            {text}
          </Text>
        )}
        <Space height={8} />

        {showScanner &&
          (hasGradientBG ? (
            <ScannerWrapper>
              <Scanner darkBG={hasGradientBG} labelText={scannerLabelText} />
            </ScannerWrapper>
          ) : (
            <Scanner darkBG={false} labelText={scannerLabelText} />
          ))}
        {buttonText && buttonUrl && !showScanner && (
          <>
            <Space height={16} />
            <Button buttonType="tertiary reversed" text={buttonText} to={buttonUrl} />
          </>
        )}
      </InfoWrapper>
      {imageSrc && <ImageWrapper>{getImage(primary.image, Image, { loading: 'eager' })}</ImageWrapper>}
      <BG
        className="bg"
        hasGradientBG={hasGradientBG}
        backgroundColor={backgroundColor === null ? 'offWhite' : backgroundColor}
      />
    </BannerWrapper>
  )
}

export default BannerTitleScan

export const query = graphql`
  fragment BannerTitleScan on PrismicPageDataBodyBannerTitleScan {
    ...SliceBase
    id
    primary {
      anchor_id
      show_scanner
      has_gradient_background
      background_color
      is_centered
      button_text {
        ...TextContent
      }
      button_link {
        ...LinkContent
      }
      title {
        ...TextContent
      }
      text {
        ...TextContent
      }
      small_caps_text {
        ...TextContent
      }
      scanner_input_label {
        ...TextContent
      }
      image {
        alt
        url
        gatsbyImageData(width: 1080)
      }
    }
  }
`
