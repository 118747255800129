/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { trackEvent } from '../../utils/segmentTrack'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import { theme } from '../../styles'
import { ShortLeftGradientBar } from '../common/GradientBars'

import Text from '../common/Text'
import Space from '../common/Space'
import Hr from '../common/Hr'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 0px;

  @media (max-width: 1050px) {
    padding: 56px 16px 32px 16px;
  }
  @media (max-width: 800px) {
    padding: 56px 0px 32px 0px;
  }
`

const JobSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    justify-items: center;
    grid-template-columns: 1fr;
  }
`

const JobsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1.5em;
  @media (max-width: 800px) {
    padding-top: 32px;
  }
`

const JobItem = styled.a`
  width: 100%;
  padding: 24px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  background: ${theme.color.white};
  border: 0px solid transparent;
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 125ms ease-in-out;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.01);
  }

  @media (max-width: 1050px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
      transform: none;
    }
  }
`

const JobSection = ({ department }) => (
  <>
    <JobSectionWrapper>
      <Text heading3>{department.name}</Text>
      <JobsContainer>
        {department.jobs.map((job, index) => (
          <Fade right>
            <JobItem href={job.absolute_url} target="_blank" key={`${job.id}-${index}`}>
              <ShortLeftGradientBar index={index} />
              <Text bodyHeavy>{job.title}</Text>
              <Text smallBody>{job.location.name}</Text>
              <OpenNewTabHiddenSpan text={job.title} shortOverGraphic />
            </JobItem>
          </Fade>
        ))}
      </JobsContainer>
    </JobSectionWrapper>
    <Hr margin={56} widthPercent={100} maxWidth={false} />
  </>
)

const GreenhouseJobBoard = () => {
  const [departmentJobs, setDepartmentJobs] = useState(null)

  const getJobs = async () => {
    try {
      const response = await fetch('https://boards-api.greenhouse.io/v1/boards/audioeye/departments')
      return response
    } catch (err) {
      trackEvent(`API Exception`, {
        label: 'Greenhouse job board API general error',
        full_exception: err,
      })
      return false
    }
  }

  useEffect(async () => {
    const jobsResponse = await getJobs()
    if (jobsResponse && jobsResponse.ok) {
      const jobsArr = await jobsResponse.json()
      setDepartmentJobs(jobsArr.departments)
    } else {
      trackEvent(`API Exception`, {
        label: 'Greenhouse job board API general error',
        full_exception: JSON.stringify(jobsResponse),
      })
    }
  }, [])

  return (
    <Wrapper>
      <Text heading2>Current Openings</Text>
      <Space height={48} />
      {departmentJobs &&
        departmentJobs.length > 0 &&
        departmentJobs.map((department, index) => {
          if (department.jobs && department.jobs.length > 0) {
            return <JobSection department={department} key={`${department.id}-${index}`} />
          }
          return null
        })}
    </Wrapper>
  )
}

export default GreenhouseJobBoard
