import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../utils/GatsbyLink'

export const Wrapper = styled.div`
  margin-bottom: 56px;
  background-color: ${p => p.theme.color.gray100};
  border-radius: 10px;
  padding: 40px;

  p {
    margin-top: 0px;
  }

  @media (max-width: 800px) {
    padding: 24px;
  }
`

const Summary = ({ primary }) => {
  const { text } = primary
  return (
    <Wrapper>
      <RichText render={text.richText} serializeHyperlink={GatsbyLink} />
    </Wrapper>
  )
}

export default Summary

export const query = graphql`
  fragment PostSummary on PrismicPostDataBodySummary {
    ...SliceBase
    primary {
      text {
        ...TextContent
      }
    }
  }
`
