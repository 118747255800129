/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react'
import { Link } from 'gatsby'

import Text from './Text'
import { trackEvent } from '../../utils/segmentTrack'
import { OpenNewTabHiddenSpan } from './NewTabHiddenSpan'
import { addSmoothScrollClass } from '../../utils/addSmoothScrollClass'

const TextLink = React.forwardRef(
  (
    { to, text, onKeyDown, role, tabIndex, outboundHeaderLink, color, font, newTab, anchorClassName, ...textProps },
    ref
  ) => {
    const oldOnClick = textProps.onClick || (() => {})
    textProps.onClick = e => {
      // Smooth scroll for anchor links
      addSmoothScrollClass()
      oldOnClick(e)
    }
    const trackedKeyDown = e => {
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    }
    const target = to && to.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')
    const opensPdf = to && to.includes('.pdf')

    const linkProps = {
      role,
      tabIndex,
      href: target,
      rel: 'noopener noreferrer',
    }

    const el = text ? (
      <Text isSpan font={font || 'body'} richText={textProps?.richText} color={color || 'purple500'} {...textProps}>
        {text}
      </Text>
    ) : null

    // supporting keyboard listeners
    const aRef = useCallback(node => {
      if (node !== null) {
        if (typeof ref === 'function') {
          ref(node)
        } else if (ref !== null) {
          ref.current = node
        }
        node.addEventListener('keydown', trackedKeyDown)
      }
    }, [])

    if ((target && target.indexOf('http') > -1) || newTab) {
      return (
        <a
          {...linkProps}
          href={target}
          target="_blank"
          className={anchorClassName || ''}
          onClick={() => {
            if (opensPdf) {
              trackEvent(`PDF Downloaded`, {
                pdf_name: to,
              })
            }
          }}
          onKeyDown={e => {
            if (outboundHeaderLink) trackedKeyDown(e)
            if (e.key === 'Escape') {
              e.target.blur()
            }
          }}
        >
          {el}
          <OpenNewTabHiddenSpan text={text || ''} fileType={opensPdf && 'PDF'} />
        </a>
      )
    }

    if (!to) {
      return (
        <a
          role="button"
          tabIndex={tabIndex || '0'}
          onClick={textProps.onClick}
          className={anchorClassName || ''}
          onKeyDown={e => {
            trackedKeyDown(e)
          }}
        >
          {el}
        </a>
      )
    }

    return (
      <Link to={target} ref={aRef} className={anchorClassName || ''} {...linkProps}>
        {el}
      </Link>
    )
  }
)

TextLink.displayName = 'TextLink'

export default TextLink
