import React from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import chevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon.svg'
import whiteChevron from '../../../static/icons/arrow-icons/chevron-icon-white.svg'

const PageBlockWrapper = styled.button`
  min-width: 40px;
  min-height: 35px;
  border: 1px solid ${p => p.theme.color.offBlack};
  border-radius: 3px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${p => (p.isSelected ? p.theme.color.offBlack : p.theme.color.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 0px 10px;

  ${p =>
    p.darkBG &&
    `
    background-color: ${p.isSelected ? p.theme.color.white : 'transparent'};
    border: 1px solid ${p.theme.color.white};
  `}

  &:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 1050px) {
    min-width: unset;
    margin-left: 8px;
    margin-top: 8px;
  }
`

const Img = styled.img`
  width: 14px;
  transform: ${p => (p.previous ? 'rotate(90deg)' : 'rotate(270deg)')};
`

const modifyPage = (page, darkBG) => {
  switch (page) {
    case 'previous':
      return <Img src={darkBG ? whiteChevron : chevron} previous alt="" />
    case 'next':
      return <Img src={darkBG ? whiteChevron : chevron} next alt="" />
    default:
      return page
  }
}

const PageBlock = ({ page, isSelected, ariaLabel, darkBG, ...props }) => {
  const modifiedPage = modifyPage(page, darkBG)

  return (
    <PageBlockWrapper
      aria-label={ariaLabel || `page ${page}${isSelected ? ' is selected' : ''}`}
      isSelected={isSelected}
      aria-current={isSelected}
      key={ariaLabel}
      darkBG={darkBG}
      className={darkBG ? 'dark-bg-focus' : null}
      {...props}
    >
      {page === 'previous' || page === 'next' ? (
        modifiedPage
      ) : (
        <Text smallBodyHeavy color={darkBG ? (isSelected ? 'offBlack' : 'white') : isSelected ? 'white' : ''}>
          {page}
        </Text>
      )}
    </PageBlockWrapper>
  )
}
export default PageBlock
