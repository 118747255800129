/* eslint-disable camelcase */
/* eslint-disable indent */
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import idx from 'idx'
import { getCookie } from '../../../utils/cookie'
import { trackEvent } from '../../../utils/segmentTrack'
import parseFullName from '../../../utils/parseFullName'
import testingVariant from '../../../../config/testingVariant'

const hsCookie = getCookie('hubspotutk')
const params = new URLSearchParams(typeof window !== 'undefined' && window.location.search)

export const trackForm = (formData, stagingHubSpotFormId, productionHubSpotFormId) => {
  const mappedData = mapValues(keyBy(formData, 'name'), 'value')

  if (typeof window !== 'undefined') {
    trackEvent(`Hubspot Form Submitted`, {
      email: mappedData.email || '',
      job_title: mappedData.jobtitle || '',
      website: mappedData.website || '',
      company_name: mappedData.company || '',
      hs_form_name: mappedData.form_name || '',
      hs_form_id: process.env.GATSBY_ACTIVE_ENV === 'production' ? productionHubSpotFormId : stagingHubSpotFormId,
      page_url: window.location.href || '',
    })
  }
}
const persistedUtmParams = typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem('utm_params'))
const utmParams = {
  utmCampaign: params.get('utm_campaign') || idx(persistedUtmParams, _ => _.utm_campaign) || '',
  utmContent: params.get('utm_content') || idx(persistedUtmParams, _ => _.utm_content) || '',
  utmMedium: params.get('utm_medium') || idx(persistedUtmParams, _ => _.utm_medium) || '',
  utmSource: params.get('utm_source') || idx(persistedUtmParams, _ => _.utm_source) || '',
  utmTerm: params.get('utm_term') || idx(persistedUtmParams, _ => _.utm_term) || '',
  utmPlacement: params.get('utm_placement') || idx(persistedUtmParams, _ => _.utm_placement) || '',
}

export const onboardingHsFormCollect = async (formData, intlNum) => {
  const { email, fullName, url, freelancerAgency, numWebsites, company, form_name, agencyKeywordsFound } = formData
  const prodHSOnboardingFormID = '384a986d-d7fb-496c-b41d-20305721d23d'
  const stagingHSOnboardingFormID = 'b551162d-5f6c-4811-8948-da0a9299c70e'
  const { firstName, lastName } = parseFullName(fullName)
  const testData = JSON.stringify(testingVariant())

  try {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${
        process.env.GATSBY_ACTIVE_ENV === 'production' ? prodHSOnboardingFormID : stagingHSOnboardingFormID
      }`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${Date.now()}`,
          fields: [
            {
              name: 'email',
              value: email,
            },
            {
              name: 'firstname',
              value: firstName,
            },
            {
              name: 'lastname',
              value: lastName,
            },
            {
              name: 'phone',
              value: intlNum,
            },
            {
              name: 'website',
              value: url,
            },
            {
              name: 'agency_or_freelancer',
              value: freelancerAgency,
            },
            {
              name: 'record_type',
              value: freelancerAgency.length > 0 ? numWebsites : '',
            },
            {
              name: 'company',
              value: company,
            },
            {
              name: 'variant',
              value: testData,
            },
            {
              name: 'agency_keywords_found',
              value: agencyKeywordsFound || '',
            },
            {
              name: 'utm_campaign',
              value: utmParams.utmCampaign,
            },
            {
              name: 'utm_content',
              value: utmParams.utmContent,
            },
            {
              name: 'utm_medium',
              value: utmParams.utmMedium,
            },
            {
              name: 'utm_source',
              value: utmParams.utmSource,
            },
            {
              name: 'utm_term',
              value: utmParams.utmTerm,
            },
            {
              name: 'utm_placement',
              value: utmParams.utmPlacement,
            },
          ],
          context: {
            hutk: hsCookie,
            pageUri: (window && window.location.href) || '#get-started-free',
            pageName: document.title || '',
          },
        }),
      }
    )
    trackEvent(`Hubspot Form Submitted`, {
      email: email || '',
      job_title: '',
      website: url || '',
      hs_form_name: form_name || '',
      hs_form_id: process.env.GATSBY_ACTIVE_ENV === 'production' ? prodHSOnboardingFormID : stagingHSOnboardingFormID,
      page_url: window.location.href || '',
    })
    if (!response.ok) {
      const resBody = await response.json()
      trackEvent(`API Exception`, {
        label: 'Hubspot form submit onboarding error',
        full_exception: resBody,
      })
    }
  } catch (err) {
    console.log('hubspot err', err)
    trackEvent(`API Exception`, {
      label: 'Hubspot form submit onboarding error',
      full_exception: err,
    })
  }
}

export const scannerGateHsFormCollect = async (formData, scoreData, url, intlNum) => {
  const beneficiaryData = {}
  if (scoreData && scoreData.beneficiaryImpact) {
    scoreData.beneficiaryImpact.forEach(item => {
      if (item.beneficiary === 'visual') beneficiaryData.visual = item.errorCount
      if (item.beneficiary === 'auditory') beneficiaryData.auditory = item.errorCount
      if (item.beneficiary === 'cognitive') beneficiaryData.cognitive = item.errorCount
      if (item.beneficiary === 'motor') beneficiaryData.motor = item.errorCount
    })
  }
  const { email, fullName, form_name, freelancerAgency, numWebsites, scannerScoreUrl, agencyKeywordsFound } = formData
  const prodFormId = '2ced1a76-06eb-492e-9871-6d5575dc2794'
  const stagingFormId = '7c7d53b6-919e-476e-9674-ae13af6b68b9'
  const { firstName, lastName } = parseFullName(fullName)
  const testData = JSON.stringify(testingVariant())

  try {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${
        process.env.GATSBY_ACTIVE_ENV === 'production' ? prodFormId : stagingFormId
      }`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${Date.now()}`,
          fields: [
            {
              name: 'email',
              value: email,
            },
            {
              name: 'firstname',
              value: firstName,
            },
            {
              name: 'lastname',
              value: lastName,
            },
            {
              name: 'phone',
              value: intlNum,
            },
            {
              name: 'website',
              value: url,
            },
            {
              name: 'agency_or_freelancer',
              value: freelancerAgency,
            },
            {
              name: 'record_type',
              value: freelancerAgency.length > 0 ? numWebsites : '',
            },
            {
              name: 'scanner_score',
              value: scoreData?.score || '',
            },
            {
              name: 'scanner_total_number_of_errors',
              value: scoreData?.numIssues || '',
            },
            {
              name: 'scanner_visual_error_count',
              value: beneficiaryData?.visual || '',
            },
            {
              name: 'scanner_auditory_error_count',
              value: beneficiaryData?.auditory || '',
            },
            {
              name: 'scanner_cognitive_error_count',
              value: beneficiaryData?.cognitive || '',
            },
            {
              name: 'scanner_motor_error_count',
              value: beneficiaryData?.motor || '',
            },
            {
              name: 'scanner_score_url',
              value: scannerScoreUrl,
            },
            {
              name: 'scanner_issue_code_1',
              value: scoreData.topIssues?.[0]?.code || '',
            },
            {
              name: 'top_issue_1_description',
              value: scoreData.topIssues?.[0]?.description || '',
            },
            {
              name: 'top_issue_1_primary_beneficiary',
              value: scoreData.topIssues?.[0]?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_1_wcag_criterion',
              value: scoreData.topIssues?.[0]?.standards?.[0].standard
                ? `${scoreData.topIssues?.[0]?.standards?.[0].standard} ${scoreData.topIssues?.[0]?.standards?.[0].standardDetails.complianceLevel} Success Criterion ${scoreData.topIssues?.[0]?.standards?.[0].standardDetails.successCriteria}`
                : '',
            },
            {
              name: 'top_issue_1_wcag_criterion_resource',
              value: scoreData.topIssues?.[0]?.standards?.[0]?.standardDetails?.resource || '',
            },
            {
              name: 'top_issue_1_user_impact_severity',
              value: scoreData.topIssues?.[0]?.userImpactSeverity || '',
            },
            {
              name: 'top_issue_1_element_count',
              value: scoreData.topIssues?.[0]?.elementCount || '',
            },
            {
              name: 'scanner_issue_code_2',
              value: scoreData.topIssues?.[1]?.code || '',
            },
            {
              name: 'top_issue_2_description',
              value: scoreData.topIssues?.[1]?.description || '',
            },
            {
              name: 'top_issue_2_primary_beneficiary',
              value: scoreData.topIssues?.[1]?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_2_wcag_criterion',
              value: scoreData.topIssues?.[1]?.standards?.[0].standard
                ? `${scoreData.topIssues?.[1]?.standards?.[0].standard} ${scoreData.topIssues?.[1]?.standards?.[0].standardDetails.complianceLevel} Success Criterion ${scoreData.topIssues?.[1]?.standards?.[0].standardDetails.successCriteria}`
                : '',
            },
            {
              name: 'top_issue_2_wcag_criterion_resource',
              value: scoreData.topIssues?.[1]?.standards?.[0]?.standardDetails?.resource || '',
            },
            {
              name: 'top_issue_2_user_impact_severity',
              value: scoreData.topIssues?.[1]?.userImpactSeverity || '',
            },
            {
              name: 'top_issue_2_element_count',
              value: scoreData.topIssues?.[1]?.elementCount || '',
            },
            {
              name: 'scanner_issue_code_3',
              value: scoreData.topIssues?.[2]?.code || '',
            },
            {
              name: 'top_issue_3_description',
              value: scoreData.topIssues?.[2]?.description || '',
            },
            {
              name: 'top_issue_3_primary_beneficiary',
              value: scoreData.topIssues?.[2]?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_3_wcag_criterion',
              value: scoreData.topIssues?.[2]?.standards?.[0].standard
                ? `${scoreData.topIssues?.[2]?.standards?.[0].standard} ${scoreData.topIssues?.[2]?.standards?.[0].standardDetails.complianceLevel} Success Criterion ${scoreData.topIssues?.[2]?.standards?.[0].standardDetails.successCriteria}`
                : '',
            },
            {
              name: 'top_issue_3_wcag_criterion_resource',
              value: scoreData.topIssues?.[2]?.standards?.[0]?.standardDetails?.resource || '',
            },
            {
              name: 'top_issue_3_user_impact_severity',
              value: scoreData.topIssues?.[2]?.userImpactSeverity || '',
            },
            {
              name: 'top_issue_3_element_count',
              value: scoreData.topIssues?.[2]?.elementCount || '',
            },
            {
              name: 'variant',
              value: testData,
            },
            {
              name: 'agency_keywords_found',
              value: agencyKeywordsFound || '',
            },
            {
              name: 'form_name',
              value: form_name,
            },
            {
              name: 'utm_campaign',
              value: utmParams.utmCampaign,
            },
            {
              name: 'utm_content',
              value: utmParams.utmContent,
            },
            {
              name: 'utm_medium',
              value: utmParams.utmMedium,
            },
            {
              name: 'utm_source',
              value: utmParams.utmSource,
            },
            {
              name: 'utm_term',
              value: utmParams.utmTerm,
            },
            {
              name: 'utm_placement',
              value: utmParams.utmPlacement,
            },
          ],
          context: {
            hutk: hsCookie,
            pageUri: (window && window.location.href) || '',
            pageName: document.title || '',
          },
        }),
      }
    )
    trackEvent(`Hubspot Form Submitted`, {
      email: email || '',
      job_title: '',
      website: url || '',
      hs_form_name: form_name || '',
      hs_form_id: process.env.GATSBY_ACTIVE_ENV === 'production' ? prodFormId : stagingFormId,
      page_url: window.location.href || '',
    })
    // Don't show the form again
    window.sessionStorage.setItem('gate', false)
    if (!response.ok) {
      const resBody = await response.json()
      trackEvent(`API Exception`, {
        label: 'Hubspot form submit scanner gate form error',
        full_exception: resBody,
      })
    }
  } catch (err) {
    console.log('hubspot err', err)
    trackEvent(`API Exception`, {
      label: 'Hubspot form submit scanner gate form error',
      full_exception: err,
    })
  }
}
