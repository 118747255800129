import React from 'react'
import { graphql } from 'gatsby'
import Chart from './Chart'
import ProductProcess from './ProductProcess'
import GreenhouseJobBoard from './GreenhouseJobBoard'
import PressReleasesGrid from './press/PressReleasesGrid'
import NewsItemsGrid from './press/NewsItemsGrid'
import ContrastChecker from '../ContrastChecker'

export default function StaticContentBlock({ primary }) {
  const CHART = 'chart'
  const PRODUCT_PROCESS = 'product-process'
  const GREENHOUSE_BOARD = 'greenhouse-board'
  const PRESS_RELEASES = 'press-releases'
  const IN_THE_NEWS = 'in-the-news'
  const CONTRAST_CHECKER = 'contrast-checker'

  switch (primary.component_type) {
    case PRODUCT_PROCESS:
      return <ProductProcess />
    case CHART:
      return <Chart />
    case GREENHOUSE_BOARD:
      return <GreenhouseJobBoard />
    case PRESS_RELEASES:
      return <PressReleasesGrid />
    case IN_THE_NEWS:
      return <NewsItemsGrid />
    case CONTRAST_CHECKER:
      return <ContrastChecker />
    default:
      return null
  }
}

export const query = graphql`
  fragment StaticContentBlock on PrismicPageDataBodyStaticComponent {
    ...SliceBase
    primary {
      component_type
    }
  }
`
