import React from 'react'
import { theme } from '../../../../styles'

export const IndustryNewsIcon = ({ index }) => {
  const colors = [
    theme.color.purple500,
    theme.color.blue500,
    theme.color.green500,
    theme.color.orange500,
    theme.color.red500,
    theme.color.purple500,
  ]
  return (
    <svg
      style={{ gridArea: '1 / 1 / 2 / -1', zIndex: '1' }}
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="51.6604" fill={colors[index]} stroke={colors[index]} strokeWidth="0.679245" />
      <path
        d="M51.5 80C66.6878 80 79 67.6878 79 52.5C79 37.3122 66.6878 25 51.5 25C36.3122 25 24 37.3122 24 52.5C24 67.6878 36.3122 80 51.5 80Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5527 58.3956C51.4472 58.6517 51.3757 58.8062 51.3191 58.9644C49.7671 63.0819 48.2188 67.1993 46.663 71.313C46.2448 72.4168 45.3257 73.0233 44.1616 72.9932C43.0955 72.9668 42.2178 72.3226 41.875 71.3243C41.664 70.7103 41.7469 70.1151 41.9428 69.5086C42.9147 66.5363 43.9544 63.5867 44.8133 60.5805C45.9887 56.4819 46.4746 52.2702 46.4822 48.0058C46.4822 47.113 46.275 46.8569 45.401 46.6572C42.0746 45.9075 38.7483 45.1579 35.4219 44.412C34.6383 44.2387 34.0054 43.862 33.6363 43.1274C32.8941 41.6507 34.0318 39.9027 35.7421 39.9932C36.8647 40.0534 37.9798 40.3058 39.0986 40.464C42.5794 40.9613 46.0263 41.6695 49.5561 41.824C52.7846 41.9671 55.9829 41.7298 59.1698 41.225C61.7503 40.8144 64.3346 40.4075 66.9188 40.0082C68.2788 39.7973 69.4616 40.5771 69.6726 41.8089C69.8835 43.0257 69.0811 44.0918 67.7174 44.4007C64.3911 45.1541 61.0647 45.9 57.7383 46.6497C56.8267 46.8531 56.6346 47.0942 56.6308 48.0284C56.5931 54.3685 57.9493 60.4449 60.0702 66.3781C60.4507 67.4442 60.8198 68.5103 61.189 69.5801C61.6825 71.0041 61.0948 72.3452 59.7688 72.8387C58.4051 73.3435 56.9698 72.688 56.4462 71.3017C54.8904 67.188 53.3421 63.0706 51.79 58.9531C51.726 58.7986 51.6582 58.648 51.5527 58.3956Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5452 31.9955C53.975 31.988 55.9301 33.9205 55.949 36.3541C55.9716 38.7952 53.9976 40.7917 51.5603 40.7955C49.1267 40.7993 47.1339 38.8027 47.1489 36.3691C47.164 33.9431 49.1116 32.003 51.5452 31.9955Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
