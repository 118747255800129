import React from 'react'
import styled from '@emotion/styled'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import Text from '../common/Text'
import Space from '../common/Space'
import { Facebook } from '../../../static/icons/component-icons/FacebookIcon.js'
import { Twitter } from '../../../static/icons/component-icons/TwitterIcon.js'
import { LinkedIn } from '../../../static/icons/component-icons/LinkedInIcon.js'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const SocialWrapper = styled.div`
  display: flex;

  button {
    border-radius: 50%;
  }

  .icon {
    transition: all 75ms ease-in-out;
    fill: ${p => p.theme.color.offBlack};
  }
  button:hover {
    .icon {
      fill: ${p => p.theme.color.green500};
    }
  }

  @media (max-width: 800px) {
    svg {
      width: 24px;
      height: 24px;
    }
    button:hover {
      .icon {
        fill: ${p => p.theme.color.offBlack};
      }
    }
  }
`

const SharePost = () => (
  <Wrapper>
    <Text bodyHeavy center>
      Share this post
    </Text>
    <Space height={8} />
    <SocialWrapper>
      <LinkedinShareButton
        style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
        url={typeof window !== 'undefined' && window.location.href}
        aria-label="opens a new linkedin tab"
      >
        <LinkedIn alt="linkedin" />
      </LinkedinShareButton>
      <Space width={12} />
      <FacebookShareButton
        style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
        url={typeof window !== 'undefined' && window.location.href}
        aria-label="opens a new facebook tab"
      >
        <Facebook alt="facebook" />
      </FacebookShareButton>
      <Space width={12} />
      <TwitterShareButton
        style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
        url={typeof window !== 'undefined' && window.location.href}
        aria-label="opens a new twitter tab"
      >
        <Twitter alt="twitter" />
      </TwitterShareButton>
    </SocialWrapper>
  </Wrapper>
)

export default SharePost
