/* eslint-disable camelcase */
import { navigate } from 'gatsby-link'
import { trackEvent } from '../../../utils/segmentTrack'
import { prependUrl } from './utils'
import parseFullName from '../../../utils/parseFullName'

export const checkSiteValidity = async (url, setStage) => {
  try {
    const response = await fetch(`${process.env.GATSBY_AEYE_PORTAL_URL}/site/validity?url=${prependUrl(url)}`)
    return response
  } catch (err) {
    trackEvent(`API Exception`, {
      label: 'Check site validity general error',
      full_exception: err,
    })
    setStage('error')
    return false
  }
}

export const createUser = async (formData, setStage, intlNum) => {
  const { email, password, fullName, numWebsites, freelancerAgency, company } = formData
  const { firstName, lastName } = parseFullName(fullName)
  try {
    const userResponse = await fetch(`${process.env.GATSBY_AEYE_PORTAL_URL}/user`, {
      headers: {
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        phone: intlNum,
        company,
        numberOfSitesManaged: numWebsites,
        customerType: freelancerAgency ? 'Reseller' : 'Direct',
      }),
    })

    return userResponse
  } catch (err) {
    trackEvent(`API Exception`, {
      label: `Create user general error`,
      full_exception: err,
    })
    setStage('error')
    return false
  }
}

export const loginUser = async (email, password, setStage) => {
  try {
    const user = await fetch(`/fetch-user`, {
      headers: {
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        username: email,
        password,
      }),
    })
    return user.json()
  } catch (err) {
    console.log(err)
    trackEvent(`API Exception`, {
      label: `Login user general error`,
      full_exception: err,
    })
    setStage('error')
    return false
  }
}

export const createSite = async (url, planId, coupons, id, accessToken, setStage) => {
  try {
    const createSiteResponse = await fetch(`${process.env.GATSBY_AEYE_PORTAL_URL}/user/${id}/site`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'POST',
      body: JSON.stringify({
        url: prependUrl(url),
        planId,
        coupons,
      }),
    })

    const result = await createSiteResponse.json()
    return result
  } catch (err) {
    trackEvent(`API Exception`, {
      label: `Create site general error`,
      full_exception: err,
    })
    setStage('error')
    return false
  }
}

export const provisionAccount = async (setStage, setPartnerName, formData, intlNum) => {
  setStage(2)

  const { url, email, password, first_name, last_name } = formData

  // site validity api call
  const siteValidityResponse = await checkSiteValidity(url, setStage)
  const siteValidityBody = await siteValidityResponse.json()

  // detected site is with CMS partner
  if (siteValidityResponse.status === 419) {
    trackEvent(`API Exception`, {
      label: 'Site uses partner CMS',
      full_exception: siteValidityBody,
    })
    setPartnerName(siteValidityBody.partner)
    setStage('cms')
    return false
  }

  // detected existing partner
  if (siteValidityResponse.status === 420) {
    trackEvent(`API Exception`, {
      label: 'Site already registered as partner site',
      full_exception: siteValidityBody,
    })
    setStage('partner')
    return false
  }

  const createUserResponse = await createUser(formData, setStage, intlNum)
  const createUserBody = await createUserResponse.json()

  if (createUserResponse.status === 500) {
    trackEvent(`API Exception`, {
      label: 'Create user error',
      full_exception: createUserBody,
    })
    setStage('error')
    return
  }

  // detected existing user
  if (createUserResponse.status === 409 || createUserResponse.statusCode === 409) {
    trackEvent(`API Exception`, {
      label: 'Existing user error',
      full_exception: createUserBody,
    })
    setStage('exists')
    return false
  }

  const loginUserResponse = await loginUser(email, password, setStage)
  const id = loginUserResponse.username
  const accessToken = loginUserResponse.signInUserSession.accessToken.jwtToken

  // Segment Identify call
  if (typeof window !== 'undefined' && window.analytics.user) {
    const anonymousID = window.analytics.user().anonymousId()
    window.analytics.identify(id, {
      account_create_method: 'email',
      first_name,
      last_name,
      email,
      segment_anonymousID: anonymousID,
    })
  }

  const planId = 'simple-monthly'
  const coupons = []
  const createSiteResponse = await createSite(url, planId, coupons, id, accessToken, setStage)

  if (
    createSiteResponse.statusCode === 500 ||
    createSiteResponse.statusCode === 400 ||
    !createSiteResponse.subscriptionId
  ) {
    trackEvent(`API Exception`, {
      label: 'Create site error',
      full_exception: createSiteResponse,
    })
    setStage('error')
    return false
  }
  if (createSiteResponse.subscriptionId) {
    // track success event
    trackEvent(`Onboarding Modal Completed`, {
      category: 'onboarding_modal',
      email,
      first_name,
      last_name,
      site_id: createSiteResponse.id || '',
      site_url: url || '',
      plan_name: planId,
      plan_price: '49',
      plan_period_unit: 'month',
      account_create_method: 'email',
      subscription_id: createSiteResponse.subscriptionId || '',
      subscription_status: 'in_trial',
    })
    navigate(`${process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}?email=${encodeURIComponent(formData.email)}`)
  }
}
