/* eslint-disable indent */

import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'

import GatsbyLink from '../../utils/GatsbyLink'
import Space from '../common/Space'
import Text from '../common/Text'
import quote from '../../../static/icons/misc-icons/quote.svg'
import Carousel from '../common/Carousel'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  height: 100%;
  z-index: -1;
  top: 0;

  ${p =>
    p.hasGradientBG
      ? `
      width: 150vw;
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-color: ${theme.color.purple900};
      background-image: radial-gradient(at 36% 28%, hsla(267,80%,50%,1) 0, transparent 50%),
        radial-gradient(at 82% 16%, hsla(267,95%,20%,1) 0, transparent 50%),
        radial-gradient(at 10% 70%, hsla(267,95%,20%,1) 0, transparent 50%),
        radial-gradient(at 84% 63%, hsla(267,80%,50%,1) 0, transparent 50%),
        radial-gradient(at 9% 89%, hsla(267,95%,20%,1) 0, transparent 50%),
        radial-gradient(at 80% 100%, hsla(171,70%,49%,1) 0, transparent 50%),
        radial-gradient(at 0% 0%, hsla(171,70%,49%,1) 0, transparent 50%);
      margin-left: -75%;

      @media (max-width:1050px) {
        width: 150vw;
        margin-left: -50%;
      }
  `
      : `
      background-color: ${theme.color[p.backgroundColor]};
      width: 500vw;
      margin-left: -100%;
`}
`

const Wrapper = styled.div`
  color: white;
  padding: 80px 0px;
  width: 100%;
  position: relative;

  @media (max-width: 1050px) {
    width: 100%;
    padding: 56px 0px;
    margin-left: 0px;
  }
`

const QuoteWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 40px;

  @media (max-width: 800px) {
    padding-bottom: 0px;
    flex-direction: column;
  }
`

const Image = styled.img`
  display: flex;
  max-width: 375px;
  object-fit: contain;
  width: 100%;

  @media (max-width: 1050px) {
    max-width: 200px;
  }
`

const QuoteImg = styled.img`
  width: 61.4px;
  height: 48.4px;
  object-fit: contain;
  margin-right: 16px;

  @media (max-width: 1050px) {
    width: 32px;
    height: auto;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InfoWrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 240px;
  min-width: 700px;

  & p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 1500px) {
    min-width: unset;
  }
`

const InfoOutWrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: flex-start;
`

const QuoteComponent = ({ text, name, profession, image, hasGradientBG }) => (
  <QuoteWrapper hasGradientBG={hasGradientBG}>
    <ImageWrapper>{getImage(image, Image)}</ImageWrapper>
    <Space width={40} height={40} tabletHeight={8} tabletWidth={0} />
    <InfoOutWrapper>
      <QuoteImg alt="" src={quote} />
      <Space width={24} tabletWidth={16} mobileWidth={8} />
      <InfoWrapper>
        <Text color={hasGradientBG ? 'white' : 'offBlack'} richText font="heading4">
          <RichText render={text.richText} serializeHyperlink={GatsbyLink} />
        </Text>
        <Space height={16} tabletHeight={0} />
        <Text color={hasGradientBG ? 'white' : 'offBlack'} richText font="bodyHeavy">
          <RichText render={name.richText} serializeHyperlink={GatsbyLink} />
        </Text>
        <Space height={5} tabletHeight={0} />
        <Text color={hasGradientBG ? 'white' : 'offBlack'} richText font="smallBody">
          <RichText render={profession.richText} serializeHyperlink={GatsbyLink} />
        </Text>
      </InfoWrapper>
    </InfoOutWrapper>
  </QuoteWrapper>
)
const Quote = ({ items, primary }) => {
  const hasGradientBG = idx(primary, _ => _.has_gradient_background)
  const renderItem = item => (
    <>
      <QuoteComponent
        text={idx(item, _ => _.text)}
        name={idx(item, _ => _.name)}
        profession={idx(item, _ => _.profession)}
        image={idx(item, _ => _.image)}
        hasGradientBG={hasGradientBG}
      />
    </>
  )
  return (
    <Wrapper id={primary.anchorId || null}>
      <Carousel renderItem={renderItem} items={items} ariaLabel="Quote pagination" darkBG={hasGradientBG} />
      <BG className="bg" hasGradientBG={hasGradientBG} />
    </Wrapper>
  )
}

export default Quote

export const query = graphql`
  fragment Quote on PrismicPageDataBodyQuote {
    ...SliceBase
    items {
      image {
        alt
        url
        gatsbyImageData(width: 375)
      }
      name {
        ...TextContent
      }
      profession {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
    primary {
      has_gradient_background
      anchor_id
    }
  }
`
