export default (url, autoPlay = false) => {
  if (/vimeo\.com/.test(url)) {
    const vimeoId = /\/(\d+)/.exec(url)
    const urlAutoPlay = /autoplay=1?/.test(url)
    if (vimeoId != null && vimeoId[1]) {
      if (autoPlay || urlAutoPlay) {
        return `https://player.vimeo.com/video/${vimeoId[1]}?autoplay=1&loop=1&autopause=0&muted=1&title=0&portrait=0&byline=0?texttrack=es`
      }
      return `https://player.vimeo.com/video/${vimeoId[1]}?title=0&portrait=0&byline=0&?texttrack=es`
    }
  }
  return url
}
