import idx from 'idx'
import { gaPropertyId } from './keys'

export default () => {
  const windowGlobal = typeof window !== `undefined` && window
  const optimizeExperiments = {
    optimizeTest: (windowGlobal && idx(window?.gaData?.[gaPropertyId], _ => _.experiments)) || '',
  }
  const netlifyExperiments = { netlifyTest: 'Control' }

  return { ...netlifyExperiments, ...optimizeExperiments }
}
