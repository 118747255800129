import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

const BlockQuote = styled.blockquote`
  margin-left: 0;
  border-left: 2px solid ${p => p.theme.color.green500};
  margin: 40px 0px;
  padding-left: 1.45rem;
  p {
    font-size: ${p => p.theme.fonts.size.body};
    font-style: italic;
  }
`

const Quote = ({ primary }) => (
  <BlockQuote>
    <div dangerouslySetInnerHTML={{ __html: primary.quote.html }} />
  </BlockQuote>
)

export default Quote

export const query = graphql`
  fragment PostQuote on PrismicPostDataBodyQuote {
    ...SliceBase
    primary {
      quote {
        ...TextContent
      }
    }
  }
`
