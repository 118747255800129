/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Facebook from './Facebook'
import Twitter from './Twitter'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, bannerUrl, pathname, article, node, noIndex }) => (
  <StaticQuery
    query={`${query}`}
    render={data => {
      const {
        buildTime,
        siteMetadata: {
          siteUrl,
          defaultTitle,
          defaultDescription,
          defaultBanner,
          bannerAlt,
          headline,
          siteLanguage,
          ogLanguage,
          author,
          twitter,
          facebook,
        },
      } = data.site

      const seo = {
        title: title || defaultTitle,
        description: desc || defaultDescription,
        image: bannerUrl || `${siteUrl}${banner || defaultBanner}`,
        imageAlt: bannerAlt || defaultDescription,
        url: `${siteUrl}${pathname || ''}`,
      }

      // schema.org in JSONLD format
      // https://developers.google.com/search/docs/guides/intro-structured-data
      // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

      const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: siteUrl,
        headline,
        inLanguage: siteLanguage,
        mainEntityOfPage: siteUrl,
        description: defaultDescription,
        name: defaultTitle,
        author: {
          '@type': 'Person',
          name: author,
        },
        copyrightHolder: {
          '@type': 'Person',
          name: author,
        },
        copyrightYear: '2019',
        creator: {
          '@type': 'Person',
          name: author,
        },
        publisher: {
          '@type': 'Person',
          name: author,
        },
        datePublished: '2019-01-18T10:30:00+01:00',
        dateModified: buildTime,
        image: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      }

      // Initial breadcrumb list

      const itemListElement = [
        {
          '@type': 'ListItem',
          item: {
            '@id': siteUrl,
            name: 'Homepage',
          },
          position: 1,
        },
      ]

      let schemaArticle = null

      if (article) {
        schemaArticle = {
          '@context': 'http://schema.org',
          '@type': 'Article',
          author: {
            '@type': 'Person',
            name: author,
          },
          copyrightHolder: {
            '@type': 'Person',
            name: author,
          },
          copyrightYear: '2019',
          creator: {
            '@type': 'Person',
            name: author,
          },
          publisher: {
            '@type': 'Organization',
            name: author,
            logo: {
              '@type': 'ImageObject',
              url: `${siteUrl}${defaultBanner}`,
            },
          },
          datePublished: node.first_publication_date,
          dateModified: node.last_publication_date,
          description: seo.description,
          headline: seo.title,
          inLanguage: siteLanguage,
          url: seo.url,
          name: seo.title,
          image: {
            '@type': 'ImageObject',
            url: seo.image,
          },
          mainEntityOfPage: seo.url,
        }
        // Push current blogpost into breadcrumb list
        itemListElement.push({
          '@type': 'ListItem',
          item: {
            '@id': seo.url,
            name: seo.title,
          },
          position: 2,
        })
      }

      const breadcrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        description: 'Breadcrumbs list',
        name: 'Breadcrumbs',
        itemListElement,
      }

      return (
        <Fragment>
          <Helmet title={seo.title}>
            <html lang={siteLanguage} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {process.env.GATSBY_ACTIVE_ENV !== 'production' || noIndex ? (
              <meta name="robots" content="noindex" />
            ) : null}
            {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
            {!article && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
            {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
            <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
          </Helmet>
          <Facebook
            desc={seo.description}
            image={seo.image}
            imageAlt={seo.imageAlt}
            title={seo.title}
            type={article ? 'article' : 'website'}
            url={seo.url}
            locale={ogLanguage}
            name={facebook}
          />
          <Twitter
            title={seo.title}
            image={seo.image}
            imageAlt={seo.imageAlt}
            desc={seo.description}
            username={twitter}
          />
        </Fragment>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  bannerUrl: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  bannerUrl: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        bannerAlt
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`
