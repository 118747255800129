export const key = '_audioeye_data'

export function persistData(values) {
  if (typeof window === 'undefined' || typeof values === 'undefined' || values === 'undefined') {
    return
  }
  const data = JSON.stringify(values)
  localStorage.setItem(key, data)
}

export function getPersistedData() {
  if (typeof window === 'undefined' || localStorage.getItem(key) === null) {
    return {}
  }
  const value = localStorage.getItem(key)
  if (value === 'undefined') {
    return {}
  }
  return JSON.parse(localStorage.getItem(key) || '{}')
}
