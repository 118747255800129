/* eslint-disable react/jsx-fragments */
/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../../utils/GatsbyLink'
import { theme } from '../../../styles'

import Text from '../../common/Text'
import Space from '../../common/Space'
import Button from '../../common/Button'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.offWhite};
  margin-top: -80px;
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 80px;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding: 80px 16px;
  }
`

const Header = styled.div`
  max-width: 1000px;
`

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 36px 0px;
  padding-bottom: 56px;

  @media (max-width: 1050px) {
    padding: 0px;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 800px) {
    padding-bottom: 32px;
  }
`

const PlanCard = styled.div`
  border-top: 8px solid ${theme.color.purple500};
  padding: 32px 24px;
  flex: 1;
  border-radius: 6px;
  margin-right: 24px;
  box-shadow: 0px 1px 12px 0px ${theme.color.dropShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in-out;
  background-color: ${theme.color.white};

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.05);
  }

  @media (max-width: 1050px) {
    min-width: 300px;
    margin-right: 0px;
    margin-top: 24px;
    :nth-of-type(2) {
      margin-top: 24px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    &:hover {
      transform: none;
    }
  }
`

const EnterpriseLinkCard = styled(Link)`
  padding: 32px 24px;
  flex: 1;
  border-width: 0px;
  border-top: 8px solid ${theme.color.green500};
  border-radius: 6px;
  margin-right: 0px;
  box-shadow: 0px 1px 12px 0px ${theme.color.dropShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in-out;
  background-color: ${theme.color.white};

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.05);
  }

  @media (max-width: 1050px) {
    min-width: 300px;
    margin-right: 0px;
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    width: 100%;
    min-width: unset;
    &:hover {
      transform: none;
    }
  }
`

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  li {
    list-style: none;
    margin-bottom: 8px;
    text-align: center;
  }

  @media (max-width: 800px) {
    /* padding: 32px 16px; */
  }
`

const ManagedCards = ({ primary, items }) => {
  const title = <RichText render={primary.title1.richText} serializeHyperlink={GatsbyLink} />
  const subtitle = <RichText render={idx(primary.subtitle, _ => _.richText)} />
  const buttonText = primary.cta_button_text.text
  const anchorId = primary.anchor_id

  return (
    <Wrapper id={anchorId || null}>
      <Header>
        <Text richText font="heading2" center>
          {title}
        </Text>
        <Space height={16} />
        <Text center richText>
          {subtitle}
        </Text>
      </Header>
      <CardWrapper>
        {items &&
          items.map((item, i) =>
            item.card_title && item.card_title.text === 'Enterprise' ? (
              <EnterpriseLinkCard key="enterprise" to="/enterprise-quote">
                <Text heading3 center>
                  {item.card_title && item.card_title.text}
                </Text>
                <CardListWrapper>
                  <Text center bodyHeavy color="purple500">
                    {item.card_price_text && item.card_price_text.text}
                  </Text>
                  <Space height={8} />
                  <Text richText font="smallBody">
                    {item.card_feature_list && (
                      <RichText render={item.card_feature_list.richText} serializeHyperlink={GatsbyLink} />
                    )}
                  </Text>
                </CardListWrapper>
              </EnterpriseLinkCard>
            ) : (
              <PlanCard key={item.card_title && item.card_title.text}>
                <Text heading3 center>
                  {item.card_title && item.card_title.text}
                </Text>
                <CardListWrapper>
                  <Text center bodyHeavy color="purple500">
                    {item.card_price_text && item.card_price_text.text}
                  </Text>
                  <Space height={8} />
                  <Text richText font="smallBody">
                    {item.card_feature_list && (
                      <RichText render={item.card_feature_list.richText} serializeHyperlink={GatsbyLink} />
                    )}
                  </Text>
                </CardListWrapper>
              </PlanCard>
            )
          )}
      </CardWrapper>
      <Button text={buttonText} to="#get-started-free" />
      <BG className="bg" />
    </Wrapper>
  )
}

export default ManagedCards

export const query = graphql`
  fragment ManagedCards on PrismicPageDataBodyManagedCards {
    ...SliceBase
    items {
      card_feature_list {
        ...TextContent
      }
      card_price_text {
        ...TextContent
      }
      card_title {
        ...TextContent
      }
    }
    primary {
      anchor_id
      cta_button_text {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
