import React from 'react'
import { theme } from '../../../../styles'

export const PartnershipsIcon = ({ index }) => {
  const colors = [
    theme.color.purple500,
    theme.color.blue500,
    theme.color.green500,
    theme.color.orange500,
    theme.color.red500,
    theme.color.purple500,
  ]
  return (
    <svg
      style={{ gridArea: '1 / 1 / 2 / -1', zIndex: '1' }}
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="51.6604" fill={colors[index]} stroke={colors[index]} strokeWidth="0.679245" />
      <path
        d="M54.3793 48.8154L74.247 59.532C75.536 60.2297 75.9785 61.9612 75.2297 63.4077C74.481 64.8499 72.8303 65.4583 71.537 64.7606L60.212 58.6514"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.4247 58.7747L69.9076 63.8884C71.1966 64.5861 71.6391 66.3176 70.8903 67.7641C70.1415 69.2063 68.4909 69.8147 67.1976 69.117L57.6679 63.9777"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.6679 63.982L65.3852 68.1427C66.6743 68.8404 67.1167 70.5719 66.368 72.0184C65.6192 73.4606 63.9686 74.069 62.6752 73.3713L54.4091 68.9127"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.4261 68.883L60.8629 72.3545C62.152 73.0522 62.5944 74.7837 61.8457 76.2301C61.0969 77.6724 59.4462 78.2807 58.1529 77.583L52.6776 74.6305"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2028 61.2039L30.1553 62.7482"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.1172 45.4843C45.9005 44.8461 43.3862 44.1399 42.0248 44.276L40.272 44.4547C40.0976 44.4717 39.9232 44.442 39.7658 44.3611L37.0515 43.0125"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 29.9305L37.2727 39.1539C38.0768 39.6176 38.3618 40.6641 37.9024 41.481L26.7135 61.6124C26.2583 62.4335 25.233 62.7228 24.429 62.2548L21 60.4595"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7498 38.2945L22.9103 61.3997"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7825 62.8036L34.4819 67.4791C33.1886 68.4405 31.3762 68.147 30.4318 66.8282C29.4916 65.5093 29.7766 63.6587 31.0699 62.6972L37.3706 58.0217C38.6639 57.0603 40.4762 57.3538 41.4207 58.6727C42.3609 59.9915 42.0758 61.8421 40.7825 62.8036Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4242 67.394L39.2765 71.2144C37.9832 72.1758 36.1709 71.8823 35.2264 70.5634C34.2862 69.2446 34.5712 67.394 35.8645 66.4325L41.0123 62.6121C42.3056 61.6507 44.1179 61.9442 45.0624 63.263C46.0068 64.5861 45.7175 66.4368 44.4242 67.394Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9978 72.0822L44.5008 74.6774C43.2075 75.6389 41.3952 75.3453 40.4507 74.0265C39.5105 72.7076 39.7955 70.857 41.0888 69.8955L44.5859 67.3004C45.8792 66.3389 47.6915 66.6325 48.636 67.9513C49.5805 69.2702 49.2912 71.1208 47.9978 72.0822Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4864 76.8215L49.3167 78.4339C48.0233 79.3954 46.211 79.1018 45.2666 77.783C44.3264 76.4642 44.6114 74.6135 45.9047 73.6521L48.0744 72.0397C49.3677 71.0782 51.1801 71.3717 52.1245 72.6906C53.069 74.0094 52.7797 75.8601 51.4864 76.8215Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5611 58.7067L75.5956 60.285"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.9468 42.4254L64.2323 43.5358L60.9777 42.4722C59.7865 42.0851 58.5443 41.8851 57.2935 41.8851H51.5076C49.5336 41.8851 47.7936 43.1869 47.2363 45.0844L46.7343 46.7946C46.2323 48.5134 45.9728 50.3002 45.9728 52.0912V57.8601C45.9728 60.0085 47.7128 61.7528 49.8655 61.7528C52.0139 61.7528 53.7582 60.0128 53.7582 57.8601V51.0489C53.7582 50.2619 53.9751 49.4876 54.3836 48.8154L55.9662 46.2033"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.113 24L64.5812 37.6053C63.8324 38.1626 63.6665 39.2304 64.2111 39.992L77.5568 58.7067C78.1014 59.4682 79.148 59.6384 79.8967 59.0811L83.1087 56.8986"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
