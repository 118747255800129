import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

const Content = styled.div`
  padding-bottom: 2rem;
`

const Html = ({ primary }) => <Content dangerouslySetInnerHTML={{ __html: primary.code.text }} />

export default Html

export const query = graphql`
  fragment PostHtml on PrismicPostDataBodyRawHtml {
    ...SliceBase
    primary {
      code {
        ...TextContent
      }
    }
  }
`
