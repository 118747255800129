/* eslint-disable react/display-name */

/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import Text from '../common/Text'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Label = styled.label`
  p {
    font-family: ${theme.fonts.family.smallBodyHeavy};
    font-size: ${theme.fonts.size.smallBodyHeavy};
  }
`

const Input = styled.input`
  width: 100%;
  background-color: ${p => (p.darkBG ? 'transparent' : theme.color.white)};
  font-size: ${theme.fonts.size.smallBody};
  font-family: ${theme.fonts.family.smallBody};
  padding: 8px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${p => (p.darkBG ? theme.color.white : theme.color.gray300)};
  color: ${p => (p.darkBG ? theme.color.white : theme.color.offBlack)};

  ::placeholder {
    color: ${p => (p.darkBG ? theme.color.white : theme.color.gray700)};
    font-family: ${theme.fonts.family.smallBody};
  }

  &:focus {
    border: 1px solid;
    border-color: ${p => (p.darkBG ? theme.color.white : theme.color.gray300)}!important;

    + span.focused-border {
      width: auto;
      height: auto;
      border: 3px solid;
      border-color: ${p => (p.darkBG ? theme.color.green500 : theme.color.purple500)};
    }
  }

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;
`

export const RequiredSpan = styled.span`
  display: inline-block;
  font-size: ${theme.fonts.size.smallBodyHeavy};
  font-family: ${theme.fonts.family.smallBodyHeavy};
  color: ${theme.color.red500};
`

const InputComponent = forwardRef((props, ref) => {
  const { label, error, wrapperStyle, autoComplete, scanner, required, requiredLabel } = props
  return (
    <Wrapper label={label} style={wrapperStyle} id="input-component-wrapper" className="input-component-wrapper">
      {label ? (
        <Label id="input-component-label" className="input-component-label">
          <Text font={props.labelFont ? props.labelFont : 'smallBodyHeavy'}>
            {label} {required && !scanner && <RequiredSpan>*</RequiredSpan>}
          </Text>
          <InputWrapper id="input-component-wrapper" className="input-component-wrapper">
            <Input
              ref={ref || null}
              autoComplete={autoComplete || 'on'}
              {...props}
              required={required}
              id="input-component-input"
              className={`input-component-input ${props.className || ''}`}
              onInput={e => e.target.setCustomValidity(``)}
              onInvalid={e => {
                if (e.target.validity.valueMissing) {
                  e.target.setCustomValidity(`Please enter a valid ${label.toLowerCase()}`)
                }
              }}
            />
          </InputWrapper>
        </Label>
      ) : (
        <InputWrapper>
          <Input
            autoComplete={autoComplete || 'on'}
            {...props}
            ref={ref || null}
            className={`input-component-input ${props.className || ''}`}
            onInput={e => e.target.setCustomValidity(``)}
            onInvalid={e => {
              if (e.target.validity.valueMissing && requiredLabel) {
                e.target.setCustomValidity(`Please enter a valid ${requiredLabel.toLowerCase()}`)
              }
            }}
          />
        </InputWrapper>
      )}

      <Text tinyBody color="red500">
        {error}
      </Text>
    </Wrapper>
  )
})

export default InputComponent
