import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { graphql, StaticQuery } from 'gatsby'
import idx from 'idx'

import Text from '../common/Text'
import TextLink from '../common/TextLink'
import Space from '../common/Space'

const query = graphql`
  query QMSecFilings {
    allPrismicSecAdditionalLinks {
      edges {
        node {
          data {
            group {
              link {
                link_type
                uid
                url
              }
              links {
                text
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 80px 0px;
  padding-top: 0px;
  box-shadow: 0px 0px 0px !important;
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding-top: 1.45rem;
  @media (max-width: 1050px) {
    width: 100%;
  }
`

const TableCol = styled(Col)`
  flex: 2;
  align-items: start;
`

const AdditionalLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;

  ul {
    list-style-type: none;

    li {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 1050px) {
    width: unset;
  }
`

const QMSecFilings = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//qmod.quotemedia.com/js/qmodLoader.js'
    script.async = true
    script.type = 'application/javascript'
    script.setAttribute('data-qmod-wmid', '101772')
    script.setAttribute('id', 'qmod')
    script.setAttribute('data-qmod-env', 'app')
    script.setAttribute('data-qmod-version', '')
    document.body.appendChild(script)

    const interval = setInterval(() => {
      const stocksData = document.querySelector('.qmod-quotehead')
      if (stocksData) {
        // Remove duplicate headings
        const firstStocksDataHeading = document.querySelector('.qmod-sec-filings h1.qmod-heading')
        if (firstStocksDataHeading) {
          firstStocksDataHeading.parentNode.removeChild(firstStocksDataHeading)
        }
        const secondStocksDataHeading = document.querySelector('.qmod-sec-filings h2.qmod-heading')
        if (secondStocksDataHeading) {
          secondStocksDataHeading.parentNode.removeChild(secondStocksDataHeading)
        }
        const stocksDataText = document.querySelector('.qmod-quotehead .pure-g')
        clearInterval(interval)
        stocksData.parentNode.removeChild(stocksData)
        stocksData.style.width = '100%'
        if (stocksDataText) {
          stocksDataText.style.padding = '8px 16px'
          const stockWrapper = document.querySelector('.stock-wrapper')
          stockWrapper.insertAdjacentElement('afterbegin', stocksData)
        }
      }
    }, 100)

    setTimeout(() => {
      clearInterval(interval)
    }, 5000)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Wrapper>
      <TableCol>
        <div className="qm-wrap" />
        <div
          data-qmod-tool="filings"
          data-qmod-params='{"symbol":"AEYE", "resultsPerPage":"12"}'
          className="qtool"
          style={{ width: '100%' }}
        />
      </TableCol>
      <Col>
        <AdditionalLinksWrapper>
          <Text heading4 role="heading" aria-level="2">
            Additional Links
          </Text>

          <Space height={20} />
          <ul>
            <StaticQuery
              query={query}
              render={queryData =>
                queryData.allPrismicSecAdditionalLinks.edges[0].node.data.group.map(({ links, link }) => (
                  <li>
                    <TextLink
                      font="smallBody"
                      to={idx(link, _ => _.url)}
                      text={idx(links, _ => _.text)}
                      style={{ margin: '0px' }}
                    />
                  </li>
                ))
              }
            />
          </ul>
        </AdditionalLinksWrapper>
      </Col>
    </Wrapper>
  )
}

export default QMSecFilings
