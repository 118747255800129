/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'
import idx from 'idx'
import GatsbyLink from '../../utils/GatsbyLink'

const Wrapper = styled.div`
  padding: 80px 0px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;
    margin-bottom: -1rem;
  }

  p {
    margin-top: 1.75rem;
  }
  ul {
    list-style-position: inside;
    margin-top: 1.75rem;
  }

  ul {
    list-style-position: inside;
  }

  a[target='_blank'],
  a[target='blank'] {
    :after {
      content: '(Opens in a new tab)';
      position: absolute;
      left: -9000px;
      width: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      white-space: nowrap;
      font-size: ${p => p.theme.fonts.size.tinyBody};
      text-align: center;
      padding: 4px;
      border-radius: 3px;
      background-color: ${p => p.theme.color.white};
    }
    &:focus,
    &:hover,
    &:active {
      :after {
        top: 2em;
        left: 1em;
        width: auto;
        color: ${p => p.theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${p => p.theme.color.gray400};
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
`

const RichTextSlice = ({ primary }) => {
  const anchorId = idx(primary, _ => _.anchor_id)
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  return <Wrapper id={anchorId || null}>{text}</Wrapper>
}

export default RichTextSlice

export const query = graphql`
  fragment RichTextSlice on PrismicPageDataBodyRichText {
    ...SliceBase
    primary {
      anchor_id
      text {
        ...TextContent
      }
    }
  }
`
