import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import idx from 'idx'
import LottieAnimation from '../LottieAnimation'

import Text from '../common/Text'

import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import { trackEvent } from '../../utils/segmentTrack'
import Space from '../common/Space'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white)};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ResourcesPanelWrapper = styled.div`
  background-color: ${p => (p.wrapperColor ? theme.color[p.wrapperColor] : theme.color.gray100)};
  background-color: ${p => p.noWrapper && 'transparent'};
  margin: 56px 0;
  padding: ${p => (p.noWrapper ? '0px' : '80px')};
  border-radius: 6px;

  @media (max-width: 1050px) {
    padding: 30px;
    margin: 32px 0px;
  }
  @media (max-width: 800px) {
    padding: 16px;
  }
`

const ResourcesHeader = styled.div`
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 1050px) {
    text-align: center;
  }
`

const Resources = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: ${p => (p.numItems <= 3 ? 'flex-start' : 'unset')};
  width: calc(100% + 32px);
  margin-left: -16px;
  flex-wrap: wrap;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    display: block;
  }
`

const ResourceWrapper = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 16px;
  border-radius: 6px;
  background-color: ${p => (p.color ? theme.color[p.color] : theme.color.white)};
  transition: transform 0.2s ease-in-out;
  min-width: 300px;
  padding: 32px;

  ${p =>
    p.hasLink &&
    `
    cursor: pointer;
  `}

  p {
    max-width: 660px;
    margin: 0 auto;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.05);
  }

  @media (max-width: 1050px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 800px) {
    min-width: unset;
    padding: 24px;

    &:hover,
    &:focus {
      transform: none;
      box-shadow: none;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ResourceIcon = styled.img`
  max-height: 64px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
`

const ResourceLink = styled(Link)`
  text-align: right;
`

const Resource = ({ item, color, indx }) => {
  const opensPdf = item.link && item.link.url?.includes('.pdf')
  const rawLottie = item.animation && item.animation.text
  const lottieAnim = rawLottie ? JSON.parse(item.animation.text) : null

  return idx(item.link, _ => _.url) ? (
    <ResourceWrapper
      color={color}
      className="link-list-item external-link-wrapper focus-outline"
      hasLink
      onClick={() => {
        if (idx(item.link, _ => _.type)) {
          navigate(item.link.url)
        } else {
          if (typeof window !== 'undefined')
            window.open(
              idx(item.link, _ => _.url),
              '_blank'
            )
          if (opensPdf) {
            trackEvent(`PDF Downloaded`, {
              pdf_name: item.link.url,
            })
          }
        }
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          if (idx(item.link, _ => _.type)) {
            navigate(item.link.url)
          } else if (e.key === 'Enter' && typeof window !== 'undefined') {
            window.open(
              idx(item.link, _ => _.url),
              '_blank'
            )
          }
        }
      }}
      tabIndex="0"
    >
      {lottieAnim && typeof lottieAnim === 'object' && (
        <LottieAnimation lotti={lottieAnim} height={64} width={64} index={indx} />
      )}
      {item.icon && <ImageWrapper>{getImage(item.icon, ResourceIcon, { alt: '' })}</ImageWrapper>}
      <Space height={16} />
      {item.title1 && (
        <Text center bodyHeavy role="heading" aria-level="3">
          {item.title1.text}
        </Text>
      )}
      <Space height={8} />
      {item.text && (
        <Text center body>
          {item.text.text}
        </Text>
      )}
      <Space height={8} />
      {idx(item.link, _ => _.type) ? (
        <ResourceLink to={item.link.url} tabIndex="-1">
          <Text center smallBodyHeavy underline color="purple500">
            Learn More
          </Text>
        </ResourceLink>
      ) : (
        <>
          <a
            href={idx(item.link, _ => _.url)}
            onClick={e => {
              e.stopPropagation()
              if (opensPdf) {
                trackEvent(`PDF Downloaded`, {
                  pdf_name: item.link.url,
                })
              }
            }}
            target="_blank"
            rel="noreferrer"
            tabIndex="-1"
            className="no-focus"
            aria-label={item.title1 ? `Learn more about ${item.title1.text}` : ''}
          >
            <Text center smallBodyHeavy underline color="purple500">
              Learn More
            </Text>
          </a>
          <OpenNewTabHiddenSpan text={item.title1.text || ''} fileType={opensPdf && 'PDF'} />
        </>
      )}
    </ResourceWrapper>
  ) : (
    <ResourceWrapper color={color}>
      {lottieAnim && typeof lottieAnim === 'object' && (
        <LottieAnimation lotti={lottieAnim} height={64} width={64} index={indx} />
      )}
      {item.icon && <ImageWrapper>{getImage(item.icon, ResourceIcon, { alt: '' })}</ImageWrapper>}
      <Space height={15} />
      <Text center bodyHeavy role="heading" aria-level="3">
        {item.title1.text}
      </Text>
      <Space height={8} />
      <Text center body>
        {item.text.text}
      </Text>
    </ResourceWrapper>
  )
}

const ResourcesPanel = ({ primary, items }) => {
  const title = idx(primary, _ => _.title1.text)
  const text = idx(primary, _ => _.text.text)
  const anchorId = primary.anchor_id
  const wrapperColor = primary.wrapper_color
  const backgroundColor = primary.background_color
  const noWrapper = primary.no_wrapper

  return (
    <Wrapper id={anchorId || null}>
      <ResourcesPanelWrapper wrapperColor={wrapperColor} noWrapper={noWrapper}>
        {(title || text) && (
          <ResourcesHeader>
            {title && (
              <>
                <Text center heading2>
                  {title}
                </Text>
                <Space height={25} />
              </>
            )}
            {text && <Text center>{text}</Text>}
          </ResourcesHeader>
        )}
        <Resources numItems={items.length}>
          {items.map((item, indx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Resource item={item} color={item.color} indx={indx} key={`resource-item-${indx}`} />
          ))}
        </Resources>
      </ResourcesPanelWrapper>
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default ResourcesPanel

export const query = graphql`
  fragment ResourcesPanel on PrismicPageDataBodyResourcesPanel {
    ...SliceBase
    items {
      color
      animation {
        ...TextContent
      }
      icon {
        alt
        url
        gatsbyImageData(width: 64)
      }
      link {
        ...LinkContent
      }
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
    primary {
      anchor_id
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      wrapper_color
      background_color
      no_wrapper
    }
  }
`
