const theme = {
  color: {
    purple900: '#2D0363',
    purple500: '#7119E1',
    purple200: '#E7DEFC',
    purple100: '#F5F1FD',
    green900: '#075A4C',
    green500: '#25D0B4',
    green700: '#0A957E',
    green100: '#DEF8F4',
    offBlack: '#141316',
    white: '#FFFFFF',
    gray700: '#4D4C4E',
    gray400: '#99989A',
    gray300: '#B3B2B4',
    gray200: '#CCCBCD',
    gray100: '#EAEAEC',
    offWhite: '#F7F8FA',
    blue500: '#2145E9',
    blue100: '#DEE3FC',
    orange500: '#FD9426',
    orange100: '#FFEAD4',
    red900: '#99130A',
    red500: '#E81C0D',
    red100: '#FCE8E8',
    transparent: 'transparent',
    dropShadow: 'rgba(145, 140, 151, 0.15)',
    darkShadow: '	rgba(102, 99, 105, 0.18)',
  },
  fonts: {
    size: {
      heading1: '2.4rem',
      heading2: '2rem',
      heading3: '1.6rem',
      heading4: '1.2rem',
      largeBody: '1.2rem',
      bodyHeavy: '1rem',
      body: '1rem',
      smallBodyHeavy: '.8rem',
      smallBodyBold: '.8rem',
      smallBody: '.8rem',
      tinyBody: '.64rem',
      microBody: '.55rem',
      smallCaps: '.7rem',
      smallCapsSans: '.6rem',
    },
    family: {
      heading1: 'GT-America-Bold',
      heading2: 'GT-America-Bold',
      heading3: 'GT-America-Bold',
      heading4: 'GT-America-Bold',
      largeBody: 'GT-America-Light',
      bodyHeavy: 'GT-America-Medium',
      body: 'GT-America-Light',
      smallBodyHeavy: 'GT-America-Medium',
      smallBodyBold: 'GT-America-Bold',
      smallBody: 'GT-America-Light',
      tinyBody: 'GT-America-Medium',
      microBody: 'GT-America-Regular',
      smallCaps: 'GT-America-Mono',
      smallCapsSans: 'GT-America-Regular',
    },
    lineHeight: {
      heading1: 1.175,
      heading2: 1.2,
      heading3: 1.25,
      heading4: 1.3,
      largeBody: 1.35,
      bodyHeavy: 1.45,
      body: 1.5,
      smallBodyHeavy: 1.5,
      smallBodyBold: 1.5,
      smallBody: 1.5,
      tinyBody: 1.9,
      microBody: 1.2,
      smallCaps: 2.8,
      smallCapsSans: 2.5,
    },
    letterSpacing: {
      heading1: '.02rem',
      heading2: '.01rem',
      heading3: '.005rem',
      heading4: 0,
      largeBody: 0,
      bodyHeavy: 0,
      body: 0,
      smallBodyHeavy: 0,
      smallBodyBold: 0,
      smallBody: 0,
      tinyBody: 0,
      microBody: 0,
      smallCaps: '.0375rem',
      smallCapsSans: '.0375rem',
    },
  },
  focusOutline: '3px solid #7119E1',
  darkBgFocusOutline: '3px solid #25D0B4',
}

export default theme
