import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import getImage from '../../utils/getImage'

export const ImageEl = styled.img`
  margin-bottom: 1.45rem;
  padding-bottom: 24px;
`

const Image = ({ primary }) => getImage(primary.image, ImageEl)

export default Image

export const query = graphql`
  fragment PostImage on PrismicPostDataBodyImage {
    ...SliceBase
    primary {
      image {
        alt
        url
        gatsbyImageData(width: 1080)
      }
    }
  }
`
