import React from 'react'
import { theme } from '../../../../styles'

export const AnnouncementsIcon = ({ index }) => {
  const colors = [
    theme.color.purple500,
    theme.color.blue500,
    theme.color.green500,
    theme.color.orange500,
    theme.color.red500,
    theme.color.purple500,
  ]
  return (
    <svg
      style={{ gridArea: '1 / 1 / 2 / -1', zIndex: '1' }}
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="51.6604" fill={colors[index]} stroke={colors[index]} strokeWidth="0.679245" />
      <path
        d="M74.9294 68.6252L23.448 67.6949L20 56.7249L61.3492 25.9709"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.2899 68.5963C78.3239 67.6435 77.7695 57.2741 74.0516 45.4355C70.3338 33.597 64.8604 24.7724 61.8264 25.7252C58.7924 26.678 59.3468 37.0475 63.0646 48.886C66.7825 60.7245 72.2559 69.5491 75.2899 68.5963Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2664 44.9833C62.65 44.6835 63.0953 44.4454 63.5936 44.2866C65.8026 43.5944 68.157 44.8201 68.8537 47.0335C69.5503 49.2469 68.3202 51.597 66.1068 52.2937C65.613 52.448 65.1103 52.5097 64.6208 52.4833"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4665 67.9815L42.3898 77.2981L36.9665 79L33.47 67.8757"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.5943 43.6605L83.9639 42.2848"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.724 39.0353L79.8897 35.7284"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.8812 49.0926L84.3741 50.0009"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.2953 53.2505C50.2115 56.403 46.5784 61.4559 46.5784 61.4559C46.5784 61.4559 40.3615 61.1164 37.8571 59.2073C36.305 58.0256 35.1586 55.627 36.2874 53.4974C37.5087 51.1649 41.2919 50.6226 42.5308 53.0036C41.455 50.5433 44.2504 47.9374 46.8298 48.4489C49.1975 48.903 50.3438 51.2972 50.2953 53.2505Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
