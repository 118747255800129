/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-fragments */
import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'

import idx from 'idx'

import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'

import whiteCompanyLogo from '../../static/logos/company-logo-green-tm.svg'
import Text from './common/Text'
import Space from './common/Space'
import TextLink from './common/TextLink'
import { OpenNewTabHiddenSpan } from './common/NewTabHiddenSpan'
import { Facebook } from '../../static/icons/component-icons/FacebookIcon.js'
import { Instagram } from '../../static/icons/component-icons/InstagramIcon.js'
import { Twitter } from '../../static/icons/component-icons/TwitterIcon.js'
import { LinkedIn } from '../../static/icons/component-icons/LinkedInIcon.js'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 80px 60px 80px;
  background-color: ${p => p.theme.color.offBlack};
  color: white;
  width: 100vw;
  margin-left: -150px;

  @media (max-width: 1050px) {
    padding: 40px 14px 60px 14px;
    width: 100vw;
    margin-left: -24px;

    justify-content: center;
    justify-items: center;
  }
`

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1050px) {
    min-width: 50%;
    margin-bottom: 24px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  padding: 68px 0px;
  width: 100%;

  @media (max-width: 1050px) {
    flex-direction: column;
    padding: 40px 0px;
  }
`

const Content = styled(SectionWrapper)`
  border-bottom: 1px solid ${p => p.theme.color.gray400};

  @media (max-width: 1050px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
`

const BottomWrapper = styled(SectionWrapper)`
  @media (max-width: 1050px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const BottomLinkWrapper = styled.div`
  flex: 3;
  display: flex;
`

const FooterLinkList = styled.ul`
  li {
    margin-bottom: 0;
    font-size: initial;
    list-style-type: none;

    a {
      text-decoration: none;
    }
  }
`

const FooterTextLinkWrapper = styled.ul`
  flex: 2;
  display: flex;

  li {
    flex: 1;
    margin-bottom: 0;
    list-style-type: none;

    a {
      text-decoration: none;
    }
  }

  &.hide-desktop {
    display: none;
    @media (max-width: 1050px) {
      display: flex;
      padding-bottom: 40px;
    }
  }
  &.hide-mobile {
    display: flex;
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

const SocialWrapper = styled.ul`
  display: flex;
  flex: 1;

  li {
    margin-bottom: 0;
    list-style-type: none;

    a {
      border-radius: 50%;
      height: fit-content;
      display: flex;
    }
  }

  .icon {
    transition: all 75ms ease-in-out;
    fill: ${p => p.theme.color.white};
  }

  a:hover {
    border: 1px solid ${p => p.theme.color.green500};
    padding: 2px;
    margin: -3px;
    .icon {
      fill: ${p => p.theme.color.green500};
    }
  }

  @media (max-width: 800px) {
    svg {
      width: 24px;
      height: 24px;
    }
    a:hover {
      border: 3px solid transparent;
      padding: 0px;
      .icon {
        fill: ${p => p.theme.color.white};
      }
    }
  }
`

const FooterLogo = styled.img`
  width: 180px;

  @media (max-width: 1050px) {
    width: 130px;
    margin-top: 0px;
  }
`

const Copyright = styled.div`
  display: flex;
  justify-content: center;

  p {
    display: inline-block;
  }
`

const FooterTextLink = props => (
  <TextLink
    className="dark-bg-link"
    anchorClassName="dark-bg-link dark-bg-focus"
    font="smallBody"
    inFooter
    outboundFooterLink={idx(props.to, to => to.includes('http'))}
    style={{ flex: '1' }}
    {...props}
  />
)

const FooterSocialLink = ({ href, icon, alt }) => (
  <li className="link-list-item">
    <a target="_blank" rel="noopener noreferrer" href={href} className="dark-bg-link dark-bg-focus">
      {icon === Facebook && <Facebook alt={alt} />}
      {icon === Twitter && <Twitter alt={alt} />}
      {icon === Instagram && <Instagram alt={alt} />}
      {icon === LinkedIn && <LinkedIn alt={alt} />}
      <OpenNewTabHiddenSpan text={alt} />
    </a>
  </li>
)

const FooterUtilityLinks = ({ responsiveClass }) => (
  <FooterTextLinkWrapper className={responsiveClass}>
    <li className="link-list-item">
      <FooterTextLink text="Terms of Service" data-cy="footer-terms-of-service" to="/terms-of-service" />
    </li>
    <li className="link-list-item">
      <FooterTextLink text="Privacy Policy" data-cy="footer-privacy-policy" to="/privacy-policy" />
    </li>
  </FooterTextLinkWrapper>
)

const Footer = ({ hideHeaderFooterNav }) => {
  const footerQuery = graphql`
    query Footer {
      prismicFooterLinks {
        data {
          group {
            title {
              text
            }
            section
            link_fallback {
              text
            }
            link {
              link_type
              uid
              url
            }
          }
        }
      }
    }
  `

  return (
    <Wrapper role="contentinfo" className="footer">
      {!hideHeaderFooterNav && (
        <Content>
          <StaticQuery
            query={`${footerQuery}`}
            render={data => {
              const items = data.prismicFooterLinks.data.group

              const grouped = mapValues(groupBy(items, 'section'), clist => clist.map(car => omit(car, 'section')))

              return Object.keys(grouped).map((key, indexOut) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${indexOut}:${key}`}>
                  <Col>
                    <Text role="heading" aria-level="2" bodyHeavy color="white">
                      {key}
                    </Text>
                    <Space height={15} />
                    <FooterLinkList>
                      {grouped[key].map((item, indexIn) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={`${indexIn}:${item.link && item.link.url}`}>
                          <li
                            key={`${indexIn}:${item.link && item.link.url}`}
                            className="footer-text-link link-list-item"
                          >
                            <FooterTextLink
                              text={item.title.text}
                              data-cy={`footer-${item.title.text.replace(/ /g, '-').toLowerCase()}`}
                              to={
                                (item.link && item.link.url) ||
                                (item.link_fallback && item.link_fallback && item.link_fallback.text)
                              }
                            />
                          </li>
                          <Space height={8} />
                        </React.Fragment>
                      ))}
                    </FooterLinkList>
                  </Col>
                </React.Fragment>
              ))
            }}
          />
        </Content>
      )}
      <BottomWrapper>
        <Col>
          <FooterLogo src={whiteCompanyLogo} alt="AudioEye logo" />
        </Col>
        <BottomLinkWrapper>
          <FooterUtilityLinks responsiveClass="hide-mobile" />
          <SocialWrapper>
            <FooterSocialLink href="https://www.linkedin.com/company/audioeye-inc/" icon={LinkedIn} alt="linkedin" />
            <Space width={23} tabletWidth={8} />
            <FooterSocialLink href="https://www.facebook.com/audioeyeinc" imgSrc="" icon={Facebook} alt="facebook" />
            <Space width={23} tabletWidth={8} />
            <FooterSocialLink href="https://twitter.com/audioeyeinc" icon={Twitter} alt="twitter" />
            <Space width={23} tabletWidth={8} />
            <FooterSocialLink href="https://www.instagram.com/audioeyeinc/" icon={Instagram} alt="instagram" />
          </SocialWrapper>
        </BottomLinkWrapper>
      </BottomWrapper>
      <FooterUtilityLinks responsiveClass="hide-desktop" />
      <Copyright>
        <Text center color="gray400" tinyBody>
          Copyright ©2021 AudioEye, Inc. All rights reserved. AUDIOEYE is a U.S. registered trademark of AudioEye, Inc.
          The products of AudioEye are protected by patents. For additional information, see{' '}
          <TextLink
            font="tinyBody"
            color="gray400"
            to="/ip"
            text="www.audioeye.com/ip"
            anchorClassName="dark-bg-link dark-bg-focus"
          />{' '}
          or contact ip@audioeye.com.
        </Text>
      </Copyright>
    </Wrapper>
  )
}

export default Footer
