import testingVariant from '../../config/testingVariant'

export const trackEvent = (label, data) => {
  window.analytics.ready(() => {
    const user = window.analytics.user()
    const anonymousID = user.anonymousId()
    const userId = user.id()

    const testData = testingVariant()

    const identifiedData = {
      ...data,
      mvtest: testData,
      segment_userID: userId,
      segment_anonymousID: anonymousID,
    }
    window.analytics.track(label, identifiedData)
  })
}
