import React from 'react'
import { graphql } from 'gatsby'
import idx from 'idx'
import { RichText } from 'prismic-reactjs'
import styled from '@emotion/styled'
import GatsbyLink from '../../utils/GatsbyLink'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import getImage from '../../utils/getImage'

const BG = styled.div`
  position: absolute;
  background-color: ${p => p.theme.color[p.backgroundColor] || p.theme.color.gray100};
  width: 500vw;
  margin-left: -300%;
  height: 100%;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 0px 150px;

  ${p =>
    p.img &&
    `
    flex-direction: row;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
  `}

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 80px;
  border-radius: 4px;
  text-align: center;
  ${p =>
    p.img &&
    `
    align-items: flex-start;
    text-align: left;
    `}
  z-index: 1;

  @media (max-width: 1050px) {
    padding: 80px 0px;
  }

  @media (max-width: 800px) {
    align-items: center;
    * {
      text-align: center !important;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${p =>
    p.img &&
    `
    margin-top: 40px;
  `}
  z-index: 1;
`

const Image = styled.img`
  max-width: 400px;
  min-width: 400px;
  width: 100%;
  object-fit: contain;

  @media (max-width: 800px) {
    min-width: unset;
  }
`

const BannerInfo = ({ primary }) => {
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const title = <RichText render={primary.title.richText} serializeHyperlink={GatsbyLink} />
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const img = idx(primary, _ => _.image.url)
  const anchorId = primary.anchor_id

  return (
    <BannerWrapper img={img} id={anchorId || null}>
      <ImageWrapper img={img}>{getImage(primary.image, Image)}</ImageWrapper>
      <InfoWrapper img={img}>
        <Text richText font="heading3" center={!img}>
          {title}
        </Text>
        <Space height={16} />
        <Text richText center={!img} style={{ maxWidth: '650px' }}>
          {text}
        </Text>
        <Space height={32} />
        <Button text={buttonText} to={buttonUrl} />
      </InfoWrapper>
      <BG className="bg" backgroundColor="white" />
    </BannerWrapper>
  )
}

export default BannerInfo

export const query = graphql`
  fragment BannerInfo on PrismicPageDataBodyBannerInfo {
    ...SliceBase
    primary {
      anchor_id
      background_color
      button_link {
        ...LinkContent
      }
      button_text {
        ...TextContent
      }
      image {
        alt
        url
        gatsbyImageData(width: 400)
      }
      title {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
  }
`
