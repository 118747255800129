import React from 'react'
import styled from '@emotion/styled'
import TextLink from './TextLink'
import chevron from '../../../static/icons/arrow-icons/chevron-icon-violet.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Img = styled.img`
  width: 12px;
  margin-right: 8px;
  margin-top: 3px;
  transform: rotate(90deg);
`

const BackButton = ({ text = 'Back', to, ...rest }) => (
  <Wrapper>
    <Img src={chevron} alt="" />
    <TextLink text={text} to={to || ''} font="bodyHeavy" {...rest}>
      {text}
    </TextLink>
  </Wrapper>
)

export default BackButton
