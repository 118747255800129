/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import BodyText from '../components/postSlices/BodyText'
import CodeBlock from '../components/postSlices/CodeBlock'
import Image from '../components/postSlices/Image'
import Video from '../components/postSlices/Video'
import Quote from '../components/postSlices/Quote'
import Html from '../components/postSlices/Html'
import PostEarningsTable from '../components/postSlices/PostEarningsTable'
import MultiLevelList from '../components/postSlices/MultiLevelList'
import Summary from '../components/postSlices/Summary'
import ContentBlock from '../components/postSlices/ContentBlock'

import Feature from '../components/pageSlices/Feature'
import ScannerBanner from '../components/pageSlices/ScannerBanner'

export const PostContentWrapper = styled.div`
  padding: 40px 0px;
  img {
    width: 100%;
    height: auto;
  }
  ul,
  ol {
    list-style-position: outside;
    margin-top: 1.75rem;
    margin-left: 32px;

    @media (max-width: 800px) {
      margin-left: 24px;
    }
  }
  p,
  li {
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
    p {
      display: inline;
    }
  }
`

export const renderPostSlices = contentBlocks => (
  <PostContentWrapper>
    {contentBlocks
      .filter(x => x)
      .map(({ slice_type = '', ...props }, i) => {
        if (connectors[slice_type]) {
          const SliceComponent = connectors[slice_type]
          return <SliceComponent key={props.id ? `${slice_type}-${props.id}-${i}` : `${slice_type}-${i}`} {...props} />
        }
        if (process.env.NODE_ENV !== 'production') console.warn(`No connector found for ${slice_type}`)
        return null
      })}
  </PostContentWrapper>
)

const connectors = {
  text: BodyText,
  code_block: CodeBlock,
  quote: Quote,
  image: Image,
  video: Video,
  raw_html: Html,
  'post-earnings-table': PostEarningsTable,
  'multi-level-list': MultiLevelList,
  feature: Feature,
  summary: Summary,
  scanner: ScannerBanner,
  content_block: ContentBlock,
}

export const query = graphql`
  fragment PostDataBody on PrismicPostDataBodySlicesType {
    ...PostBodyText
    ...PostQuote
    ...PostCodeBlock
    ...PostImage
    ...PostVideo
    ...PostHtml
    ...PostEarningsTable
    ...PostMultiLevelList
    ...PostFeature
    ...PostSummary
    ...PostScanner
    ...PostContentBlock
  }
`
