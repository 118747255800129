/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import styled from '@emotion/styled'

import Text from '../../common/Text'
import PageBlock from '../../common/PageBlock'
import NewsGridItem from './NewsGridItem'
import { theme } from '../../../styles'
import { getPrismicNewsItems } from '../../../utils/prismicPostAPI'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  position: relative;
`

const BG = styled.div`
  position: absolute;
  top: 0;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.offWhite};
`

const GridWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  flex-wrap: wrap;
  grid-column-gap: 24px;
  margin: 32px 0px;
  grid-gap: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-bottom: 0px;
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin-top: 24px;
  }
`

const BottomWrapper = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

const NewsItemsGrid = () => {
  const [page, setPage] = useState(1)
  const [newsData, setNewsData] = useState()

  const onPreviousClick = () => setPage(page - 1)
  const onNextClick = () => setPage(page + 1)

  useEffect(() => {
    try {
      getPrismicNewsItems({ page, pageSize: 6 }).then(res => {
        setNewsData(res)
      })
    } catch (error) {
      console.log(error)
    }
  }, [page])

  return (
    <Wrapper>
      <Header>
        <Text heading2 center>
          In the News
        </Text>
      </Header>
      <GridWrapper>
        {newsData &&
          newsData.results &&
          newsData.results.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${item.data.title[0].text}+${i}`}>
              <NewsGridItem
                date={item.data.date}
                title={item.data.title[0].text}
                url={item.data.link.url}
                index={i}
                img={item.data.image}
              />
            </Fragment>
          ))}
      </GridWrapper>
      <BottomWrapper role="navigation" aria-label="Pagination Navigation">
        <PageBlock page="previous" onClick={onPreviousClick} disabled={page === 1} />
        {newsData &&
          newsData.total_pages &&
          new Array(newsData.total_pages)
            .fill(' ')
            .map((block, i) => (
              <PageBlock key={i} page={i + 1} isSelected={page === i + 1} onClick={() => setPage(i + 1)} />
            ))}
        <PageBlock
          page="next"
          onClick={onNextClick}
          disabled={newsData && newsData.total_pages && page === newsData.total_pages}
        />
      </BottomWrapper>
      <BG className="bg" />
    </Wrapper>
  )
}

export default NewsItemsGrid
